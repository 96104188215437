import { FC } from 'react';

export type FormDividerProps = {
  text?: string;
};

export const FormDivider: FC<FormDividerProps> = (props) => {
  const { text } = props;
  return (
    <div
      className="flex my-6 min-h-[1.5em] items-center justify-center text-clevergy-subtext text-base before:bg-clevergy-border before:content-[''] before:block before:h-px before:w-full after:bg-clevergy-border after:content-[''] after:block after:h-px after:w-full"
      role="none"
    >
      {text && <span className="max-w-xs whitespace-nowrap px-2">{text}</span>}
    </div>
  );
};
