import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import {
  ClevergyEvent,
  ClevergyEventTypes,
  useClevergyEvent,
} from '@clevergy/event-bus';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getHouseSettingsTrackingEventObject } from 'utils/getHouseSettingsTrackingEventObject';

export const HouseSettingsPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { track } = useAnalyticsContext();
  const { selectedHouse } = useSelectedHouse();

  const trackEvent = (event: ClevergyEvent) => {
    const trackingEventObject = getHouseSettingsTrackingEventObject({
      event,
      page: 'profile',
    });

    track(trackingEventObject);
  };

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_SAVED, (event) => {
    trackEvent(event);
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_NOT_SAVED, (event) => {
    trackEvent(event);
  });

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('house-settings.title') as string}
        withHousePicker
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {(!token || !selectedHouse) && (
          <div className="fixed inset-0 flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
        {token && selectedHouse && (
          <>
            <div className="flex flex-col gap-4">
              <div>
                <h3 className="text-clevergy-moduleHeaderTitle text-xl font-semibold mb-2">
                  {t('houseSettings.my-account.configure-your-house-title')}
                </h3>
                <p>
                  {t('houseSettings.my-account.configure-your-house-content')}
                </p>
              </div>
              <clevergy-house-settings
                data-token={token}
                data-house-id={selectedHouse.houseId}
              ></clevergy-house-settings>
            </div>
          </>
        )}
      </Wrapper>
    </Page>
  );
};
