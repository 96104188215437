import { ModuleSuccess } from 'components/ModuleSuccess';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlexaIntegrationPage: FC = () => {
  const { t } = useTranslation();
  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader backButton title={t('alexa.title') as string} />

      <Wrapper>
        <ModuleSuccess
          title={t('alexa.success.title') as string}
          message={t('alexa.success.message') as string}
        />
      </Wrapper>
    </Page>
  );
};
