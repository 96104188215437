import { ModuleCardContent } from './ModuleCardContent';
import { ModuleCardHeader } from './ModuleCardHeader';
import { ModuleCardNavigationHeader } from './ModuleCardNavigationHeader';
import { FC, ReactNode } from 'react';

export type ModuleCardProps = {
  children: ReactNode;
};

const ModuleCard: FC<ModuleCardProps> & {
  Header: typeof ModuleCardHeader;
  Content: typeof ModuleCardContent;
  NavigationHeader: typeof ModuleCardNavigationHeader;
} = (props: ModuleCardProps) => {
  const { children } = props;
  return (
    <div className="h-full bg-clevergy-moduleContainerBackground shadow-moduleContainer rounded-moduleContainer m-moduleContainerMargin group">
      {children}
    </div>
  );
};

ModuleCard.Header = ModuleCardHeader;
ModuleCard.Content = ModuleCardContent;
ModuleCard.NavigationHeader = ModuleCardNavigationHeader;

export { ModuleCard };
