import { TutorialIntro } from './components/TutorialIntro';
import { TutorialSteps } from './components/TutorialSteps';
import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import { Modal } from '@clevergy/ui/components/Modal';
import { FC, useState } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const AppTutorialModal: FC = () => {
  const isOnboardingLessonsCompleted = useAppConfigurationStore(
    (state) => state.isOnboardingLessonsCompleted,
  );
  const [showIntro, setShowIntro] = useState(true);

  const setIsOnboardingLessonsAsCompleted = useAppConfigurationStore(
    (state) => state.setIsOnboardingLessonsAsCompleted,
  );

  const { track } = useAnalyticsContext();

  const handleComplete = () => {
    setIsOnboardingLessonsAsCompleted();
    track({
      event_type: 'onboarding_completed',
      user_properties: {
        onboarding_lessons: true,
      },
    });
  };

  const handleSkip = () => {
    setIsOnboardingLessonsAsCompleted();
  };

  return (
    <Modal isOpen={!isOnboardingLessonsCompleted}>
      <div className="h-full w-full md:!h-[640px] overflow-hidden">
        {showIntro && <TutorialIntro onContinue={() => setShowIntro(false)} />}
        {!showIntro && (
          <TutorialSteps onSkip={handleSkip} onComplete={handleComplete} />
        )}
      </div>
    </Modal>
  );
};
