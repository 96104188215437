/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { HouseEnergy } from './HouseEnergy';
import {
  HouseEnergyFromJSON,
  HouseEnergyFromJSONTyped,
  HouseEnergyToJSON,
  HouseEnergyToJSONTyped,
} from './HouseEnergy';

/**
 *
 * @export
 * @interface HouseDataEnergy
 */
export interface HouseDataEnergy {
  /**
   *
   * @type {Array<HouseEnergy>}
   * @memberof HouseDataEnergy
   */
  data?: Array<HouseEnergy>;
  /**
   *
   * @type {string}
   * @memberof HouseDataEnergy
   */
  rateType?: HouseDataEnergyRateTypeEnum;
}

/**
 * @export
 */
export const HouseDataEnergyRateTypeEnum = {
  Regulated: 'REGULATED',
  Pymes: 'PYMES',
} as const;
export type HouseDataEnergyRateTypeEnum =
  (typeof HouseDataEnergyRateTypeEnum)[keyof typeof HouseDataEnergyRateTypeEnum];

/**
 * Check if a given object implements the HouseDataEnergy interface.
 */
export function instanceOfHouseDataEnergy(
  value: object,
): value is HouseDataEnergy {
  return true;
}

export function HouseDataEnergyFromJSON(json: any): HouseDataEnergy {
  return HouseDataEnergyFromJSONTyped(json, false);
}

export function HouseDataEnergyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseDataEnergy {
  if (json == null) {
    return json;
  }
  return {
    data:
      json['data'] == null
        ? undefined
        : (json['data'] as Array<any>).map(HouseEnergyFromJSON),
    rateType: json['rateType'] == null ? undefined : json['rateType'],
  };
}

export function HouseDataEnergyToJSON(json: any): HouseDataEnergy {
  return HouseDataEnergyToJSONTyped(json, false);
}

export function HouseDataEnergyToJSONTyped(
  value?: HouseDataEnergy | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    data:
      value['data'] == null
        ? undefined
        : (value['data'] as Array<any>).map(HouseEnergyToJSON),
    rateType: value['rateType'],
  };
}
