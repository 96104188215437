/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { SolarInfo, SolarRecommendation } from '../models/index';
import {
  SolarInfoFromJSON,
  SolarInfoToJSON,
  SolarRecommendationFromJSON,
  SolarRecommendationToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetSolarInfoRequest {
  houseId: string;
  startDate: Date;
  endDate: Date;
}

export interface GetSolarRecommendationRequest {
  houseId: string;
}

/**
 *
 */
export class SolarApi extends runtime.BaseAPI {
  /**
   * Returns solar information for a house.
   */
  async getSolarInfoRaw(
    requestParameters: GetSolarInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SolarInfo>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getSolarInfo().',
      );
    }

    if (requestParameters['startDate'] == null) {
      throw new runtime.RequiredError(
        'startDate',
        'Required parameter "startDate" was null or undefined when calling getSolarInfo().',
      );
    }

    if (requestParameters['endDate'] == null) {
      throw new runtime.RequiredError(
        'endDate',
        'Required parameter "endDate" was null or undefined when calling getSolarInfo().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['startDate'] != null) {
      queryParameters['startDate'] = (
        requestParameters['startDate'] as any
      ).toISOString();
    }

    if (requestParameters['endDate'] != null) {
      queryParameters['endDate'] = (
        requestParameters['endDate'] as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/solar-info`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SolarInfoFromJSON(jsonValue),
    );
  }

  /**
   * Returns solar information for a house.
   */
  async getSolarInfo(
    requestParameters: GetSolarInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SolarInfo> {
    const response = await this.getSolarInfoRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns whether solar is recommended for the given house.
   */
  async getSolarRecommendationRaw(
    requestParameters: GetSolarRecommendationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SolarRecommendation>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getSolarRecommendation().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/solar-recommendation`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SolarRecommendationFromJSON(jsonValue),
    );
  }

  /**
   * Returns whether solar is recommended for the given house.
   */
  async getSolarRecommendation(
    requestParameters: GetSolarRecommendationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SolarRecommendation> {
    const response = await this.getSolarRecommendationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
