import { MobileNavbar } from 'components/MobileNavbar';
import { NonMobileNavbar } from 'components/NonMobileNavbar';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';

export const NavbarLayout = () => {
  const location = useLocation();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  // reset scroll position when location changes
  useEffect(() => {
    if (location && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <div
      id="navbar-layout"
      className="flex flex-col sm:flex-row h-full bg-[var(--navbar-layout-content-mobile-background)] sm:bg-transparent"
    >
      <NonMobileNavbar />
      <div
        className="grow overflow-y-auto xl:overflow-visible relative"
        ref={scrollDivRef}
      >
        <div className="min-h-full sm:mx-auto sm:min-h-fit md:max-w-[600px] lg:py-8">
          <Outlet />
        </div>
      </div>
      <MobileNavbar />
    </div>
  );
};
