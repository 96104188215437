import {
  ClevergyEventHandler,
  ClevergyEventType,
} from '../classes/EventBus.class';
import { useEventBusContext } from '../context/EventBusContext';
import { useEffect } from 'react';

export const useClevergyEvent = (
  type: ClevergyEventType,
  callback?: ClevergyEventHandler,
) => {
  const { eventBus } = useEventBusContext();

  useEffect(() => {
    if (!callback) {
      return;
    }
    const unsubscribe = eventBus.subscribeToEvent(type, callback);
    return unsubscribe;
  }, [callback, eventBus, type]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (data?: any) => eventBus.dispatchEvent({ type, data });
};
