import { ClevergyEvent } from '@clevergy/event-bus';

type HouseSettingsAnswer = {
  cupsAvailable: boolean;
  houseSettingsFilled: boolean;
};

type HouseSettingsEvent = {
  event: ClevergyEvent;
  page: 'onboarding' | 'profile';
};

export const getHouseSettingsTrackingEventObject = (
  payload: HouseSettingsEvent,
) => {
  const { page, event } = payload;
  const { cupsAvailable, houseSettingsFilled } =
    event.data as HouseSettingsAnswer;

  return {
    event_type: 'house_settings_answered',
    event_properties: {
      page,
      cups_available: cupsAvailable,
      house_settings_filled: houseSettingsFilled,
    },
    user_properties: {
      house_settings_completed: cupsAvailable && houseSettingsFilled,
    },
  };
};
