import { FC, ReactNode, createContext, useContext } from 'react';

export type ModalContextValue = {
  modalRenderElement: HTMLElement;
};

export const ModalContext = createContext<ModalContextValue | null>(null);

export const ModalProvider: FC<
  ModalContextValue & {
    children: ReactNode;
  }
> = ({ modalRenderElement, children }) => {
  return (
    <ModalContext.Provider
      value={{
        modalRenderElement,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === null) {
    throw Error('useModalContext must be used inside of a ModalContext');
  }
  return context;
};
