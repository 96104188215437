import { SurplusRecommendations } from './components/SurplusRecommendations';
import { TrackedViewMount } from '@clevergy/amplitude/components/TrackedViewMount';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Modal } from '@clevergy/ui/components/Modal';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { AppAlerts } from 'components/AppAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OptimizePage: FC = () => {
  const { t } = useTranslation();

  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const { hasRateRecommenderEnabled, tenantId } = useTenantSettings();
  const [showTipsPanelModal, setShowTipsPanelModal] = useState(false);

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}
        {token && (
          <>
            <AppAlerts />

            {selectedHouse && (
              <clevergy-recommendations
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-recommender
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && <SurplusRecommendations />}

            <ModuleCard>
              <ModuleCard.Content>
                <button
                  onClick={() => setShowTipsPanelModal(true)}
                  className="flex w-full items-center gap-2"
                >
                  <div className="flex-shrink-0 text-clevergy-primary">
                    <FontIcon name="energy_savings_leaf" />
                  </div>
                  <div className="flex-grow font-bold text-left">
                    {t('optimize.tips.title')}
                  </div>
                  <div className="flex-shrink-0">
                    <FontIcon name="arrow_forward_ios" />
                  </div>
                </button>
              </ModuleCard.Content>
            </ModuleCard>

            {selectedHouse && hasRateRecommenderEnabled && (
              <clevergy-rate-recommender
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-variant={
                  tenantId === 'smartconsum' ? 'lead-capture' : 'default'
                }
              />
            )}

            <Modal
              isOpen={showTipsPanelModal}
              onClose={() => setShowTipsPanelModal(false)}
            >
              <TrackedViewMount
                event={{
                  event_type: 'module_tips_panel',
                  event_properties: {
                    viewed: 'showed',
                  },
                }}
              >
                <clevergy-tips-panel
                  data-token={token}
                  data-house-id={selectedHouse?.houseId}
                />
              </TrackedViewMount>
            </Modal>
          </>
        )}
      </Wrapper>
    </Page>
  );
};
