/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemHouse
 */
export interface EnergyItemHouse {
  /**
   *
   * @type {number}
   * @memberof EnergyItemHouse
   */
  consumption?: number;
  /**
   *
   * @type {number}
   * @memberof EnergyItemHouse
   */
  selfConsumption?: number;
}

/**
 * Check if a given object implements the EnergyItemHouse interface.
 */
export function instanceOfEnergyItemHouse(
  value: object,
): value is EnergyItemHouse {
  return true;
}

export function EnergyItemHouseFromJSON(json: any): EnergyItemHouse {
  return EnergyItemHouseFromJSONTyped(json, false);
}

export function EnergyItemHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemHouse {
  if (json == null) {
    return json;
  }
  return {
    consumption: json['consumption'] == null ? undefined : json['consumption'],
    selfConsumption:
      json['selfConsumption'] == null ? undefined : json['selfConsumption'],
  };
}

export function EnergyItemHouseToJSON(json: any): EnergyItemHouse {
  return EnergyItemHouseToJSONTyped(json, false);
}

export function EnergyItemHouseToJSONTyped(
  value?: EnergyItemHouse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    consumption: value['consumption'],
    selfConsumption: value['selfConsumption'],
  };
}
