import { useAppUpdaterContext } from 'context/AppUpdaterContext';
import { FC } from 'react';

export const AppVersionInfo: FC = () => {
  const { appVersion, bundleVersion } = useAppUpdaterContext();
  return (
    <>
      {(appVersion || bundleVersion) && (
        <div className="text-center text-clevergy-subtext text-xs pt-2">
          {appVersion && <>v{appVersion}</>}
          {bundleVersion && bundleVersion !== 'builtin' && (
            <>(b.{bundleVersion})</>
          )}
        </div>
      )}
    </>
  );
};
