import { ClevergyIcons } from '../variants/ClevergyIcons';
import Calendar from './naturgy/icon-calendar.svg?react';
import HouseEfficient from './naturgy/icon-house-efficient.svg?react';
import HouseVerified from './naturgy/icon-house-verified.svg?react';
import House from './naturgy/icon-house.svg?react';
import Location from './naturgy/icon-location.svg?react';
import Monitoring from './naturgy/icon-monitoring.svg?react';
import Neighbors from './naturgy/icon-neighbors.svg?react';

// Any icon here needs to be present in ClevergyIcons
// but it can be optional because Clevergy version is used as fallback
type NaturgyIconsType = Record<
  keyof typeof ClevergyIcons,
  React.FC<React.SVGProps<SVGElement>>
>;

export const NaturgyIcons: Partial<NaturgyIconsType> = {
  Calendar,
  House,
  HouseEfficient,
  HouseVerified,
  Location,
  Monitoring,
  Neighbors,
};
