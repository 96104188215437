/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { OptimizeRecommendation } from '../models/index';
import {
  OptimizeRecommendationFromJSON,
  OptimizeRecommendationToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface DiscardOptimizeRecommendationsRequest {
  houseId: string;
  id: string;
}

export interface GetOptimizeRecommendationsRequest {
  houseId: string;
  locale: string;
}

/**
 *
 */
export class OptimizeApi extends runtime.BaseAPI {
  /**
   * Discard recommendation
   */
  async discardOptimizeRecommendationsRaw(
    requestParameters: DiscardOptimizeRecommendationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling discardOptimizeRecommendations().',
      );
    }

    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling discardOptimizeRecommendations().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/recommendations/{id}`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters['houseId'])),
          )
          .replace(
            `{${'id'}}`,
            encodeURIComponent(String(requestParameters['id'])),
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Discard recommendation
   */
  async discardOptimizeRecommendations(
    requestParameters: DiscardOptimizeRecommendationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.discardOptimizeRecommendationsRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * Returns all recommendations for a supply
   */
  async getOptimizeRecommendationsRaw(
    requestParameters: GetOptimizeRecommendationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<OptimizeRecommendation>>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getOptimizeRecommendations().',
      );
    }

    if (requestParameters['locale'] == null) {
      throw new runtime.RequiredError(
        'locale',
        'Required parameter "locale" was null or undefined when calling getOptimizeRecommendations().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['locale'] != null) {
      queryParameters['locale'] = requestParameters['locale'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/recommendations`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(OptimizeRecommendationFromJSON),
    );
  }

  /**
   * Returns all recommendations for a supply
   */
  async getOptimizeRecommendations(
    requestParameters: GetOptimizeRecommendationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<OptimizeRecommendation>> {
    const response = await this.getOptimizeRecommendationsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
