import { ALEXA_CLIENT_ID, ALEXA_SKILL_STAGE } from 'constants/alexaSettings';

const baseUrl = 'https://alexa.amazon.com/spa/skill-account-linking-consent';

const options = {
  response_type: 'code',
  client_id: ALEXA_CLIENT_ID,
  skill_stage: ALEXA_SKILL_STAGE,
  scope: 'alexa::skills:account_linking',
  fragment: 'skill-account-linking-consent',
  state: Math.random().toString(36).substring(2, 15),
  redirect_uri: 'https://app.clever.gy/profile/integrations/alexa/callback',
};

const params = Object.entries(options)
  .map(([key, value]) => `${key}=${value}`)
  .join('&');

export const getAlexaIntegrationItemLink = () =>
  encodeURI(`${baseUrl}?${params}`);
