/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { HttpError, TenantSettings } from '../models/index';
import {
  HttpErrorFromJSON,
  HttpErrorToJSON,
  TenantSettingsFromJSON,
  TenantSettingsToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetTenantSettingsRequest {
  tenantId?: string;
  tenantGcpId?: string;
}

/**
 *
 */
export class TenantsApi extends runtime.BaseAPI {
  /**
   * Get tenant settings
   */
  async getTenantSettingsRaw(
    requestParameters: GetTenantSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<TenantSettings>> {
    const queryParameters: any = {};

    if (requestParameters['tenantId'] != null) {
      queryParameters['tenantId'] = requestParameters['tenantId'];
    }

    if (requestParameters['tenantGcpId'] != null) {
      queryParameters['tenantGcpId'] = requestParameters['tenantGcpId'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/consumer/tenants`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TenantSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Get tenant settings
   */
  async getTenantSettings(
    requestParameters: GetTenantSettingsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TenantSettings> {
    const response = await this.getTenantSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
