import { FontIcon } from '../FontIcon';
import { FC, ReactNode } from 'react';

export type ModuleCardNavigationHeaderProps = {
  title: string;
  onBackButton?: () => void;
  endContent?: ReactNode;
};

export const ModuleCardNavigationHeader: FC<
  ModuleCardNavigationHeaderProps
> = ({ title, onBackButton, endContent }) => {
  const handleBackButton = () => {
    onBackButton?.();
  };

  return (
    <div className="sticky top-0 z-10 border-b border-clevergy-border bg-white">
      <div className="py-2 grid grid-cols-[1fr_auto_1fr] gap-3 px-moduleContainerPadding">
        {onBackButton ? (
          <>
            <button
              type="button"
              className="text-left text-clevergy-primary shrink-0 whitespace-nowrap flex items-center gap-0.5"
              onClick={handleBackButton}
            >
              <FontIcon name="arrow_back_ios" size={14} />
              Atrás
            </button>
          </>
        ) : (
          <span></span>
        )}
        <div className="flex justify-center items-center py-2 text-xl font-semibold text-center">
          <h2>{title}</h2>
        </div>
        {endContent && (
          <div className="flex justify-end items-center shrink-0 whitespace-nowrap">
            {endContent}
          </div>
        )}
      </div>
    </div>
  );
};
