/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserAccountDetails
 */
export interface UserAccountDetails {
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof UserAccountDetails
   */
  phoneNumber?: string;
}

/**
 * Check if a given object implements the UserAccountDetails interface.
 */
export function instanceOfUserAccountDetails(
  value: object,
): value is UserAccountDetails {
  return true;
}

export function UserAccountDetailsFromJSON(json: any): UserAccountDetails {
  return UserAccountDetailsFromJSONTyped(json, false);
}

export function UserAccountDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserAccountDetails {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
    surname: json['surname'] == null ? undefined : json['surname'],
    email: json['email'] == null ? undefined : json['email'],
    dni: json['dni'] == null ? undefined : json['dni'],
    phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
  };
}

export function UserAccountDetailsToJSON(json: any): UserAccountDetails {
  return UserAccountDetailsToJSONTyped(json, false);
}

export function UserAccountDetailsToJSONTyped(
  value?: UserAccountDetails | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
    surname: value['surname'],
    email: value['email'],
    dni: value['dni'],
    phoneNumber: value['phoneNumber'],
  };
}
