/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ConsumerAppSettings } from './ConsumerAppSettings';
import {
  ConsumerAppSettingsFromJSON,
  ConsumerAppSettingsFromJSONTyped,
  ConsumerAppSettingsToJSON,
  ConsumerAppSettingsToJSONTyped,
} from './ConsumerAppSettings';
import type { ThemeCssVariables } from './ThemeCssVariables';
import {
  ThemeCssVariablesFromJSON,
  ThemeCssVariablesFromJSONTyped,
  ThemeCssVariablesToJSON,
  ThemeCssVariablesToJSONTyped,
} from './ThemeCssVariables';

/**
 *
 * @export
 * @interface TenantSettings
 */
export interface TenantSettings {
  /**
   * Tenant identifier
   * @type {string}
   * @memberof TenantSettings
   */
  tenantId: string;
  /**
   * Tenant GCP identifier
   * @type {string}
   * @memberof TenantSettings
   */
  tenantGcpId: string;
  /**
   * Tenant name
   * @type {string}
   * @memberof TenantSettings
   */
  name: string;
  /**
   * Tenant legal name
   * @type {string}
   * @memberof TenantSettings
   */
  legalName?: string;
  /**
   * Tenant tax ID
   * @type {string}
   * @memberof TenantSettings
   */
  taxId?: string;
  /**
   * Tenant address
   * @type {string}
   * @memberof TenantSettings
   */
  address?: string;
  /**
   * Tenant contact email
   * @type {string}
   * @memberof TenantSettings
   */
  contactEmail?: string;
  /**
   * Tenant DPO email
   * @type {string}
   * @memberof TenantSettings
   */
  dpoEmail?: string;
  /**
   * Tenant phone number
   * @type {string}
   * @memberof TenantSettings
   */
  phoneNumber?: string;
  /**
   * Tenant WhatsApp number
   * @type {string}
   * @memberof TenantSettings
   */
  whatsappNumber?: string;
  /**
   * Tenant mailer sender email
   * @type {string}
   * @memberof TenantSettings
   */
  mailerSenderEmail?: string;
  /**
   * Tenant theme font face url
   * @type {string}
   * @memberof TenantSettings
   */
  themeFontFaceUrl?: string;
  /**
   *
   * @type {ThemeCssVariables}
   * @memberof TenantSettings
   */
  themeCssVariables?: ThemeCssVariables;
  /**
   *
   * @type {ConsumerAppSettings}
   * @memberof TenantSettings
   */
  consumerAppSettings?: ConsumerAppSettings;
  /**
   * Tenant has app registration enabled
   * @type {boolean}
   * @memberof TenantSettings
   */
  hasAppRegistrationEnabled: boolean;
  /**
   * Tenant has social login enabled
   * @type {boolean}
   * @memberof TenantSettings
   */
  hasSocialLoginEnabled: boolean;
  /**
   * Tenant has rate recommender enabled
   * @type {boolean}
   * @memberof TenantSettings
   */
  hasRateRecommenderEnabled: boolean;
  /**
   * Tenant has control over house creation
   * @type {boolean}
   * @memberof TenantSettings
   */
  hasControlOverHouseCreation: boolean;
  /**
   * Tenant energy prices type
   * @type {string}
   * @memberof TenantSettings
   */
  energyPricesType?: TenantSettingsEnergyPricesTypeEnum;
}

/**
 * @export
 */
export const TenantSettingsEnergyPricesTypeEnum = {
  None: 'NONE',
  Pvpc: 'PVPC',
  Omie: 'OMIE',
} as const;
export type TenantSettingsEnergyPricesTypeEnum =
  (typeof TenantSettingsEnergyPricesTypeEnum)[keyof typeof TenantSettingsEnergyPricesTypeEnum];

/**
 * Check if a given object implements the TenantSettings interface.
 */
export function instanceOfTenantSettings(
  value: object,
): value is TenantSettings {
  if (!('tenantId' in value) || value['tenantId'] === undefined) return false;
  if (!('tenantGcpId' in value) || value['tenantGcpId'] === undefined)
    return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (
    !('hasAppRegistrationEnabled' in value) ||
    value['hasAppRegistrationEnabled'] === undefined
  )
    return false;
  if (
    !('hasSocialLoginEnabled' in value) ||
    value['hasSocialLoginEnabled'] === undefined
  )
    return false;
  if (
    !('hasRateRecommenderEnabled' in value) ||
    value['hasRateRecommenderEnabled'] === undefined
  )
    return false;
  if (
    !('hasControlOverHouseCreation' in value) ||
    value['hasControlOverHouseCreation'] === undefined
  )
    return false;
  return true;
}

export function TenantSettingsFromJSON(json: any): TenantSettings {
  return TenantSettingsFromJSONTyped(json, false);
}

export function TenantSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TenantSettings {
  if (json == null) {
    return json;
  }
  return {
    tenantId: json['tenantId'],
    tenantGcpId: json['tenantGcpId'],
    name: json['name'],
    legalName: json['legalName'] == null ? undefined : json['legalName'],
    taxId: json['taxId'] == null ? undefined : json['taxId'],
    address: json['address'] == null ? undefined : json['address'],
    contactEmail:
      json['contactEmail'] == null ? undefined : json['contactEmail'],
    dpoEmail: json['dpoEmail'] == null ? undefined : json['dpoEmail'],
    phoneNumber: json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    whatsappNumber:
      json['whatsappNumber'] == null ? undefined : json['whatsappNumber'],
    mailerSenderEmail:
      json['mailerSenderEmail'] == null ? undefined : json['mailerSenderEmail'],
    themeFontFaceUrl:
      json['themeFontFaceUrl'] == null ? undefined : json['themeFontFaceUrl'],
    themeCssVariables:
      json['themeCssVariables'] == null
        ? undefined
        : ThemeCssVariablesFromJSON(json['themeCssVariables']),
    consumerAppSettings:
      json['consumerAppSettings'] == null
        ? undefined
        : ConsumerAppSettingsFromJSON(json['consumerAppSettings']),
    hasAppRegistrationEnabled: json['hasAppRegistrationEnabled'],
    hasSocialLoginEnabled: json['hasSocialLoginEnabled'],
    hasRateRecommenderEnabled: json['hasRateRecommenderEnabled'],
    hasControlOverHouseCreation: json['hasControlOverHouseCreation'],
    energyPricesType:
      json['energyPricesType'] == null ? undefined : json['energyPricesType'],
  };
}

export function TenantSettingsToJSON(json: any): TenantSettings {
  return TenantSettingsToJSONTyped(json, false);
}

export function TenantSettingsToJSONTyped(
  value?: TenantSettings | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    tenantId: value['tenantId'],
    tenantGcpId: value['tenantGcpId'],
    name: value['name'],
    legalName: value['legalName'],
    taxId: value['taxId'],
    address: value['address'],
    contactEmail: value['contactEmail'],
    dpoEmail: value['dpoEmail'],
    phoneNumber: value['phoneNumber'],
    whatsappNumber: value['whatsappNumber'],
    mailerSenderEmail: value['mailerSenderEmail'],
    themeFontFaceUrl: value['themeFontFaceUrl'],
    themeCssVariables: ThemeCssVariablesToJSON(value['themeCssVariables']),
    consumerAppSettings: ConsumerAppSettingsToJSON(
      value['consumerAppSettings'],
    ),
    hasAppRegistrationEnabled: value['hasAppRegistrationEnabled'],
    hasSocialLoginEnabled: value['hasSocialLoginEnabled'],
    hasRateRecommenderEnabled: value['hasRateRecommenderEnabled'],
    hasControlOverHouseCreation: value['hasControlOverHouseCreation'],
    energyPricesType: value['energyPricesType'],
  };
}
