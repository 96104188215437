import { useSelectedHouse } from './useSelectedHouse';
import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const useSmartMeterIntegration = () => {
  const { userStatus } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const { hasSkippedSmartMeterIntegration } = useAppConfigurationStore();
  const tenantSettings = useTenantSettings();

  const shouldSuggestSmartMeterIntegration =
    (userStatus === UserSetUpUserStatusEnum.Initial ||
      userStatus === UserSetUpUserStatusEnum.PendingRenewal) &&
    typeof selectedHouse !== 'undefined' &&
    !selectedHouse?.dataProviders?.solarInverter &&
    typeof tenantSettings.dpoEmail !== 'undefined' &&
    tenantSettings.dpoEmail !== null &&
    tenantSettings.dpoEmail !== '';

  const shouldRedirectToSmartMeter =
    shouldSuggestSmartMeterIntegration && !hasSkippedSmartMeterIntegration;

  const shouldShowSmartMeterDraftModules =
    shouldSuggestSmartMeterIntegration &&
    !selectedHouse?.dataProviders?.smartMeter;

  return {
    shouldSuggestSmartMeterIntegration,
    shouldRedirectToSmartMeter,
    shouldShowSmartMeterDraftModules,
  };
};
