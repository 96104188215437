import { CarouselControls } from './CarouselControls';
import { motion } from 'framer-motion';
import { FC, ReactElement, ReactNode, useState } from 'react';

export type CarouselItemProps = {
  /**
   * Icon of the carousel item
   */
  icon?: ReactNode;
  /**
   * Title of the carousel item
   */
  title: string;
  /**
   * Content of the carousel item
   */
  children: ReactNode;
};

const CarouselItem: FC<CarouselItemProps> = (props) => {
  const { icon, title, children } = props;
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        {icon}
        <span className="font-semibold">{title}</span>
      </div>
      <div>{children}</div>
    </div>
  );
};

export type CarouselProps = {
  /**
   * Transition seconds of the carousel
   */
  transitionSeconds?: number;
  /**
   * Items of the carousel
   */
  children?: ReactElement<typeof CarouselItem>[];
};

const Carousel: FC<CarouselProps> & { Item: typeof CarouselItem } = (
  props: CarouselProps,
) => {
  const { children, transitionSeconds = 1 } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  if (!children || children.length === 0) {
    return null;
  }
  const handleNavigateToItem = (index: number) => {
    setCurrentIndex(index);
  };

  const totalWidth = children.length * 100;

  return (
    <div className="border border-neutral-300 rounded-lg p-4">
      <div className="overflow-hidden relative">
        <div className="flex flex-nowrap" style={{ width: `${totalWidth}%` }}>
          {children.map((item, index) => (
            <motion.div
              key={item.key}
              className="w-full"
              initial={{ x: 0 }}
              animate={{
                x: `-${currentIndex * 100}%`,
                opacity: currentIndex === index ? 1 : 0,
              }}
              transition={{ duration: transitionSeconds }}
            >
              {item}
            </motion.div>
          ))}
        </div>
        {children.length > 1 && (
          <CarouselControls
            totalItems={children.length}
            currentIndex={currentIndex}
            setCurrentIndex={handleNavigateToItem}
          />
        )}
      </div>
    </div>
  );
};

Carousel.Item = CarouselItem;

export { Carousel };
