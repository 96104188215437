import { CheckDevicesModal } from './CheckDevicesModal';
import { MoreInformationModal } from './MoreInformationModal';
import {
  GetSmartDevicesResponse,
  GetSmartDevicesResponseSubtypeEnum,
} from '@clevergy/api-client';
import { Badge } from '@clevergy/ui/components/Badge';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { List } from '@clevergy/ui/components/List';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type ConnectedStateProps = {
  devices: GetSmartDevicesResponse[];
};

export const ConnectedState: FC<ConnectedStateProps> = ({ devices }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedHouse } = useSelectedHouse();

  const [showMoreInformationModal, setShowMoreInformationModal] =
    useState(false);

  const [showCheckDevicesModal, setShowCheckDevicesModal] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-4 h-full">
        <p className="text-sm">
          {t('smartDevices.connectedState.intro')}{' '}
          <button
            className="text-clevergy-button"
            onClick={() => setShowMoreInformationModal(true)}
          >
            {t('smartDevices.connectedState.moreInformation')}
          </button>
        </p>

        <List>
          <>
            {devices.map((item) => {
              return (
                <List.Item
                  key={`${item.deviceId}-${item.channel}`}
                  testId={`${item.deviceId}-${item.channel}`}
                  leftIcon={<Icon name="Shelly" />}
                  rightIcon={
                    <div className="flex gap-2 items-center">
                      {item.subtype ===
                        GetSmartDevicesResponseSubtypeEnum.Unknown && (
                        <Badge colorScheme="danger">
                          {t('smartDevices.connectedState.noAssigned')}
                        </Badge>
                      )}
                      <FontIcon name="chevron_right" />
                    </div>
                  }
                  onClick={() =>
                    navigate(
                      `/houses/${selectedHouse?.houseId}/smart-devices/${item.deviceId}/${item.channel}`,
                    )
                  }
                >
                  {item.name}
                </List.Item>
              );
            })}
          </>
        </List>

        <div className="mt-4">
          <Button
            target="_blank"
            fullWidth
            onClick={() => setShowCheckDevicesModal(true)}
          >
            {t('smartDevices.connectedState.buttons.connect')}
          </Button>
        </div>
      </div>

      <MoreInformationModal
        isOpen={showMoreInformationModal}
        onCloseModal={() => setShowMoreInformationModal(false)}
      />

      <CheckDevicesModal
        isOpen={showCheckDevicesModal}
        onCloseModal={() => setShowCheckDevicesModal(false)}
      />
    </>
  );
};
