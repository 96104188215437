import { GetSmartDevicesResponseModelEnum } from '@clevergy/api-client';

const models = {
  [GetSmartDevicesResponseModelEnum.Em]:
    'smartDevices.smartDevicesModels.SHELLY_EM',
  [GetSmartDevicesResponseModelEnum.PlugS]:
    'smartDevices.smartDevicesModels.SHELLY_PLUG_S',
} as const;

/**
 * Retrieves the translation key for a given smart device model.
 *
 * @param model - The smart device model.
 * @returns The translation key for the smart device model, or 'unknown' if the model is not found.
 */
export const getSmartDeviceModelTranslationKey = (
  model: GetSmartDevicesResponseModelEnum,
) => models[model] || 'smartDevices.form.fields.model.unknown';
