/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { ConsumerAppSettings, HttpError } from '../models/index';
import {
  ConsumerAppSettingsFromJSON,
  ConsumerAppSettingsToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetAppSettingsRequest {
  domain: string;
}

/**
 *
 */
export class AppsApi extends runtime.BaseAPI {
  /**
   * Get app settings
   */
  async getAppSettingsRaw(
    requestParameters: GetAppSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ConsumerAppSettings>> {
    if (requestParameters['domain'] == null) {
      throw new runtime.RequiredError(
        'domain',
        'Required parameter "domain" was null or undefined when calling getAppSettings().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['domain'] != null) {
      queryParameters['domain'] = requestParameters['domain'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/consumer/apps`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ConsumerAppSettingsFromJSON(jsonValue),
    );
  }

  /**
   * Get app settings
   */
  async getAppSettings(
    requestParameters: GetAppSettingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ConsumerAppSettings> {
    const response = await this.getAppSettingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
