type Params = {
  phone: string;
  text?: string;
};

export function getWhatsappUrl({ phone, text = 'Hola' }: Params) {
  if (!phone) {
    throw new Error('Phone is required');
  }

  const encodedPhone = encodeURIComponent(phone);
  const encodedText = text && encodeURIComponent(text);
  return `https://api.whatsapp.com/send?phone=${encodedPhone}&text=${encodedText}`;
}
