/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateAlexaUserAuthCodeRequest
 */
export interface CreateAlexaUserAuthCodeRequest {
  /**
   *
   * @type {string}
   * @memberof CreateAlexaUserAuthCodeRequest
   */
  cups: string;
}

/**
 * Check if a given object implements the CreateAlexaUserAuthCodeRequest interface.
 */
export function instanceOfCreateAlexaUserAuthCodeRequest(
  value: object,
): value is CreateAlexaUserAuthCodeRequest {
  if (!('cups' in value) || value['cups'] === undefined) return false;
  return true;
}

export function CreateAlexaUserAuthCodeRequestFromJSON(
  json: any,
): CreateAlexaUserAuthCodeRequest {
  return CreateAlexaUserAuthCodeRequestFromJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAlexaUserAuthCodeRequest {
  if (json == null) {
    return json;
  }
  return {
    cups: json['cups'],
  };
}

export function CreateAlexaUserAuthCodeRequestToJSON(
  json: any,
): CreateAlexaUserAuthCodeRequest {
  return CreateAlexaUserAuthCodeRequestToJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeRequestToJSONTyped(
  value?: CreateAlexaUserAuthCodeRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    cups: value['cups'],
  };
}
