/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface PowerAnalysis
 */
export interface PowerAnalysis {
  /**
   *
   * @type {boolean}
   * @memberof PowerAnalysis
   */
  canImproveHouseContract?: boolean;
  /**
   *
   * @type {number}
   * @memberof PowerAnalysis
   */
  contractedPeakPower?: number;
  /**
   *
   * @type {number}
   * @memberof PowerAnalysis
   */
  contractedOffPeakPower?: number;
  /**
   *
   * @type {number}
   * @memberof PowerAnalysis
   */
  peakPowerSpike?: number;
  /**
   *
   * @type {number}
   * @memberof PowerAnalysis
   */
  offPeakPowerSpike?: number;
}

/**
 * Check if a given object implements the PowerAnalysis interface.
 */
export function instanceOfPowerAnalysis(value: object): value is PowerAnalysis {
  return true;
}

export function PowerAnalysisFromJSON(json: any): PowerAnalysis {
  return PowerAnalysisFromJSONTyped(json, false);
}

export function PowerAnalysisFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PowerAnalysis {
  if (json == null) {
    return json;
  }
  return {
    canImproveHouseContract:
      json['canImproveHouseContract'] == null
        ? undefined
        : json['canImproveHouseContract'],
    contractedPeakPower:
      json['contractedPeakPower'] == null
        ? undefined
        : json['contractedPeakPower'],
    contractedOffPeakPower:
      json['contractedOffPeakPower'] == null
        ? undefined
        : json['contractedOffPeakPower'],
    peakPowerSpike:
      json['peakPowerSpike'] == null ? undefined : json['peakPowerSpike'],
    offPeakPowerSpike:
      json['offPeakPowerSpike'] == null ? undefined : json['offPeakPowerSpike'],
  };
}

export function PowerAnalysisToJSON(json: any): PowerAnalysis {
  return PowerAnalysisToJSONTyped(json, false);
}

export function PowerAnalysisToJSONTyped(
  value?: PowerAnalysis | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    canImproveHouseContract: value['canImproveHouseContract'],
    contractedPeakPower: value['contractedPeakPower'],
    contractedOffPeakPower: value['contractedOffPeakPower'],
    peakPowerSpike: value['peakPowerSpike'],
    offPeakPowerSpike: value['offPeakPowerSpike'],
  };
}
