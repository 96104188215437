import clsx from 'clsx';
import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';

export type ListProps = PropsWithChildren<{
  /**
   * List items
   */
  children: ReactElement<typeof ListItem> | ReactElement<typeof ListItem>[];
}>;

export type ListItemProps = {
  /**
   * Content of the list item
   */
  children: ReactNode;
  /**
   * Left icon of the list item
   */
  leftIcon?: ReactNode;
  /**
   * Right icon of the list item
   */
  rightIcon?: ReactNode;
  /**
   * Test id of the list item
   */
  testId?: string;
  /**
   * If the list item has bottom border
   */
  withBorder?: boolean;
  /**
   * Callback when the list item is clicked
   */
  onClick?: () => void;
};

const ListItem: FC<ListItemProps> = ({
  children,
  leftIcon,
  rightIcon,
  testId,
  withBorder = true,
  onClick,
}) => {
  const content = (
    <>
      {leftIcon && (
        <div className="w-6 shrink-0 text-clevergy-text">{leftIcon}</div>
      )}
      <div className="mr-auto w-full text-start font-normal">{children}</div>
      {rightIcon && <div className="shrink-0">{rightIcon}</div>}
    </>
  );

  return (
    <li
      data-testid={testId}
      className={clsx('list-none', {
        'rounded-none border-b border-gray-200 sm:last:border-b-0': withBorder,
      })}
    >
      {onClick ? (
        <button
          type="button"
          className={clsx(
            'flex w-full items-center justify-between gap-4 px-2 py-4 pr-2',
          )}
          onClick={onClick}
        >
          {content}
        </button>
      ) : (
        <div
          className={clsx(
            'flex w-full items-center justify-between gap-4 px-2 py-4 pr-2',
          )}
        >
          {content}
        </div>
      )}
    </li>
  );
};

const List: FC<ListProps> & { Item: typeof ListItem } = (props: ListProps) => {
  const { children } = props;
  return <ul>{children}</ul>;
};

List.Item = ListItem;

export { List };
