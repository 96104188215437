import { es, Locale } from 'date-fns/locale';
import i18n from 'i18next';
import es_ES from 'locales/es-ES';
import { initReactI18next } from 'react-i18next';

const resources = {
  'es-ES': es_ES,
};

const DEFAULT_LANGUAGE = 'es-ES';
const availableLanguages = Object.keys(resources);
export type AvailableLanguages = (typeof availableLanguages)[number];

const dateFnsLocales: Record<AvailableLanguages, Locale> = {
  'es-ES': es,
};

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  resources,
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export {
  i18n,
  resources,
  availableLanguages,
  DEFAULT_LANGUAGE,
  dateFnsLocales,
};
