import clsx from 'clsx';
import { forwardRef } from 'react';

export type InputTextAreaProps = {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  value?: string;
  rows?: number;
  cols?: number;
  autoComplete?: string;
  hiddenLabel?: boolean;
  readOnly?: boolean;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
};

export const InputTextArea = forwardRef<
  HTMLTextAreaElement,
  InputTextAreaProps
>((props, ref) => {
  const {
    id,
    name,
    label,
    hiddenLabel,
    readOnly,
    placeholder,
    value,
    rows = 2,
    cols = 20,
    autoComplete,
    error,
    onChange,
    onClick,
    onFocus,
  } = props;
  return (
    <div className="relative flex flex-col gap-1 w-full">
      {label && (
        <label
          htmlFor={name}
          hidden={hiddenLabel}
          className="text-clevergy-text font-semibold"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          className={clsx(
            'font-normal text-clevergy-text placeholder-gray-300 w-full rounded-lg border border-gray-300 bg-white px-3 py-2 placeholder:font-normal focus:border-clevergy-primary focus:outline-none',
            {
              'opacity-60': readOnly,
            },
          )}
          id={id}
          ref={ref}
          name={name}
          placeholder={placeholder}
          value={value}
          rows={rows}
          cols={cols}
          autoComplete={autoComplete}
          aria-invalid={Boolean(error)}
          readOnly={readOnly}
          onChange={onChange}
          onClick={onClick}
          onFocus={onFocus}
        />
      </div>

      {error && <span className="text-red-800 block text-sm">{error}</span>}
    </div>
  );
});

InputTextArea.displayName = 'InputTextArea';
