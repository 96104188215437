import { SplashScreen } from '@capacitor/splash-screen';
import { AnalyticsProvider } from '@clevergy/amplitude/context/AnalyticsContext';
import { EventBus, EventBusContextProvider } from '@clevergy/event-bus';
import { ModalProvider } from '@clevergy/ui/context/ModalContext';
import { AppRoutes } from 'AppRoutes';
import { AppWrapper } from 'components/AppWrapper';
import { GoogleTagManager } from 'components/GoogleTagManager';
import { HouseSettingsBackupRestorer } from 'components/HouseSettingsBackupRestorer';
import { LastLogginTracker } from 'components/LastLogginTracker';
import { ApiProvider } from 'context/ApiContext';
import { AppSettingsProvider } from 'context/AppSettingsContext';
import { AppUpdaterProvider } from 'context/AppUpdaterContext';
import { AuthProvider } from 'context/AuthContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { QueryProvider } from 'context/QueryContext';
import { TranslationsProvider } from 'context/TranslationsContext';
import { UserSetupProvider } from 'context/UserSetupContext';
import { AppAnalyticsListener } from 'listeners/AppAnalyticsListener';
import { AppUrlListener } from 'listeners/AppUrlListener';
import { FC, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'styles/index.css';

const eventBus = new EventBus();

export const Root: FC = () => {
  // Hide the splash screen when app is ready
  useEffect(() => {
    SplashScreen.hide();
  });

  return (
    <AppWrapper>
      <AnalyticsProvider>
        <EventBusContextProvider eventBus={eventBus}>
          <ModalProvider modalRenderElement={document.body}>
            <QueryProvider>
              <AuthProvider>
                <ApiProvider>
                  <AppSettingsProvider>
                    <TranslationsProvider>
                      <AppUpdaterProvider>
                        <UserSetupProvider>
                          <NotificationsProvider>
                            <LastLogginTracker />
                            <GoogleTagManager />
                            <AppUrlListener />
                            <AppAnalyticsListener />
                            <HouseSettingsBackupRestorer />
                            <AppRoutes />
                          </NotificationsProvider>
                        </UserSetupProvider>
                      </AppUpdaterProvider>
                    </TranslationsProvider>
                  </AppSettingsProvider>
                </ApiProvider>
              </AuthProvider>
            </QueryProvider>
          </ModalProvider>
        </EventBusContextProvider>
      </AnalyticsProvider>
    </AppWrapper>
  );
};

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export const App = () => {
  return <RouterProvider router={router} />;
};
