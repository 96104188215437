import { useModalContext } from '../../context/ModalContext';
import {
  flip,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { FC, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

export type TooltipProps = {
  content: string | ReactNode;
  children: ReactNode;
};

export const Tooltip: FC<TooltipProps> = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    strategy: 'absolute',
    placement: 'bottom',
    middleware: [shift(), flip()],
    transform: true,
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context);

  const hover = useHover(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  return (
    <>
      <div
        className="relative inline-block cursor-pointer"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </div>
      {isMounted && (
        <TooltipPortal>
          <div
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...transitionStyles }}
            className="px-8 z-50"
            {...getFloatingProps()}
          >
            <div className="max-w-sm px-5 py-3 text-sm rounded-lg text-clevergy-text border border-solid border-gray-300 bg-white shadow-xl pointer-events-none">
              {content}
            </div>
          </div>
        </TooltipPortal>
      )}
    </>
  );
};

const TooltipPortal = ({ children }: { children: ReactNode }) => {
  const { modalRenderElement } = useModalContext();
  if (!modalRenderElement) {
    throw Error('modalRenderElement is required');
  }
  const TooltipContent = <>{children}</>;
  return <>{createPortal(TooltipContent, modalRenderElement)}</>;
};
