/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { ConsumptionCategory } from './ConsumptionCategory';
import {
  ConsumptionCategoryFromJSON,
  ConsumptionCategoryFromJSONTyped,
  ConsumptionCategoryToJSON,
  ConsumptionCategoryToJSONTyped,
} from './ConsumptionCategory';

/**
 * Energy consumed by the house
 * @export
 * @interface HouseEnergyConsumptionKwh
 */
export interface HouseEnergyConsumptionKwh {
  /**
   * Consumption organized by category
   * @type {Array<ConsumptionCategory>}
   * @memberof HouseEnergyConsumptionKwh
   */
  categories?: Array<ConsumptionCategory>;
  /**
   * Total consumption in kWh
   * @type {number}
   * @memberof HouseEnergyConsumptionKwh
   */
  total?: number;
}

/**
 * Check if a given object implements the HouseEnergyConsumptionKwh interface.
 */
export function instanceOfHouseEnergyConsumptionKwh(
  value: object,
): value is HouseEnergyConsumptionKwh {
  return true;
}

export function HouseEnergyConsumptionKwhFromJSON(
  json: any,
): HouseEnergyConsumptionKwh {
  return HouseEnergyConsumptionKwhFromJSONTyped(json, false);
}

export function HouseEnergyConsumptionKwhFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseEnergyConsumptionKwh {
  if (json == null) {
    return json;
  }
  return {
    categories:
      json['categories'] == null
        ? undefined
        : (json['categories'] as Array<any>).map(ConsumptionCategoryFromJSON),
    total: json['total'] == null ? undefined : json['total'],
  };
}

export function HouseEnergyConsumptionKwhToJSON(
  json: any,
): HouseEnergyConsumptionKwh {
  return HouseEnergyConsumptionKwhToJSONTyped(json, false);
}

export function HouseEnergyConsumptionKwhToJSONTyped(
  value?: HouseEnergyConsumptionKwh | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    categories:
      value['categories'] == null
        ? undefined
        : (value['categories'] as Array<any>).map(ConsumptionCategoryToJSON),
    total: value['total'],
  };
}
