/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Tenant theme CSS variables
 * @export
 * @interface ThemeCssVariables
 */
export interface ThemeCssVariables {
  /**
   * Font family
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyFontFamily?: string;
  /**
   * Primary color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorPrimary?: string;
  /**
   * Secondary color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorSecondary?: string;
  /**
   * Text color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorText?: string;
  /**
   * Subtext color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorSubtext?: string;
  /**
   * Unselected color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorUnselected?: string;
  /**
   * Border color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyColorBorder?: string;
  /**
   * Button color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyButtonColor?: string;
  /**
   * Button border radius
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyButtonBorderRadius?: string;
  /**
   * Module header title color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyModuleHeaderTitleColor?: string;
  /**
   * Module header action color
   * @type {string}
   * @memberof ThemeCssVariables
   */
  clevergyModuleHeaderActionColor?: string;
}

/**
 * Check if a given object implements the ThemeCssVariables interface.
 */
export function instanceOfThemeCssVariables(
  value: object,
): value is ThemeCssVariables {
  return true;
}

export function ThemeCssVariablesFromJSON(json: any): ThemeCssVariables {
  return ThemeCssVariablesFromJSONTyped(json, false);
}

export function ThemeCssVariablesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ThemeCssVariables {
  if (json == null) {
    return json;
  }
  return {
    clevergyFontFamily:
      json['--clevergy-font-family'] == null
        ? undefined
        : json['--clevergy-font-family'],
    clevergyColorPrimary:
      json['--clevergy-color-primary'] == null
        ? undefined
        : json['--clevergy-color-primary'],
    clevergyColorSecondary:
      json['--clevergy-color-secondary'] == null
        ? undefined
        : json['--clevergy-color-secondary'],
    clevergyColorText:
      json['--clevergy-color-text'] == null
        ? undefined
        : json['--clevergy-color-text'],
    clevergyColorSubtext:
      json['--clevergy-color-subtext'] == null
        ? undefined
        : json['--clevergy-color-subtext'],
    clevergyColorUnselected:
      json['--clevergy-color-unselected'] == null
        ? undefined
        : json['--clevergy-color-unselected'],
    clevergyColorBorder:
      json['--clevergy-color-border'] == null
        ? undefined
        : json['--clevergy-color-border'],
    clevergyButtonColor:
      json['--clevergy-button-color'] == null
        ? undefined
        : json['--clevergy-button-color'],
    clevergyButtonBorderRadius:
      json['--clevergy-button-border-radius'] == null
        ? undefined
        : json['--clevergy-button-border-radius'],
    clevergyModuleHeaderTitleColor:
      json['--clevergy-module-header-title-color'] == null
        ? undefined
        : json['--clevergy-module-header-title-color'],
    clevergyModuleHeaderActionColor:
      json['--clevergy-module-header-action-color'] == null
        ? undefined
        : json['--clevergy-module-header-action-color'],
  };
}

export function ThemeCssVariablesToJSON(json: any): ThemeCssVariables {
  return ThemeCssVariablesToJSONTyped(json, false);
}

export function ThemeCssVariablesToJSONTyped(
  value?: ThemeCssVariables | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    '--clevergy-font-family': value['clevergyFontFamily'],
    '--clevergy-color-primary': value['clevergyColorPrimary'],
    '--clevergy-color-secondary': value['clevergyColorSecondary'],
    '--clevergy-color-text': value['clevergyColorText'],
    '--clevergy-color-subtext': value['clevergyColorSubtext'],
    '--clevergy-color-unselected': value['clevergyColorUnselected'],
    '--clevergy-color-border': value['clevergyColorBorder'],
    '--clevergy-button-color': value['clevergyButtonColor'],
    '--clevergy-button-border-radius': value['clevergyButtonBorderRadius'],
    '--clevergy-module-header-title-color':
      value['clevergyModuleHeaderTitleColor'],
    '--clevergy-module-header-action-color':
      value['clevergyModuleHeaderActionColor'],
  };
}
