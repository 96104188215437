import { useSelectedHouse } from './useSelectedHouse';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useMemo } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const EXCLUDED_DOMAINS = ['developer.clever.gy'];

export type UserCompletionsItem = {
  path: string;
  pending: boolean;
};

export type UserCompletionsWhileOnboardingState = {
  /**
   * Whether or not the state is resolving because of pending queries.
   */
  isResolving: boolean;
  /**
   * Whether or not there are pending user completeions.
   */
  hasPendingUserCompletions: () => boolean;
  /**
   * Returns the paths of the pending user completions.
   */
  getPendingUserCompletionsPaths: () => string[];
  /**
   * Returns the next pending user completion.
   * If there are no pending completions, returns undefined.
   */
  getNextPendingCompletion: () => UserCompletionsItem | undefined;
};

export const useUserCompletionsWhileOnboarding =
  (): UserCompletionsWhileOnboardingState => {
    const { api } = useApiContext();
    const { selectedHouse } = useSelectedHouse();

    // Get expectations answered by the user
    const expectationsQuery = useQuery({
      queryKey: [QueryKeys.GET_USER_EXPECTATIONS],
      queryFn: async () => await api.expectations.getUserExpectations(),
    });

    const houseSettingsQuery = useQuery({
      queryKey: [QueryKeys.GET_HOUSE_SETTINGS, selectedHouse?.houseId],
      queryFn: async () =>
        selectedHouse?.houseId
          ? await api.settings.getHouseSettings({
              houseId: selectedHouse?.houseId,
            })
          : skipToken,
    });

    const hasAnsweredHouseSettingsWhileOnBoarding = useAppConfigurationStore(
      (state) => state.hasAnsweredHouseSettingsWhileOnBoarding,
    );

    const steps = useMemo(() => {
      return {
        expectations: {
          path: '/expectations',
          pending:
            expectationsQuery.data?.hasUserAnsweredExpectations === false &&
            !EXCLUDED_DOMAINS.includes(window.location.hostname),
        },
        houseSettings: {
          path: '/onboarding-house-settings',
          pending:
            (houseSettingsQuery.isError || selectedHouse === null) &&
            !hasAnsweredHouseSettingsWhileOnBoarding &&
            !EXCLUDED_DOMAINS.includes(window.location.hostname),
        },
      };
    }, [
      expectationsQuery.data?.hasUserAnsweredExpectations,
      hasAnsweredHouseSettingsWhileOnBoarding,
      houseSettingsQuery.isError,
      selectedHouse,
    ]);

    const resultState: UserCompletionsWhileOnboardingState = {
      isResolving: expectationsQuery.isPending || houseSettingsQuery.isPending,
      hasPendingUserCompletions: () =>
        Object.values(steps).some((completion) => completion.pending),
      getPendingUserCompletionsPaths: () =>
        Object.values(steps).map((completion) => completion.path),
      getNextPendingCompletion: () =>
        Object.values(steps).find((completion) => completion.pending),
    };

    return resultState;
  };
