/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ContractLead
 */
export interface ContractLead {
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  tariffId: string;
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  houseId?: string;
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  contractedPower?: string;
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  holderName?: string;
  /**
   *
   * @type {string}
   * @memberof ContractLead
   */
  iban?: string;
}

/**
 * Check if a given object implements the ContractLead interface.
 */
export function instanceOfContractLead(value: object): value is ContractLead {
  if (!('userId' in value) || value['userId'] === undefined) return false;
  if (!('tariffId' in value) || value['tariffId'] === undefined) return false;
  return true;
}

export function ContractLeadFromJSON(json: any): ContractLead {
  return ContractLeadFromJSONTyped(json, false);
}

export function ContractLeadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContractLead {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'],
    tariffId: json['tariffId'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    contractedPower:
      json['contractedPower'] == null ? undefined : json['contractedPower'],
    holderName: json['holderName'] == null ? undefined : json['holderName'],
    iban: json['iban'] == null ? undefined : json['iban'],
  };
}

export function ContractLeadToJSON(json: any): ContractLead {
  return ContractLeadToJSONTyped(json, false);
}

export function ContractLeadToJSONTyped(
  value?: ContractLead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userId: value['userId'],
    tariffId: value['tariffId'],
    houseId: value['houseId'],
    contractedPower: value['contractedPower'],
    holderName: value['holderName'],
    iban: value['iban'],
  };
}
