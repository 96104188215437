import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type WrapperProps = {
  children: ReactNode;
};

export const Wrapper: FC<WrapperProps> = ({ children }) => {
  return (
    <div className={clsx('px-safe-or-4 py-4 grow shrink-0')}>{children}</div>
  );
};
