import {
  TrackedEvent,
  useAnalyticsContext,
} from '../../context/AnalyticsContext';
import { FC, ReactNode } from 'react';
import { useMount } from 'react-use';

export type TrackedViewMountProps = {
  event: TrackedEvent;
  children: ReactNode;
};

export const TrackedViewMount: FC<TrackedViewMountProps> = ({
  event,
  children,
}) => {
  const { track } = useAnalyticsContext();

  useMount(() => {
    track(event);
  });

  return <>{children}</>;
};
