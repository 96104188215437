/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface DataProviderInfo
 */
export interface DataProviderInfo {
  /**
   *
   * @type {Date}
   * @memberof DataProviderInfo
   */
  firstDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof DataProviderInfo
   */
  lastDate?: Date;
  /**
   *
   * @type {string}
   * @memberof DataProviderInfo
   */
  type?: DataProviderInfoTypeEnum;
}

/**
 * @export
 */
export const DataProviderInfoTypeEnum = {
  RemoteSelfConsumption: 'REMOTE_SELF_CONSUMPTION',
  CollectiveSelfConsumption: 'COLLECTIVE_SELF_CONSUMPTION',
  EnergyCommunity: 'ENERGY_COMMUNITY',
} as const;
export type DataProviderInfoTypeEnum =
  (typeof DataProviderInfoTypeEnum)[keyof typeof DataProviderInfoTypeEnum];

/**
 * Check if a given object implements the DataProviderInfo interface.
 */
export function instanceOfDataProviderInfo(
  value: object,
): value is DataProviderInfo {
  return true;
}

export function DataProviderInfoFromJSON(json: any): DataProviderInfo {
  return DataProviderInfoFromJSONTyped(json, false);
}

export function DataProviderInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataProviderInfo {
  if (json == null) {
    return json;
  }
  return {
    firstDate:
      json['firstDate'] == null ? undefined : new Date(json['firstDate']),
    lastDate: json['lastDate'] == null ? undefined : new Date(json['lastDate']),
    type: json['type'] == null ? undefined : json['type'],
  };
}

export function DataProviderInfoToJSON(json: any): DataProviderInfo {
  return DataProviderInfoToJSONTyped(json, false);
}

export function DataProviderInfoToJSONTyped(
  value?: DataProviderInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    firstDate:
      value['firstDate'] == null ? undefined : value['firstDate'].toISOString(),
    lastDate:
      value['lastDate'] == null ? undefined : value['lastDate'].toISOString(),
    type: value['type'],
  };
}
