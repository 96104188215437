/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserRegistration
 */
export interface UserRegistration {
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  userName?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  tenant?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistration
   */
  language?: string;
}

/**
 * Check if a given object implements the UserRegistration interface.
 */
export function instanceOfUserRegistration(
  value: object,
): value is UserRegistration {
  if (!('id' in value) || value['id'] === undefined) return false;
  return true;
}

export function UserRegistrationFromJSON(json: any): UserRegistration {
  return UserRegistrationFromJSONTyped(json, false);
}

export function UserRegistrationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRegistration {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    userName: json['userName'] == null ? undefined : json['userName'],
    surname: json['surname'] == null ? undefined : json['surname'],
    email: json['email'] == null ? undefined : json['email'],
    tenant: json['tenant'] == null ? undefined : json['tenant'],
    language: json['language'] == null ? undefined : json['language'],
  };
}

export function UserRegistrationToJSON(json: any): UserRegistration {
  return UserRegistrationToJSONTyped(json, false);
}

export function UserRegistrationToJSONTyped(
  value?: UserRegistration | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    userName: value['userName'],
    surname: value['surname'],
    email: value['email'],
    tenant: value['tenant'],
    language: value['language'],
  };
}
