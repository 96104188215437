import { useSupport } from '../hooks/useSupport';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const IssuesList: FC = () => {
  const navigate = useNavigate();
  const { issuesList } = useSupport();

  if (!issuesList) {
    return <LoadingIndicator centered />;
  }

  return (
    <List>
      {issuesList.map((subject, index) => {
        return (
          <List.Item
            key={index}
            withBorder={false}
            rightIcon={<FontIcon name="chevron_right" />}
            onClick={() =>
              navigate(`/support/tickets/create?subject=${subject}`)
            }
          >
            {subject}
          </List.Item>
        );
      })}
    </List>
  );
};
