/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateAlexaUserAuthCodeResponse
 */
export interface CreateAlexaUserAuthCodeResponse {
  /**
   *
   * @type {string}
   * @memberof CreateAlexaUserAuthCodeResponse
   */
  userAuthCode?: string;
}

/**
 * Check if a given object implements the CreateAlexaUserAuthCodeResponse interface.
 */
export function instanceOfCreateAlexaUserAuthCodeResponse(
  value: object,
): value is CreateAlexaUserAuthCodeResponse {
  return true;
}

export function CreateAlexaUserAuthCodeResponseFromJSON(
  json: any,
): CreateAlexaUserAuthCodeResponse {
  return CreateAlexaUserAuthCodeResponseFromJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateAlexaUserAuthCodeResponse {
  if (json == null) {
    return json;
  }
  return {
    userAuthCode:
      json['userAuthCode'] == null ? undefined : json['userAuthCode'],
  };
}

export function CreateAlexaUserAuthCodeResponseToJSON(
  json: any,
): CreateAlexaUserAuthCodeResponse {
  return CreateAlexaUserAuthCodeResponseToJSONTyped(json, false);
}

export function CreateAlexaUserAuthCodeResponseToJSONTyped(
  value?: CreateAlexaUserAuthCodeResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userAuthCode: value['userAuthCode'],
  };
}
