import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import {
  InvalidateQueryFilters,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { FC, ReactNode } from 'react';

const queryClient = new QueryClient();

export type QueryProviderProps = {
  children: ReactNode;
};

export const QueryProvider: FC<QueryProviderProps> = ({ children }) => {
  queryClient.setDefaultOptions({
    queries: {
      staleTime: 1000_000,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  });

  useClevergyEvent(ClevergyEventTypes.INVALIDATE_ALL_QUERIES, () => {
    queryClient.invalidateQueries();
  });

  useClevergyEvent(ClevergyEventTypes.INVALIDATE_QUERY, (e) => {
    const queryFilter = e.data as InvalidateQueryFilters;
    queryClient.invalidateQueries(queryFilter);
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
