/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UploadInvoiceUrl
 */
export interface UploadInvoiceUrl {
  /**
   *
   * @type {string}
   * @memberof UploadInvoiceUrl
   */
  url?: string;
}

/**
 * Check if a given object implements the UploadInvoiceUrl interface.
 */
export function instanceOfUploadInvoiceUrl(
  value: object,
): value is UploadInvoiceUrl {
  return true;
}

export function UploadInvoiceUrlFromJSON(json: any): UploadInvoiceUrl {
  return UploadInvoiceUrlFromJSONTyped(json, false);
}

export function UploadInvoiceUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadInvoiceUrl {
  if (json == null) {
    return json;
  }
  return {
    url: json['url'] == null ? undefined : json['url'],
  };
}

export function UploadInvoiceUrlToJSON(json: any): UploadInvoiceUrl {
  return UploadInvoiceUrlToJSONTyped(json, false);
}

export function UploadInvoiceUrlToJSONTyped(
  value?: UploadInvoiceUrl | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    url: value['url'],
  };
}
