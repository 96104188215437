/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Comment information
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   * Comment identifier
   * @type {string}
   * @memberof Comment
   */
  id: string;
  /**
   * Comment creation date
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
  /**
   * Comment author
   * @type {string}
   * @memberof Comment
   */
  author: CommentAuthorEnum;
  /**
   * Comment body
   * @type {string}
   * @memberof Comment
   */
  body: string;
}

/**
 * @export
 */
export const CommentAuthorEnum = {
  User: 'USER',
  Support: 'SUPPORT',
} as const;
export type CommentAuthorEnum =
  (typeof CommentAuthorEnum)[keyof typeof CommentAuthorEnum];

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): value is Comment {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('author' in value) || value['author'] === undefined) return false;
  if (!('body' in value) || value['body'] === undefined) return false;
  return true;
}

export function CommentFromJSON(json: any): Comment {
  return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Comment {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: json['createdAt'],
    author: json['author'],
    body: json['body'],
  };
}

export function CommentToJSON(json: any): Comment {
  return CommentToJSONTyped(json, false);
}

export function CommentToJSONTyped(
  value?: Comment | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    createdAt: value['createdAt'],
    author: value['author'],
    body: value['body'],
  };
}
