/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Detail of smart device
 * @export
 * @interface GetSmartDevicesResponse
 */
export interface GetSmartDevicesResponse {
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  deviceId: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  vendor: string;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  subtype: GetSmartDevicesResponseSubtypeEnum;
  /**
   *
   * @type {number}
   * @memberof GetSmartDevicesResponse
   */
  channel: number;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  status: GetSmartDevicesResponseStatusEnum;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  model: GetSmartDevicesResponseModelEnum;
  /**
   *
   * @type {string}
   * @memberof GetSmartDevicesResponse
   */
  deviceType: GetSmartDevicesResponseDeviceTypeEnum;
}

/**
 * @export
 */
export const GetSmartDevicesResponseSubtypeEnum = {
  Grid: 'GRID',
  SelfProduction: 'SELF_PRODUCTION',
  Battery: 'BATTERY',
  Export: 'EXPORT',
  Dishwasher: 'DISHWASHER',
  Kitchen: 'KITCHEN',
  Fridge: 'FRIDGE',
  Freezer: 'FREEZER',
  Microwave: 'MICROWAVE',
  Oven: 'OVEN',
  Ac: 'AC',
  PlugAc: 'PLUG_AC',
  WashingMachine: 'WASHING_MACHINE',
  WaterHeater: 'WATER_HEATER',
  Workstation: 'WORKSTATION',
  Vacuum: 'VACUUM',
  Heater: 'HEATER',
  PlugHeater: 'PLUG_HEATER',
  Tv: 'TV',
  Dryer: 'DRYER',
  Light: 'LIGHT',
  Pool: 'POOL',
  Aerothermal: 'AEROTHERMAL',
  Unknown: 'UNKNOWN',
  Other: 'OTHER',
} as const;
export type GetSmartDevicesResponseSubtypeEnum =
  (typeof GetSmartDevicesResponseSubtypeEnum)[keyof typeof GetSmartDevicesResponseSubtypeEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseStatusEnum = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
} as const;
export type GetSmartDevicesResponseStatusEnum =
  (typeof GetSmartDevicesResponseStatusEnum)[keyof typeof GetSmartDevicesResponseStatusEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseModelEnum = {
  PlugS: 'SHELLY_PLUG_S',
  Em: 'SHELLY_EM',
} as const;
export type GetSmartDevicesResponseModelEnum =
  (typeof GetSmartDevicesResponseModelEnum)[keyof typeof GetSmartDevicesResponseModelEnum];

/**
 * @export
 */
export const GetSmartDevicesResponseDeviceTypeEnum = {
  Relay: 'RELAY',
  Emeter: 'EMETER',
} as const;
export type GetSmartDevicesResponseDeviceTypeEnum =
  (typeof GetSmartDevicesResponseDeviceTypeEnum)[keyof typeof GetSmartDevicesResponseDeviceTypeEnum];

/**
 * Check if a given object implements the GetSmartDevicesResponse interface.
 */
export function instanceOfGetSmartDevicesResponse(
  value: object,
): value is GetSmartDevicesResponse {
  if (!('deviceId' in value) || value['deviceId'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('vendor' in value) || value['vendor'] === undefined) return false;
  if (!('subtype' in value) || value['subtype'] === undefined) return false;
  if (!('channel' in value) || value['channel'] === undefined) return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  if (!('model' in value) || value['model'] === undefined) return false;
  if (!('deviceType' in value) || value['deviceType'] === undefined)
    return false;
  return true;
}

export function GetSmartDevicesResponseFromJSON(
  json: any,
): GetSmartDevicesResponse {
  return GetSmartDevicesResponseFromJSONTyped(json, false);
}

export function GetSmartDevicesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): GetSmartDevicesResponse {
  if (json == null) {
    return json;
  }
  return {
    deviceId: json['deviceId'],
    name: json['name'],
    vendor: json['vendor'],
    subtype: json['subtype'],
    channel: json['channel'],
    status: json['status'],
    model: json['model'],
    deviceType: json['deviceType'],
  };
}

export function GetSmartDevicesResponseToJSON(
  json: any,
): GetSmartDevicesResponse {
  return GetSmartDevicesResponseToJSONTyped(json, false);
}

export function GetSmartDevicesResponseToJSONTyped(
  value?: GetSmartDevicesResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    deviceId: value['deviceId'],
    name: value['name'],
    vendor: value['vendor'],
    subtype: value['subtype'],
    channel: value['channel'],
    status: value['status'],
    model: value['model'],
    deviceType: value['deviceType'],
  };
}
