/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  HouseInvoiceDetail,
  HouseInvoiceSummaryResponse,
  HttpError,
  InteractiveHouseInvoice,
} from '../models/index';
import {
  HouseInvoiceDetailFromJSON,
  HouseInvoiceDetailToJSON,
  HouseInvoiceSummaryResponseFromJSON,
  HouseInvoiceSummaryResponseToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  InteractiveHouseInvoiceFromJSON,
  InteractiveHouseInvoiceToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetHouseInvoicesByYearRequest {
  houseId: string;
  year?: number;
}

export interface GetHouseLastInvoiceRequest {
  houseId: string;
}

export interface GetInteractiveHouseInvoiceRequest {
  houseId: string;
  invoiceId: string;
}

export interface GetLastInteractiveHouseInvoiceRequest {
  houseId: string;
}

/**
 *
 */
export class InvoicesApi extends runtime.BaseAPI {
  /**
   * Get the invoices of a house by year.
   */
  async getHouseInvoicesByYearRaw(
    requestParameters: GetHouseInvoicesByYearRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<HouseInvoiceSummaryResponse>>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getHouseInvoicesByYear().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['year'] != null) {
      queryParameters['year'] = requestParameters['year'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/invoices`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(HouseInvoiceSummaryResponseFromJSON),
    );
  }

  /**
   * Get the invoices of a house by year.
   */
  async getHouseInvoicesByYear(
    requestParameters: GetHouseInvoicesByYearRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<HouseInvoiceSummaryResponse>> {
    const response = await this.getHouseInvoicesByYearRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get a detailed description of the last invoice
   */
  async getHouseLastInvoiceRaw(
    requestParameters: GetHouseLastInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HouseInvoiceDetail>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getHouseLastInvoice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/last-invoice`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseInvoiceDetailFromJSON(jsonValue),
    );
  }

  /**
   * Get a detailed description of the last invoice
   */
  async getHouseLastInvoice(
    requestParameters: GetHouseLastInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HouseInvoiceDetail | null | undefined> {
    const response = await this.getHouseLastInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    switch (response.raw.status) {
      case 200:
        return await response.value();
      case 204:
        return null;
      default:
        return await response.value();
    }
  }

  /**
   * Get specific interactive invoice comparing an invoice and the previous one
   */
  async getInteractiveHouseInvoiceRaw(
    requestParameters: GetInteractiveHouseInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractiveHouseInvoice>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getInteractiveHouseInvoice().',
      );
    }

    if (requestParameters['invoiceId'] == null) {
      throw new runtime.RequiredError(
        'invoiceId',
        'Required parameter "invoiceId" was null or undefined when calling getInteractiveHouseInvoice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/interactive-invoice/{invoiceId}`
          .replace(
            `{${'houseId'}}`,
            encodeURIComponent(String(requestParameters['houseId'])),
          )
          .replace(
            `{${'invoiceId'}}`,
            encodeURIComponent(String(requestParameters['invoiceId'])),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractiveHouseInvoiceFromJSON(jsonValue),
    );
  }

  /**
   * Get specific interactive invoice comparing an invoice and the previous one
   */
  async getInteractiveHouseInvoice(
    requestParameters: GetInteractiveHouseInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractiveHouseInvoice> {
    const response = await this.getInteractiveHouseInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Get last interactive invoice comparing an invoice and the previous one
   */
  async getLastInteractiveHouseInvoiceRaw(
    requestParameters: GetLastInteractiveHouseInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractiveHouseInvoice>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getLastInteractiveHouseInvoice().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/last-interactive-invoice`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractiveHouseInvoiceFromJSON(jsonValue),
    );
  }

  /**
   * Get last interactive invoice comparing an invoice and the previous one
   */
  async getLastInteractiveHouseInvoice(
    requestParameters: GetLastInteractiveHouseInvoiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractiveHouseInvoice> {
    const response = await this.getLastInteractiveHouseInvoiceRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
