import { Button } from '@clevergy/ui/components/Button';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { AppVersionInfo } from 'components/AppVersionInfo';
import { LegalFooter } from 'components/LegalFooter';
import { LoginWithEmailForm } from 'components/LoginWithEmailForm';
import { Page } from 'components/Page';
import { SocialLoginButtons } from 'components/SocialLoginButtons';
import { TenantPageIntroWithLogo } from 'components/TenantPageIntroWithLogo';
import { Wrapper } from 'components/Wrapper';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const emailLoginFormAnimationVariants = {
  closed: { opacity: 0, y: 20 },
  open: { opacity: 1, y: 0 },
};

export const LoginPage: FC = () => {
  const { t } = useTranslation();
  const { hasAppRegistrationEnabled, hasSocialLoginEnabled } =
    useTenantSettings();

  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [isSocialLoginInProgress, setIsSocialLoginInProgress] = useState(false);

  const emailLoginFormContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showEmailLogin) {
      emailLoginFormContainerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [showEmailLogin]);

  return (
    <Page>
      <AnimatePresence>
        <Wrapper>
          {isSocialLoginInProgress && <LoadingIndicator fullScreen="overlay" />}

          <div className="flex h-full flex-col gap-6 p-safe">
            <div className="flex grow flex-col justify-center">
              <TenantPageIntroWithLogo title={t('login.title') as string} />
            </div>

            <div className="flex flex-col gap-3">
              <SocialLoginButtons
                onLoginStarted={() => setIsSocialLoginInProgress(true)}
                onLoginFinished={() => setIsSocialLoginInProgress(false)}
              />

              {!showEmailLogin && hasSocialLoginEnabled && (
                <Button
                  type="button"
                  variant="filled"
                  fullWidth
                  onClick={() => setShowEmailLogin(true)}
                  testId="show-email-login-button"
                >
                  {t('login.email.button')}
                </Button>
              )}

              {(showEmailLogin || !hasSocialLoginEnabled) && (
                <motion.div
                  ref={emailLoginFormContainerRef}
                  variants={emailLoginFormAnimationVariants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                >
                  <LoginWithEmailForm />
                </motion.div>
              )}

              {hasAppRegistrationEnabled && (
                <p className="text-clevergy-subtext text-center font-semibold">
                  {t('login.signup.title')}{' '}
                  <Link to="/signup" className="text-clevergy-primary">
                    {t('login.signup.link')}
                  </Link>
                </p>
              )}
            </div>

            <LegalFooter />

            <AppVersionInfo />
          </div>
        </Wrapper>
      </AnimatePresence>
    </Page>
  );
};
