/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarRecommendation
 */
export interface SolarRecommendation {
  /**
   *
   * @type {boolean}
   * @memberof SolarRecommendation
   */
  isSolarRecommended?: boolean;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  totalKwh?: number;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  kwhInSunHours?: number;
  /**
   *
   * @type {number}
   * @memberof SolarRecommendation
   */
  percentageOfKwhInSunHours?: number;
}

/**
 * Check if a given object implements the SolarRecommendation interface.
 */
export function instanceOfSolarRecommendation(
  value: object,
): value is SolarRecommendation {
  return true;
}

export function SolarRecommendationFromJSON(json: any): SolarRecommendation {
  return SolarRecommendationFromJSONTyped(json, false);
}

export function SolarRecommendationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarRecommendation {
  if (json == null) {
    return json;
  }
  return {
    isSolarRecommended:
      json['isSolarRecommended'] == null
        ? undefined
        : json['isSolarRecommended'],
    totalKwh: json['totalKwh'] == null ? undefined : json['totalKwh'],
    kwhInSunHours:
      json['kwhInSunHours'] == null ? undefined : json['kwhInSunHours'],
    percentageOfKwhInSunHours:
      json['percentageOfKwhInSunHours'] == null
        ? undefined
        : json['percentageOfKwhInSunHours'],
  };
}

export function SolarRecommendationToJSON(json: any): SolarRecommendation {
  return SolarRecommendationToJSONTyped(json, false);
}

export function SolarRecommendationToJSONTyped(
  value?: SolarRecommendation | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    isSolarRecommended: value['isSolarRecommended'],
    totalKwh: value['totalKwh'],
    kwhInSunHours: value['kwhInSunHours'],
    percentageOfKwhInSunHours: value['percentageOfKwhInSunHours'],
  };
}
