import { HouseSettings } from '@clevergy/api-client';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = { houseSettings: undefined };

export type HouseSettingsBackupStoreState = {
  houseSettings: HouseSettings | undefined;
  setHouseSettings: (houseSettings: HouseSettings) => void;
  reset: () => void;
};

export const useHouseSettingsBackupStore =
  create<HouseSettingsBackupStoreState>()(
    persist(
      (set) => ({
        ...initialState,
        setHouseSettings: (houseSettings) => set({ houseSettings }),
        reset: () => set({ ...initialState }),
      }),
      {
        name: 'houseSettingsBackupStoreState',
      },
    ),
  );
