/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyComparison
 */
export interface EnergyComparison {
  /**
   * Energy consumption of the house
   * @type {number}
   * @memberof EnergyComparison
   */
  consumption?: number;
  /**
   * If the comparison is for the current year
   * @type {boolean}
   * @memberof EnergyComparison
   */
  currentYear: boolean;
  /**
   * Current profile of the house
   * @type {string}
   * @memberof EnergyComparison
   */
  profile: EnergyComparisonProfileEnum;
  /**
   * Average consumption of similar homes. Can  be null (depends on configuration)
   * @type {number}
   * @memberof EnergyComparison
   */
  similarHomesConsumption?: number;
  /**
   * Average consumption of energy efficient homes. Can be null (depends on configuration)
   * @type {number}
   * @memberof EnergyComparison
   */
  energyEfficientHomesConsumption?: number;
  /**
   * Average consumption of homes in the neighborhood
   * @type {number}
   * @memberof EnergyComparison
   */
  neighborhoodHomesConsumption?: number;
}

/**
 * @export
 */
export const EnergyComparisonProfileEnum = {
  Efficient: 'EFFICIENT,',
  Medium: 'MEDIUM,',
  Inefficient: 'INEFFICIENT',
  NoProfile: 'NO_PROFILE',
} as const;
export type EnergyComparisonProfileEnum =
  (typeof EnergyComparisonProfileEnum)[keyof typeof EnergyComparisonProfileEnum];

/**
 * Check if a given object implements the EnergyComparison interface.
 */
export function instanceOfEnergyComparison(
  value: object,
): value is EnergyComparison {
  if (!('currentYear' in value) || value['currentYear'] === undefined)
    return false;
  if (!('profile' in value) || value['profile'] === undefined) return false;
  return true;
}

export function EnergyComparisonFromJSON(json: any): EnergyComparison {
  return EnergyComparisonFromJSONTyped(json, false);
}

export function EnergyComparisonFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyComparison {
  if (json == null) {
    return json;
  }
  return {
    consumption: json['consumption'] == null ? undefined : json['consumption'],
    currentYear: json['currentYear'],
    profile: json['profile'],
    similarHomesConsumption:
      json['similarHomesConsumption'] == null
        ? undefined
        : json['similarHomesConsumption'],
    energyEfficientHomesConsumption:
      json['energyEfficientHomesConsumption'] == null
        ? undefined
        : json['energyEfficientHomesConsumption'],
    neighborhoodHomesConsumption:
      json['neighborhoodHomesConsumption'] == null
        ? undefined
        : json['neighborhoodHomesConsumption'],
  };
}

export function EnergyComparisonToJSON(json: any): EnergyComparison {
  return EnergyComparisonToJSONTyped(json, false);
}

export function EnergyComparisonToJSONTyped(
  value?: EnergyComparison | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    consumption: value['consumption'],
    currentYear: value['currentYear'],
    profile: value['profile'],
    similarHomesConsumption: value['similarHomesConsumption'],
    energyEfficientHomesConsumption: value['energyEfficientHomesConsumption'],
    neighborhoodHomesConsumption: value['neighborhoodHomesConsumption'],
  };
}
