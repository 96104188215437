/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { HouseEnergyConsumptionKwh } from './HouseEnergyConsumptionKwh';
import {
  HouseEnergyConsumptionKwhFromJSON,
  HouseEnergyConsumptionKwhFromJSONTyped,
  HouseEnergyConsumptionKwhToJSON,
  HouseEnergyConsumptionKwhToJSONTyped,
} from './HouseEnergyConsumptionKwh';

/**
 *
 * @export
 * @interface HouseEnergy
 */
export interface HouseEnergy {
  /**
   * Date of the consumption. This value depends on the granularity parameter. The dates could be organized by hour, day or month.
   * @type {Date}
   * @memberof HouseEnergy
   */
  date?: Date;
  /**
   *
   * @type {HouseEnergyConsumptionKwh}
   * @memberof HouseEnergy
   */
  consumptionKwh?: HouseEnergyConsumptionKwh;
  /**
   * Energy consumed from the grid
   * @type {number}
   * @memberof HouseEnergy
   */
  outGridKwh?: number;
  /**
   * Energy produced by the house
   * @type {number}
   * @memberof HouseEnergy
   */
  selfProductionKwh?: number;
  /**
   * Energy injected to the grid
   * @type {number}
   * @memberof HouseEnergy
   */
  onGridKwh?: number;
  /**
   * Energy consumed by the house from its own production
   * @type {number}
   * @memberof HouseEnergy
   */
  selfConsumptionKwh?: number;
}

/**
 * Check if a given object implements the HouseEnergy interface.
 */
export function instanceOfHouseEnergy(value: object): value is HouseEnergy {
  return true;
}

export function HouseEnergyFromJSON(json: any): HouseEnergy {
  return HouseEnergyFromJSONTyped(json, false);
}

export function HouseEnergyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseEnergy {
  if (json == null) {
    return json;
  }
  return {
    date: json['date'] == null ? undefined : new Date(json['date']),
    consumptionKwh:
      json['consumptionKwh'] == null
        ? undefined
        : HouseEnergyConsumptionKwhFromJSON(json['consumptionKwh']),
    outGridKwh: json['outGridKwh'] == null ? undefined : json['outGridKwh'],
    selfProductionKwh:
      json['selfProductionKwh'] == null ? undefined : json['selfProductionKwh'],
    onGridKwh: json['onGridKwh'] == null ? undefined : json['onGridKwh'],
    selfConsumptionKwh:
      json['selfConsumptionKwh'] == null
        ? undefined
        : json['selfConsumptionKwh'],
  };
}

export function HouseEnergyToJSON(json: any): HouseEnergy {
  return HouseEnergyToJSONTyped(json, false);
}

export function HouseEnergyToJSONTyped(
  value?: HouseEnergy | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    date: value['date'] == null ? undefined : value['date'].toISOString(),
    consumptionKwh: HouseEnergyConsumptionKwhToJSON(value['consumptionKwh']),
    outGridKwh: value['outGridKwh'],
    selfProductionKwh: value['selfProductionKwh'],
    onGridKwh: value['onGridKwh'],
    selfConsumptionKwh: value['selfConsumptionKwh'],
  };
}
