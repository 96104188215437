import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type HousePickerStoreState = {
  selectedHouseId: string | undefined;
};

export type HousePickerStoreActions = {
  setSelectedHouseId: (selectedHouseId: string) => void;
};

const initialState: HousePickerStoreState = {
  selectedHouseId: undefined,
};

export type HousePickerStore = HousePickerStoreState & HousePickerStoreActions;

export const useHousePickerStore = create<HousePickerStore>()(
  persist(
    (set) => ({
      ...initialState,
      setSelectedHouseId: (selectedHouseId: string) => set({ selectedHouseId }),
    }),
    {
      name: 'housePickerStoreState',
    },
  ),
);
