import { Browser } from '@capacitor/browser';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useMemo } from 'react';

export const useShellyConnect = () => {
  const { selectedHouse } = useSelectedHouse();
  const queryClient = useQueryClient();

  const handleOnShellyConnectFinished = () => {
    queryClient.invalidateQueries({
      queryKey: [
        QueryKeys.GET_SMART_DEVICES,
        { houseId: selectedHouse?.houseId },
      ],
    });
  };

  const shellyConnectUrl = useMemo(() => {
    if (!selectedHouse?.houseId) {
      return null;
    }

    const shellyIntegratorUrl = 'https://my.shelly.cloud/integrator.html';
    const shellyCallbackUrl = `https://api.clever.gy/houses/${selectedHouse.houseId}/integrations/shelly`;

    const shellyCustomField = 'clevergy';
    const shellyIntegratorParams = new URLSearchParams({
      itg: 'ITG_CVG',
      cb: shellyCallbackUrl,
      customField: shellyCustomField,
    });

    return `${shellyIntegratorUrl}?${shellyIntegratorParams.toString()}`;
  }, [selectedHouse?.houseId]);

  const openShellyConnect = async (options?: { onFinished?: () => void }) => {
    const { onFinished } = options || {};

    if (!shellyConnectUrl) {
      return;
    }

    await Browser.addListener('browserFinished', () => {
      Browser.removeAllListeners();
      handleOnShellyConnectFinished();
      onFinished?.();
    });

    await Browser.open({ url: shellyConnectUrl });
  };

  return { openShellyConnect };
};
