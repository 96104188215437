import { Device } from '@capacitor/device';
import { HouseInvoiceSummaryResponse } from '@clevergy/api-client';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { NumberWithDecimals } from '@clevergy/ui/components/NumberWithDecimals';
import { downloadFileFromUrl } from '@clevergy/utils/browser/downloadFileFromUrl';
import clsx from 'clsx';
import { useAuthContext } from 'context/AuthContext';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type InvoiceItemProps = {
  invoice: HouseInvoiceSummaryResponse;
};

export const InvoiceItem: FC<InvoiceItemProps> = ({ invoice }) => {
  const { token } = useAuthContext();
  const {
    i18n: { language },
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadInvoice = async (
    invoice: HouseInvoiceSummaryResponse,
  ) => {
    setIsLoading(true);
    const deviceInfo = await Device.getInfo();

    if (invoice.urlFile) {
      downloadFileFromUrl({
        url: invoice.urlFile,
        name: invoice.startDate
          ? format(invoice.startDate, 'yyyy-MM-dd')
          : 'invoice',
        target: deviceInfo.platform !== 'ios' ? '_blank' : undefined,
      });

      setIsLoading(false);
    }
  };

  const getInvoiceDate = (invoiceDateTo: Date) =>
    invoiceDateTo.toLocaleDateString('es-ES', {
      month: 'short',
      day: 'numeric',
    });

  if (!invoice.id) {
    return null;
  }

  return (
    <List.Item key={invoice.id}>
      <div className="flex w-full justify-between px-2 text-lg capitalize">
        <div className="text-left text-base">
          {invoice.startDate && getInvoiceDate(invoice.startDate)} - {''}
          {invoice.endDate && getInvoiceDate(invoice.endDate)}
        </div>
        <div className="text-right font-semibold">
          <NumberWithDecimals
            unit="€"
            precision={2}
            language={language}
            value={invoice.totalCost || 0}
          />
        </div>
        <div className="text-clevergy-primary basis-6">
          {token && invoice.urlFile && (
            <button
              type="button"
              disabled={isLoading}
              className={clsx('', {
                'text-gray-500': isLoading,
              })}
              onClick={() => handleDownloadInvoice(invoice)}
            >
              <FontIcon name="download" />
            </button>
          )}
        </div>
      </div>
    </List.Item>
  );
};
