import { FC } from 'react';
import { Outlet } from 'react-router';

export const AppLayout: FC = () => {
  return (
    <div className="absolute inset-0 flex flex-col justify-between h-full w-full min-h-full">
      <Outlet />
    </div>
  );
};
