/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * New comment information
 * @export
 * @interface NewComment
 */
export interface NewComment {
  /**
   * Comment body
   * @type {string}
   * @memberof NewComment
   */
  body: string;
}

/**
 * Check if a given object implements the NewComment interface.
 */
export function instanceOfNewComment(value: object): value is NewComment {
  if (!('body' in value) || value['body'] === undefined) return false;
  return true;
}

export function NewCommentFromJSON(json: any): NewComment {
  return NewCommentFromJSONTyped(json, false);
}

export function NewCommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NewComment {
  if (json == null) {
    return json;
  }
  return {
    body: json['body'],
  };
}

export function NewCommentToJSON(json: any): NewComment {
  return NewCommentToJSONTyped(json, false);
}

export function NewCommentToJSONTyped(
  value?: NewComment | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    body: value['body'],
  };
}
