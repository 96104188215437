import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Button } from '@clevergy/ui/components/Button';
import { Icon } from '@clevergy/ui/components/Icon';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type SocialLoginButtonsProps = {
  onLoginStarted?: () => void;
  onLoginFinished?: () => void;
};

export const SocialLoginButtons: FC<SocialLoginButtonsProps> = (props) => {
  const { onLoginStarted, onLoginFinished } = props;
  const { t } = useTranslation();
  const { hasSocialLoginEnabled, hasAppRegistrationEnabled } =
    useTenantSettings();

  const handleSignInWithGoogle = async () => {
    onLoginStarted?.();
    await FirebaseAuthentication.signInWithGoogle();
    onLoginFinished?.();
  };

  const handleSignInWithApple = async () => {
    onLoginStarted?.();
    await FirebaseAuthentication.signInWithApple();
    onLoginFinished?.();
  };

  if (!hasSocialLoginEnabled || !hasAppRegistrationEnabled) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        colorScheme="neutral"
        fullWidth
        onClick={() => handleSignInWithGoogle()}
        testId="social-login-google-button"
      >
        <Icon name="SocialGoogle" />
        {t('login.google')}
      </Button>
      <Button
        variant="outlined"
        colorScheme="neutral"
        fullWidth
        onClick={() => handleSignInWithApple()}
        testId="social-login-apple-button"
      >
        <Icon name="SocialApple" />
        {t('login.apple')}
      </Button>
    </>
  );
};
