import { MutationKeys } from '@clevergy/shared/constants/mutationKeys';
import { Button } from '@clevergy/ui/components/Button';
import { InputTextArea } from '@clevergy/ui/components/InputTextArea';
import { useIsMutating, useMutation } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type SupportTicketRespondFormSchema = {
  ticketId: string;
  comment: string;
};

export const SupportTicketRespondPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApiContext();
  const { ticketId } = useParams();

  const sendCommentMutation = useMutation({
    mutationKey: [MutationKeys.SEND_COMMENT],
    mutationFn: (params: SupportTicketRespondFormSchema) => {
      return api.tickets.addComment({
        ticketId: params.ticketId,
        body: {
          body: params.comment,
        },
      });
    },
    onSuccess: () => {
      onFinish();
    },
  });

  const isMutationLoading = useIsMutating({
    mutationKey: [MutationKeys.SEND_COMMENT],
  });

  const { register, handleSubmit, formState, clearErrors } =
    useForm<SupportTicketRespondFormSchema>({
      defaultValues: {
        ticketId,
      },
    });

  const handleSubmitRespondTicketForm = (
    data: SupportTicketRespondFormSchema,
  ) => sendCommentMutation.mutate(data);

  const onFinish = () => {
    navigate('/support/tickets/success');
  };

  if (!ticketId) {
    return null;
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath={`/support/tickets/${ticketId}`}
        title={t('support.respondTicket.title') as string}
      />
      <Wrapper>
        <form
          onSubmit={handleSubmit(handleSubmitRespondTicketForm)}
          className="flex w-full flex-col gap-4 justify-center"
        >
          <InputTextArea
            id="comment"
            rows={12}
            autoComplete="off"
            placeholder={t('support.respondTicket.form.comment.placeholder')}
            error={formState.errors.comment?.message}
            {...register('comment', {
              required: t(
                'support.respondTicket.form.comment.required',
              ) as string,
            })}
          />

          <Button
            fullWidth
            type="submit"
            variant="filled"
            disabled={
              formState.isSubmitting ||
              !formState.isValid ||
              Boolean(isMutationLoading)
            }
            busy={formState.isSubmitting || Boolean(isMutationLoading)}
            onClick={() => clearErrors()}
          >
            {t('support.respondTicket.form.actions.submit')}
          </Button>
        </form>
      </Wrapper>
    </Page>
  );
};
