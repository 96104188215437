/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateLead
 */
export interface CreateLead {
  /**
   *
   * @type {string}
   * @memberof CreateLead
   */
  leadType?: CreateLeadLeadTypeEnum;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CreateLead
   */
  payload?: { [key: string]: string };
}

/**
 * @export
 */
export const CreateLeadLeadTypeEnum = {
  Aerothermal: 'AEROTHERMAL',
  Battery: 'BATTERY',
  RateChange: 'RATE_CHANGE',
  Solar: 'SOLAR',
  Banner: 'BANNER',
} as const;
export type CreateLeadLeadTypeEnum =
  (typeof CreateLeadLeadTypeEnum)[keyof typeof CreateLeadLeadTypeEnum];

/**
 * Check if a given object implements the CreateLead interface.
 */
export function instanceOfCreateLead(value: object): value is CreateLead {
  return true;
}

export function CreateLeadFromJSON(json: any): CreateLead {
  return CreateLeadFromJSONTyped(json, false);
}

export function CreateLeadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateLead {
  if (json == null) {
    return json;
  }
  return {
    leadType: json['leadType'] == null ? undefined : json['leadType'],
    payload: json['payload'] == null ? undefined : json['payload'],
  };
}

export function CreateLeadToJSON(json: any): CreateLead {
  return CreateLeadToJSONTyped(json, false);
}

export function CreateLeadToJSONTyped(
  value?: CreateLead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    leadType: value['leadType'],
    payload: value['payload'],
  };
}
