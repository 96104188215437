import { FC } from 'react';
import { Navigate, useLocation } from 'react-router';

export type ConditionalRedirectProps = {
  /**
   * Path to redirect to
   * @example '/expectations'
   */
  targetPath: string;
  /**
   * If defined, only in these paths redirect will be triggered
   */
  includedPaths?: string[];
  /**
   * Paths that should not trigger redirect.
   * By default, targetPath is always excluded to avoid infinite redirect loop.
   */
  excludedPaths?: string[];
  /**
   * Condition that triggers redirect
   */
  redirectCondition: boolean;
};

export const ConditionalRedirect: FC<ConditionalRedirectProps> = (props) => {
  const location = useLocation();
  const { targetPath, includedPaths, excludedPaths, redirectCondition } = props;
  const resolvedAllowedPaths = [targetPath, ...(excludedPaths || [])];

  const enabled =
    redirectCondition &&
    (!includedPaths ||
      includedPaths?.some((path) => location.pathname === path)) &&
    !resolvedAllowedPaths?.some((path) => location.pathname === path);

  if (enabled) {
    return <Navigate to={targetPath} state={{ from: location }} replace />;
  }

  return null;
};
