export enum DateRangeType {
  Day = 'day',
  Month = 'month',
  Year = 'year',
  Custom = 'custom',
}

export enum GranularityEnum {
  /**
   * @deprecated
   */
  Yearly = 'YEARLY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Daily = 'DAILY',
}
