import { CSSProperties, ReactNode } from 'react';

export type SideBarContainerProps = {
  children: ReactNode;
};

export const SideBarContainer = ({ children }: SideBarContainerProps) => {
  return (
    <>
      <div
        id="sidebar-container"
        data-testid="sidebar-container"
        className="hidden overflow-y-auto xl:block xl:fixed  xl:right-4 xl:w-[320px]"
        style={
          {
            '--clevergy-module-header-title-color': 'none',
            '--clevergy-module-header-action-color': 'none',
            '--clevergy-module-container-background': 'none',
            '--clevergy-module-container-border': 'none',
            '--clevergy-module-container-border-radius': 'none',
            '--clevergy-module-container-box-shadow': 'none',
            '--clevergy-module-container-padding': 'none',
            '--clevergy-module-container-margin': '0 0 32px 0',
          } as CSSProperties
        }
      >
        {children}
      </div>
    </>
  );
};
