import { ConnectedItem, IntegrationItem } from './types';

export const getConnectedList = (list: IntegrationItem[]): ConnectedItem[] =>
  list
    .filter((item) => item.isEnabled && item.isConnected)
    .map((item) => ({
      id: item.id,
      title: item.title,
      pathForDisconnect: item.pathForDisconnect,
    }));
