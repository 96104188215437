/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemEnergyCommunitiesInner
 */
export interface EnergyItemEnergyCommunitiesInner {
  /**
   *
   * @type {string}
   * @memberof EnergyItemEnergyCommunitiesInner
   */
  type: EnergyItemEnergyCommunitiesInnerTypeEnum;
  /**
   *
   * @type {number}
   * @memberof EnergyItemEnergyCommunitiesInner
   */
  production: number;
}

/**
 * @export
 */
export const EnergyItemEnergyCommunitiesInnerTypeEnum = {
  Solar: 'SOLAR',
  Wind: 'WIND',
  Hydro: 'HYDRO',
} as const;
export type EnergyItemEnergyCommunitiesInnerTypeEnum =
  (typeof EnergyItemEnergyCommunitiesInnerTypeEnum)[keyof typeof EnergyItemEnergyCommunitiesInnerTypeEnum];

/**
 * Check if a given object implements the EnergyItemEnergyCommunitiesInner interface.
 */
export function instanceOfEnergyItemEnergyCommunitiesInner(
  value: object,
): value is EnergyItemEnergyCommunitiesInner {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('production' in value) || value['production'] === undefined)
    return false;
  return true;
}

export function EnergyItemEnergyCommunitiesInnerFromJSON(
  json: any,
): EnergyItemEnergyCommunitiesInner {
  return EnergyItemEnergyCommunitiesInnerFromJSONTyped(json, false);
}

export function EnergyItemEnergyCommunitiesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemEnergyCommunitiesInner {
  if (json == null) {
    return json;
  }
  return {
    type: json['type'],
    production: json['production'],
  };
}

export function EnergyItemEnergyCommunitiesInnerToJSON(
  json: any,
): EnergyItemEnergyCommunitiesInner {
  return EnergyItemEnergyCommunitiesInnerToJSONTyped(json, false);
}

export function EnergyItemEnergyCommunitiesInnerToJSONTyped(
  value?: EnergyItemEnergyCommunitiesInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    type: value['type'],
    production: value['production'],
  };
}
