import styles from './LoadingIndicator.module.css';
import { FC } from 'react';

export type LoadingIndicatorProps = {
  variant?: 'wave' | 'spinner';
  colorScheme?: 'primary' | 'white';
  fullScreen?: 'none' | 'overlay' | 'transparent';
  centered?: boolean;
};

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  variant = 'wave',
  colorScheme = 'primary',
  fullScreen = 'none',
  centered = false,
}) => {
  return (
    <div
      className={styles.LoadingIndicator}
      data-color-scheme={colorScheme}
      data-full-screen={fullScreen}
      data-centered={centered}
      data-variant={variant}
    >
      <div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
