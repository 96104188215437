import { DayPicker as ReactDayPicker } from 'react-day-picker';
import classNames from 'react-day-picker/style.module.css';

/**
 * ReactDayPicker wrapper with custom styles.
 */
const DayPickerWithStyles: typeof ReactDayPicker = ({ ...props }) => (
  <ReactDayPicker
    {...props}
    classNames={{
      ...classNames,
      chevron: `text-black`,
      today: `!font-bold`,
      selected: `bg-clevergy-button text-white`,
      range_start: `bg-clevergy-button text-white rounded-l-full`,
      range_middle: `bg-clevergy-button text-white`,
      range_end: `bg-clevergy-button text-white rounded-r-full`,
    }}
  />
);

export * from 'react-day-picker';
export { DayPickerWithStyles as DayPicker };
