import { useShellyConnect } from '../hooks/useShellyConnect';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { Modal } from '@clevergy/ui/components/Modal';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const CheckDevicesModal: FC<Props> = ({
  isOpen,
  onCloseModal,
}: Props) => {
  const { t } = useTranslation();

  const { selectedHouse } = useSelectedHouse();
  const { openShellyConnect } = useShellyConnect();

  return (
    <Modal isOpen={isOpen} onClose={() => onCloseModal()}>
      <div className="mt-8">
        <div className="flex justify-center items-center gap-4 mb-8">
          <Icon name="Clevergy" size={50} />
          <FontIcon name="link" />
          <Icon name="Shelly" size={40} />
        </div>

        <div className="flex flex-col gap-4 text-center">
          <p className="text-xl font-semibold">
            {t('smartDevices.checkDevices.title')}
          </p>

          <div className="text-sm">
            <p>{t('smartDevices.checkDevices.subtitle')}</p>
            <div className="flex flex-col gap-2 my-4">
              <p>{t('smartDevices.checkDevices.instructions.part1')}</p>
              <p>{t('smartDevices.checkDevices.instructions.part2')}</p>
            </div>
            <p>
              <Trans
                t={t}
                values={{
                  address:
                    selectedHouse?.address ||
                    t('smartDevices.checkDevices.noAddress'),
                }}
              >
                smartDevices.checkDevices.instructions.part3
              </Trans>
            </p>
          </div>

          <div className="flex flex-col gap-2 mt-4">
            <Button variant="flat" fullWidth onClick={() => onCloseModal()}>
              {t('smartDevices.checkDevices.buttons.skip')}
            </Button>

            <Button
              fullWidth
              onClick={() => {
                openShellyConnect();
                onCloseModal();
              }}
            >
              {t('smartDevices.checkDevices.buttons.ok')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
