import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type HeadingSize = keyof typeof HeadingAsComponents;

export type HeadingProps = {
  as?: HeadingSize;
  colorScheme?: 'primary' | 'neutral';
  centered?: boolean;
  children: ReactNode;
};

export const Heading: FC<HeadingProps> = (props) => {
  const { as = 'h2', colorScheme = 'primary', centered, children } = props;
  const HeadingComponent = HeadingAsComponents[as];
  return (
    <HeadingComponent
      className={clsx('font-semibold mb-2', {
        'justify-center text-center': centered,
        'text-clevergy-text [&_strong]:text-clevergy-subtext [&_strong]:font-semibold':
          colorScheme === 'neutral',
        'text-clevergy-primary [&_strong]:text-clevergy-text [&_strong]:font-semibold':
          colorScheme === 'primary',
      })}
      data-color-scheme={colorScheme}
    >
      {children}
    </HeadingComponent>
  );
};

type HeadingAsComponentsProps = Pick<
  HeadingProps,
  'colorScheme' | 'children'
> & {
  className?: string;
};

const HeadingAsComponents = {
  h1: (props: HeadingAsComponentsProps) => (
    <h1 {...props} className={clsx(props.className, 'text-3xl')}>
      {props.children}
    </h1>
  ),
  h2: (props: HeadingAsComponentsProps) => (
    <h2 {...props} className={clsx(props.className, 'text-2xl')}>
      {props.children}
    </h2>
  ),
  h3: (props: HeadingAsComponentsProps) => (
    <h3 {...props} className={clsx(props.className, 'text-xl')}>
      {props.children}
    </h3>
  ),
  h4: (props: HeadingAsComponentsProps) => (
    <h4 {...props} className={clsx(props.className, 'text-lg')}>
      {props.children}
    </h4>
  ),
  h5: (props: HeadingAsComponentsProps) => (
    <h5 {...props} className={clsx(props.className, 'text-base')}>
      {props.children}
    </h5>
  ),
  h6: (props: HeadingAsComponentsProps) => (
    <h6 {...props} className={clsx(props.className, 'text-sm')}>
      {props.children}
    </h6>
  ),
};
