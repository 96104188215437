/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemSmartDevicesInner
 */
export interface EnergyItemSmartDevicesInner {
  /**
   *
   * @type {string}
   * @memberof EnergyItemSmartDevicesInner
   */
  subtype: string;
  /**
   *
   * @type {string}
   * @memberof EnergyItemSmartDevicesInner
   */
  vendor: EnergyItemSmartDevicesInnerVendorEnum;
  /**
   *
   * @type {number}
   * @memberof EnergyItemSmartDevicesInner
   */
  energy: number;
}

/**
 * @export
 */
export const EnergyItemSmartDevicesInnerVendorEnum = {
  Shelly: 'SHELLY',
} as const;
export type EnergyItemSmartDevicesInnerVendorEnum =
  (typeof EnergyItemSmartDevicesInnerVendorEnum)[keyof typeof EnergyItemSmartDevicesInnerVendorEnum];

/**
 * Check if a given object implements the EnergyItemSmartDevicesInner interface.
 */
export function instanceOfEnergyItemSmartDevicesInner(
  value: object,
): value is EnergyItemSmartDevicesInner {
  if (!('subtype' in value) || value['subtype'] === undefined) return false;
  if (!('vendor' in value) || value['vendor'] === undefined) return false;
  if (!('energy' in value) || value['energy'] === undefined) return false;
  return true;
}

export function EnergyItemSmartDevicesInnerFromJSON(
  json: any,
): EnergyItemSmartDevicesInner {
  return EnergyItemSmartDevicesInnerFromJSONTyped(json, false);
}

export function EnergyItemSmartDevicesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemSmartDevicesInner {
  if (json == null) {
    return json;
  }
  return {
    subtype: json['subtype'],
    vendor: json['vendor'],
    energy: json['energy'],
  };
}

export function EnergyItemSmartDevicesInnerToJSON(
  json: any,
): EnergyItemSmartDevicesInner {
  return EnergyItemSmartDevicesInnerToJSONTyped(json, false);
}

export function EnergyItemSmartDevicesInnerToJSONTyped(
  value?: EnergyItemSmartDevicesInner | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    subtype: value['subtype'],
    vendor: value['vendor'],
    energy: value['energy'],
  };
}
