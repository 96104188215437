import { DeleteAccountModal } from './components/DeleteAccountModal';
import { demoAccountSettings } from '@clevergy/shared/constants/demoAccountSettings';
import { useHouseSettingsBackupStore } from '@clevergy/shared/stores/houseSettingsBackupStore';
import { Button } from '@clevergy/ui/components/Button';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const AccountPage: FC = () => {
  const { t } = useTranslation();
  const { authedUser, signOut } = useAuthContext();
  const { userInfo } = useUserSetupContext();

  const resetAppConfiguration = useAppConfigurationStore(
    (state) => () => state.reset(),
  );

  const resetHouseSettingsBackup = useHouseSettingsBackupStore(
    (state) => state.reset,
  );

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const list = [
    {
      id: 'name',
      key: t('account.name.key'),
      text:
        userInfo?.name && userInfo?.surname
          ? `${userInfo?.name} ${userInfo?.surname}`
          : null,
    },
    {
      id: 'email',
      key: t('account.email.key'),
      text: userInfo?.email,
    },
    {
      id: 'dni',
      key: userInfo.isCompany ? t('account.cif.key') : t('account.dni.key'),
      text: userInfo?.dni,
    },
  ];
  const isDemoAccount = demoAccountSettings.emailRegex.test(
    authedUser?.email || '',
  );

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('account.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        <div className="flex flex-col">
          <ul className="list-none">
            {list.map((item) => {
              if (!item.text) {
                return null;
              }

              return (
                <li
                  key={item.id}
                  className="flex w-full flex-wrap justify-between gap-4 rounded-none p-2"
                >
                  <div className="text-left">{item.key}</div>
                  <div className="truncate text-right font-semibold text-neutral-700">
                    {item.text}
                  </div>
                </li>
              );
            })}
            <li className="w-full my-4">
              <Link to={'/logout'}>
                <Button fullWidth colorScheme="primary">
                  {t('account.session.button')}
                </Button>
              </Link>
            </li>
            {!isDemoAccount && (
              <li className="w-full my-4">
                <Button
                  fullWidth
                  variant={'flat'}
                  onClick={() => setShowDeleteAccountModal(true)}
                >
                  {t('account.deleteAccount.button')}
                </Button>
              </li>
            )}
          </ul>
        </div>
      </Wrapper>

      <DeleteAccountModal
        isOpen={showDeleteAccountModal}
        onCloseModal={() => setShowDeleteAccountModal(false)}
        onAccountDeleted={() => {
          setShowDeleteAccountModal(false);
          resetAppConfiguration();
          resetHouseSettingsBackup();
          signOut();
        }}
      />
    </Page>
  );
};
