import {
  MainMeasuringPointEnum,
  useSmartDeviceFormLists,
} from '../hooks/useSmartDeviceFormLists';
import { getMainMeasuringPointValue } from '../utils/getMainMeasuringPoint';
import { getSmartDeviceModelTranslationKey } from '../utils/getSmartDeviceModelTranslationKey';
import { DeleteDeviceModal } from './DeleteDeviceModal';
import {
  GetSmartDevicesResponse,
  UpdateSmartDeviceRequestSubtypeEnum,
} from '@clevergy/api-client';
import { Button } from '@clevergy/ui/components/Button';
import { InputSelect } from '@clevergy/ui/components/InputSelect';
import { InputText } from '@clevergy/ui/components/InputText';
import { Prose } from '@clevergy/ui/components/Prose';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export type DeviceFormValues = {
  houseId: string;
  deviceId: string;
  channel: number;
  model: string;
  mainMeasuringPoint?: string;
  subtype: string;
};

export type SmartDeviceFormProps = {
  houseId: string;
  device: GetSmartDevicesResponse;
  isMutationLoading: number;
  onUpdateSmartDevice: (payload: DeviceFormValues) => Promise<void>;
};

export const SmartDeviceForm: FC<SmartDeviceFormProps> = (props) => {
  const { t } = useTranslation();

  const [showDeleteDeviceModal, setShowDeleteDeviceModal] = useState(false);

  const { mainMeasuringPointList, smartDevicesSubtypesList } =
    useSmartDeviceFormLists();

  const { register, handleSubmit, setValue, clearErrors, formState, watch } =
    useForm<DeviceFormValues>({
      defaultValues: {
        houseId: props.houseId,
        deviceId: props.device.deviceId,
        channel: props.device.channel,
        model: t(
          getSmartDeviceModelTranslationKey(props.device.model),
        ) as string,
        mainMeasuringPoint: getMainMeasuringPointValue(props.device.subtype),
        subtype: props.device.subtype,
      },
    });

  const handleSubmitDeviceForm = async (data: DeviceFormValues) => {
    props.onUpdateSmartDevice(data);
  };

  const selectedMainMeasuringPoint = watch('mainMeasuringPoint');
  const selectedSubtype = watch('subtype');

  return (
    <>
      <Prose>
        <p className="mb-4 text-sm">{t('smartDevices.form.intro')}</p>
      </Prose>

      <form
        className="flex flex-col gap-4"
        onSubmit={handleSubmit(handleSubmitDeviceForm)}
      >
        <InputText
          id="model"
          type="text"
          readOnly
          label={t('smartDevices.form.fields.model.label') as string}
          placeholder={t('smartDevices.form.fields.model.placeholder')}
          error={formState.errors.model?.message as string}
          {...register('model')}
        />

        <InputSelect
          fullWidth
          id="mainMeasuringPoint"
          values={mainMeasuringPointList}
          selectedValueId={selectedMainMeasuringPoint}
          label={t('smartDevices.form.fields.mainMeasuringPoint.label')}
          error={formState.errors.mainMeasuringPoint?.message}
          placeholder={t(
            'smartDevices.form.fields.mainMeasuringPoint.placeholder',
          )}
          description={
            !selectedMainMeasuringPoint
              ? ''
              : (t(
                  `smartDevices.form.fields.mainMeasuringPoint.descriptions.${selectedMainMeasuringPoint}`,
                ) as string)
          }
          {...register('mainMeasuringPoint', {
            required: t(
              'smartDevices.form.fields.mainMeasuringPoint.required',
            ) as string,
          })}
          onChange={(e) => {
            setValue('mainMeasuringPoint', e.target.value);

            if (e.target.value !== MainMeasuringPointEnum.HouseholdAppliance) {
              setValue('subtype', e.target.value);
            }

            clearErrors();
          }}
        />

        {selectedMainMeasuringPoint ===
          MainMeasuringPointEnum.HouseholdAppliance && (
          <InputSelect
            fullWidth
            id="subtype"
            values={smartDevicesSubtypesList}
            selectedValueId={selectedSubtype}
            label={t('smartDevices.form.fields.subtype.label')}
            error={formState.errors.subtype?.message}
            placeholder={t('smartDevices.form.fields.subtype.placeholder')}
            {...register('subtype', {
              required: t(
                'smartDevices.form.fields.subtype.required',
              ) as string,
            })}
            onChange={(e) => {
              setValue(
                'subtype',
                e.target.value as UpdateSmartDeviceRequestSubtypeEnum,
              );
              clearErrors();
            }}
          />
        )}

        <div className="flex flex-col gap-2 mt-4">
          <Button
            fullWidth
            variant="flat"
            disabled={
              formState.isSubmitting || Boolean(props.isMutationLoading)
            }
            onClick={() => {
              setShowDeleteDeviceModal(true);
              clearErrors();
            }}
          >
            {t('smartDevices.form.actions.delete')}
          </Button>

          <Button
            type="submit"
            variant="filled"
            fullWidth
            disabled={
              formState.isSubmitting || Boolean(props.isMutationLoading)
            }
            busy={formState.isSubmitting || Boolean(props.isMutationLoading)}
            onClick={() => clearErrors()}
          >
            {t('smartDevices.form.actions.save')}
          </Button>
        </div>
      </form>

      <DeleteDeviceModal
        houseId={props.houseId}
        deviceName={props.device.name}
        isOpen={showDeleteDeviceModal}
        onCloseModal={() => setShowDeleteDeviceModal(false)}
      />
    </>
  );
};
