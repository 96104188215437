import { Alert } from '@clevergy/ui/components/Alert';
import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { isValidEmail } from '@clevergy/utils/validations/isValidEmail';
import { Page } from 'components/Page';
import { TenantPageIntroWithLogo } from 'components/TenantPageIntroWithLogo';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type ForgotPasswordFormValues = {
  email: string;
  customError?: string;
};

export const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { tenantGcpId } = useTenantSettings();

  const { register, handleSubmit, setError, clearErrors, formState } =
    useForm<ForgotPasswordFormValues>();

  const onSubmit = async (data: ForgotPasswordFormValues) => {
    try {
      await api.auth.sendChangePasswordLink({
        body: {
          email: data.email,
          tenantGcpId: tenantGcpId,
        },
      });
    } catch (error) {
      setError('customError', {
        type: 'serverSideError',
        message: t('forgot-password.error') as string,
      });

      throw new Error(`Error sending password reset email to ${data.email}`);
    }
  };

  return (
    <Page>
      <Wrapper>
        <div className="flex flex-col p-safe h-full">
          <div className="flex grow flex-col justify-center">
            <TenantPageIntroWithLogo
              title={t('forgot-password.title') as string}
              subtitle={
                t(
                  'Introduce tu email y te ayudaremos para reestablecer tu contraseña',
                ) as string
              }
            />
          </div>

          {!formState.isSubmitSuccessful && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="my-6 flex flex-col gap-4"
            >
              <InputText
                id="email"
                type="email"
                label={t('forgot-password.email.input') as string}
                placeholder={t('forgot-password.email.placeholder')}
                {...register('email', {
                  required: t('forgot-password.email.needed') as string,
                  validate: (value) =>
                    isValidEmail(value) ||
                    (t('forgot-password.email.invalid') as string),
                })}
                error={formState.errors.email?.message as string}
              />

              {formState.errors.customError && (
                <Alert
                  type="error"
                  text={t(formState.errors.customError.message as string)}
                />
              )}

              <Button
                type="submit"
                variant="filled"
                fullWidth
                busy={formState.isSubmitting}
                onClick={() => clearErrors()}
              >
                {t('forgot-password.restore')}
              </Button>
            </form>
          )}

          {formState.isSubmitSuccessful && (
            <Alert type="success" text={t('forgot-password.success')} />
          )}

          <p className="text-clevergy-subtext my-6 text-center font-bold">
            <Link to="/login" className="text-clevergy-primary">
              {t('forgot-password.login')}
            </Link>
          </p>
        </div>
      </Wrapper>
    </Page>
  );
};
