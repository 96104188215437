import { Button } from '@clevergy/ui/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type TicketRespondProps = {
  ticketId: string;
};

export const TicketRespond: FC<TicketRespondProps> = (props) => {
  const { ticketId } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <Button
        fullWidth
        variant="filled"
        colorScheme="primary"
        onClick={() => navigate(`/support/tickets/${ticketId}/submit-respond`)}
      >
        {t('support.yourTicket.actions.respond')}
      </Button>
    </div>
  );
};
