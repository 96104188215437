export const firebaseConfig = {
  apiKey: 'AIzaSyC-JNlhA1nzW4aTLUVhltFXm6JKjR02Pk0',
  authDomain: 'clevergy.firebaseapp.com',
  projectId: 'clevergy',
  storageBucket: 'clevergy.appspot.com',
  messagingSenderId: '440045268210',
  appId: '1:440045268210:web:a928749345886d22b5346b',
  measurementId: 'G-0PFWMPTT7R',
};
export const firebaseVapidKey =
  'BO_fx_61ej7YHOAdiDOk0uqkmV4MQClTxYY2spirrcIqm9mwqxc5FNvcUbdsnxXO4Di-MvA8ujIB9vP0j4VjkZE';
