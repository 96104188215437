/**
 * Split full name into first and last name from Firebase Auth format
 */
export const splitDisplayName = (
  displayName: string | null | undefined,
): {
  firstName: string;
  lastName: string;
} => {
  if (!displayName || displayName === '')
    return { firstName: '', lastName: '' };

  // Split full name into first and last name
  let [firstName, lastName] = displayName?.split(' ') || [];

  // Replace + with space (for example: "John+Doe" -> "John Doe")
  firstName = firstName?.replace(/\+/g, ' ');
  lastName = lastName?.replace(/\+/g, ' ');

  return {
    firstName,
    lastName,
  };
};
