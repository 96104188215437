const milesSeparation = '.';
const decimalSeparator = ',';

function _formatNumberWithDecimal(value: string) {
  const decimal = value.indexOf('.');
  const milesValue = value.slice(0, 1);
  const hundredsValue = value.slice(1, decimal);
  const decimalValue = value.slice(decimal + 1);

  return `${milesValue}${milesSeparation}${hundredsValue}${decimalSeparator}${decimalValue}`;
}

function _formatNumberWithoutDecimal(value: string) {
  const milesValue = value.slice(0, 1);
  const hundredsValue = value.slice(1);

  return `${milesValue}${milesSeparation}${hundredsValue}`;
}

/**
 * This util is used to format a number between 1k and 10k for the locale 'es-ES'
 * It is used to fix a bug in the Intl.NumberFormat implementation for this locale
 * Please, do not use it for other locales or numbers
 *
 * @param {number} value - The number to format
 * @returns {string} The formatted number
 */
export function formatNumberWithLocaleES(value: number): string {
  const originalValue = value.toString();
  const hasDecimal = originalValue.includes('.');

  if (hasDecimal) {
    return _formatNumberWithDecimal(originalValue);
  }

  return _formatNumberWithoutDecimal(originalValue);
}
