import { MutationKeys } from '@clevergy/shared/constants/mutationKeys';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useEffect, useRef } from 'react';

const EXCLUDED_DOMAINS = ['localhost', 'developer.clever.gy'];

function isExcludedFromTracking() {
  return (
    import.meta.env.DEV || EXCLUDED_DOMAINS.includes(window.location.hostname)
  );
}

export const LastLogginTracker: FC = () => {
  const { authedUser } = useAuthContext();
  const { api } = useApiContext();
  const isLastLoginTracked = useRef(false);

  const trackLastLoginMutation = useMutation({
    mutationKey: [MutationKeys.TRACK_USER_APP_LOGIN],
    mutationFn: (userId: string) => {
      return api.users.trackUserAppLogin({
        userId,
      });
    },
  });

  useEffect(() => {
    if (!authedUser?.uid || isLastLoginTracked.current) {
      return;
    }
    isLastLoginTracked.current = true;

    if (isExcludedFromTracking()) {
      console.log('Last login tracking (excluded)');
      return;
    }
    trackLastLoginMutation.mutate(authedUser.uid);
  }, [authedUser, trackLastLoginMutation]);

  return null;
};
