import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { TenantLogo } from 'components/TenantLogo';
import { useAppSettings } from 'context/AppSettingsContext';
import { FC } from 'react';

export const TenantLoadingIndicator: FC = () => {
  const { appSettings } = useAppSettings();
  const showLogo = Boolean(appSettings.defaultTenantGcpId) === false;
  return (
    <div className="fixed inset-0 z-50 flex h-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        {showLogo && <TenantLogo type="small" width={140} />}
        <div className="h-11 flex items-center justify-center">
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
};
