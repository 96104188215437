export const MutationKeys = {
  UPDATE_HOUSE_SETTINGS: 'UPDATE_HOUSE_SETTINGS',
  CREATE_TICKET: 'CREATE_TICKET',
  SEND_COMMENT: 'SEND_COMMENT',
  REGISTER_USER_DEVICE_TOKEN: 'REGISTER_USER_DEVICE_TOKEN',
  UPDATE_SMART_DEVICE: 'UPDATE_SMART_DEVICE',
  CONTROL_SMART_DEVICE: 'CONTROL_SMART_DEVICE',
  UPDATE_USER_PREFERENCES_NOTIFICATIONS:
    'UPDATE_USER_PREFERENCES_NOTIFICATIONS',
  TRACK_NOTIFICATION: 'TRACK_NOTIFICATION',
  TRACK_USER_APP_LOGIN: 'TRACK_USER_APP_LOGIN',
  STORE_USER_EXPECTATIONS: 'STORE_USER_EXPECTATIONS',
} as const;
