import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import { FC } from 'react';

export const AppAnalyticsListener: FC = () => {
  const { track } = useAnalyticsContext();

  useClevergyEvent(ClevergyEventTypes.TRACK_ANALYTICS_EVENT, (event) => {
    track(event.data);
  });

  return null;
};
