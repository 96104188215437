import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Button } from '@clevergy/ui/components/Button';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Modal } from '@clevergy/ui/components/Modal';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const LoginWithTokenPage: FC = () => {
  const navigate = useNavigate();
  const { hasResolvedTenantId } = useAuthContext();
  const [searchParams] = useSearchParams();

  const [hasFailed, setHasFailed] = useState(false);

  const token = searchParams.get('token');
  const forceChangePassword = searchParams.get('force-change-password');

  const signInWithTokenCaller = useCallback(async () => {
    if (!token) {
      throw new Error('Token not found');
    }
    try {
      console.log('signInWithToken: ', token);
      await FirebaseAuthentication.signInWithCustomToken({ token });

      if (forceChangePassword === 'true') {
        navigate('/change-password', { replace: true });
      } else {
        navigate('/', { replace: true });
      }
    } catch (error: unknown) {
      setHasFailed(true);
      console.error(error);
    }
  }, [token, forceChangePassword, navigate]);

  useEffect(() => {
    if (!token || !hasResolvedTenantId) {
      return;
    }
    signInWithTokenCaller();
  }, [hasResolvedTenantId, signInWithTokenCaller, token]);

  if (hasFailed) {
    return (
      <Modal isOpen={true} fitContent>
        <div className="flex flex-col gap-4">
          <p>Parece que el enlace es incorrecto o ha caducado</p>
          <Button
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            Entendido
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Page>
      <Wrapper>
        <LoadingIndicator fullScreen="transparent" />
      </Wrapper>
    </Page>
  );
};
