import { User } from '@capacitor-firebase/authentication';
import { IntegrationsStatus } from '@clevergy/api-client';

export enum IntegrationId {
  Alexa = 'alexa',
}

export type IntegrationItem = {
  id: IntegrationId;
  title: string;
  isEnabled: boolean;
  isConnected: boolean;
  pathForConnect: string;
  pathForDisconnect: string;
};

export type IntegrationData = {
  authedUser: User | null | undefined;
  integrationsStatus: IntegrationsStatus | undefined;
};

export type ConnectedItem = Pick<
  IntegrationItem,
  'id' | 'title' | 'pathForDisconnect'
>;

export type DisconnectedItem = Pick<
  IntegrationItem,
  'id' | 'title' | 'pathForConnect'
>;
