import { HTMLAttributeAnchorTarget } from 'react';

type DownloadFileFromUrl = {
  url: string;
  name?: string;
  target?: HTMLAttributeAnchorTarget;
};

/**
 * Download file from url by creating an anchor tag and clicking it programmatically
 *
 * @param url File url to download
 * @param name File name assigned to downloaded file
 * @param target Target attribute for anchor tag (optional)
 */
export function downloadFileFromUrl({
  url,
  target,
  name = 'invoice',
}: DownloadFileFromUrl) {
  if (!window || !document) {
    throw new Error('window is not defined');
  }

  const createElement = document.createElement.bind(document);
  const anchor = createElement('a');

  anchor.href = url;
  anchor.download = name;
  anchor.style.display = 'none';

  if (target) {
    anchor.target = target;
  }

  anchor.click();
}
