import { useShellyConnect } from '../hooks/useShellyConnect';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const NoConnectedState: FC = () => {
  const { t } = useTranslation();
  const { selectedHouse } = useSelectedHouse();
  const { openShellyConnect } = useShellyConnect();

  if (!selectedHouse) {
    return <LoadingIndicator fullScreen="overlay" />;
  }

  return (
    <div className="mt-8">
      <div className="flex justify-center items-center gap-4 mb-8">
        <Icon name="Clevergy" size={50} />
        <FontIcon name="link" />
        <Icon name="Shelly" size={40} />
      </div>

      <div className="flex flex-col gap-4 text-center">
        <p className="text-xl font-semibold">
          {t('smartDevices.noConnectedState.title')}
        </p>

        <div className="text-sm">
          <p>{t('smartDevices.noConnectedState.subtitle')}</p>
          <div className="flex flex-col gap-2 my-4">
            <p>{t('smartDevices.noConnectedState.instructions.part1')}</p>
            <p>{t('smartDevices.noConnectedState.instructions.part2')}</p>
          </div>
          <p>
            <Trans
              t={t}
              values={{
                address:
                  selectedHouse?.address ||
                  t('smartDevices.noConnectedState.noAddress'),
              }}
            >
              smartDevices.noConnectedState.instructions.part3
            </Trans>
          </p>
        </div>

        <div className="mt-4">
          <Button fullWidth onClick={() => openShellyConnect()}>
            {t('smartDevices.noConnectedState.buttons.connect')}
          </Button>
        </div>
      </div>
    </div>
  );
};
