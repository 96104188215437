import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import {
  ClevergyEvent,
  ClevergyEventTypes,
  useClevergyEvent,
} from '@clevergy/event-bus';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';
import { getHouseSettingsTrackingEventObject } from 'utils/getHouseSettingsTrackingEventObject';

export const OnBoardingHouseSettingsPage: FC = () => {
  const { token } = useAuthContext();
  const { t } = useTranslation();
  const { track } = useAnalyticsContext();

  const setHasAnsweredHouseSettingsWhileOnBoarding = useAppConfigurationStore(
    (state) => state.setHasAnsweredHouseSettingsWhileOnBoarding,
  );
  const { selectedHouse } = useSelectedHouse();
  const queryClient = useQueryClient();

  const trackEvent = (event: ClevergyEvent) => {
    const trackingEventObject = getHouseSettingsTrackingEventObject({
      event,
      page: 'onboarding',
    });

    track(trackingEventObject);
  };

  const onFinish = () => {
    setHasAnsweredHouseSettingsWhileOnBoarding(true);
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.GET_HOUSE_SETTINGS, selectedHouse?.houseId],
    });
  };

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_SAVED, (event) => {
    trackEvent(event);
    onFinish();
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(ClevergyEventTypes.HOUSE_SETTINGS_NOT_SAVED, (event) => {
    trackEvent(event);
    onFinish();
  });

  // Listen to the event that fires clevergy-house-settings
  useClevergyEvent(
    ClevergyEventTypes.HOUSE_SETTINGS_SAVED_IN_BACKUP,
    (event) => {
      trackEvent(event);
      onFinish();
    },
  );

  return (
    <Page>
      <PageHeader
        title={
          t('houseSettings.onboarding.configure-your-house-title') as string
        }
      />
      <Wrapper>
        {token && (
          <div className="flex flex-col gap-4 pb-safe">
            <p>{t('houseSettings.onboarding.configure-your-house-content')}</p>
            <clevergy-house-settings
              data-token={token}
            ></clevergy-house-settings>
          </div>
        )}
      </Wrapper>
    </Page>
  );
};
