import { Capacitor } from '@capacitor/core';
import { firebaseConfig } from 'constants/firebase';
import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';

let firebaseApp: FirebaseApp | null = null;

if (Capacitor.isNativePlatform()) {
  console.log('Running Firebase on native platform');
} else {
  console.log('Firebase initialized!');
  // Initialize Firebase
  firebaseApp = initializeApp(firebaseConfig);
  getAnalytics(firebaseApp);
}

export { firebaseApp };
