import IconCo2 from '../assets/icon-co2.svg?react';
import IconDiscount from '../assets/icon-discount.svg?react';
import IconSavings from '../assets/icon-savings.svg?react';
import { LeadCaptureSuccess } from './LeadCaptureSuccess';
import { CreateLeadLeadTypeEnum } from '@clevergy/api-client';
import { Button } from '@clevergy/ui/components/Button';
import { Modal } from '@clevergy/ui/components/Modal';
import { useMutation } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useState } from 'react';

export type AerothermalModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AerothermalModal: FC<AerothermalModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const [showLeadCaptureSuccess, setShowLeadCaptureSuccess] = useState(false);

  const createLeadMutation = useMutation({
    mutationFn: () => {
      const { uid, email, tenantId } = authedUser || {};
      return api.users.createLead({
        userId: uid || '',
        createLead: {
          leadType: CreateLeadLeadTypeEnum.Aerothermal,
          payload: {
            email: email || '',
            tenant: tenantId || '',
          },
        },
      });
    },
    onSuccess: () => {
      setShowLeadCaptureSuccess(true);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      {showLeadCaptureSuccess && (
        <LeadCaptureSuccess
          onClick={() => {
            onClose();
            setShowLeadCaptureSuccess(false);
          }}
        />
      )}
      {!showLeadCaptureSuccess && (
        <div className="flex flex-col gap-4 h-full">
          <div className="flex flex-col gap-4 grow">
            <p className="text-lg font-semibold text-clevergy-subtext">
              Útil para el invierno y el verano...
            </p>
            <p className="text-2xl font-semibold">
              La aerotermia aprovecha la energía del aire exterior para ahorrar
              en calefacción, refrigeración y agua caliente.
            </p>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0 -z-10">
                <IconSavings />
              </p>
              <p className="text-3xl font-semibold">
                hasta <span className="text-5xl">4</span>x
              </p>
              <p className="text-xl text-clevergy-subtext">
                más eficiente que una caldera.
              </p>
            </div>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0 -z-10">
                <IconDiscount />
              </p>
              <p className="text-3xl font-semibold">
                <span className="text-5xl">80</span>%
              </p>
              <p className="text-xl text-clevergy-subtext">
                de la energía que extrae del aire es gratis.
              </p>
            </div>

            <div className="relative pr-10">
              <p className="absolute right-0 top-0 -z-10">
                <IconCo2 />
              </p>
              <p className="text-3xl font-semibold">
                hasta <span className="text-5xl">70</span>%
              </p>
              <p className="text-xl text-clevergy-subtext">
                reducción de las emisiones.
              </p>
            </div>
          </div>
          <div>
            <Button
              variant="filled"
              fullWidth
              busy={createLeadMutation.isPending}
              disabled={createLeadMutation.isPending}
              onClick={() => createLeadMutation.mutate()}
            >
              Quiero que me contacten
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
