/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserPreferencesNotificationResponse
 */
export interface UserPreferencesNotificationResponse {
  /**
   *
   * @type {string}
   * @memberof UserPreferencesNotificationResponse
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof UserPreferencesNotificationResponse
   */
  type?: UserPreferencesNotificationResponseTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationResponse
   */
  push?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationResponse
   */
  email?: boolean;
}

/**
 * @export
 */
export const UserPreferencesNotificationResponseTypeEnum = {
  ConsumptionReport: 'CONSUMPTION_REPORT',
  Export: 'EXPORT',
} as const;
export type UserPreferencesNotificationResponseTypeEnum =
  (typeof UserPreferencesNotificationResponseTypeEnum)[keyof typeof UserPreferencesNotificationResponseTypeEnum];

/**
 * Check if a given object implements the UserPreferencesNotificationResponse interface.
 */
export function instanceOfUserPreferencesNotificationResponse(
  value: object,
): value is UserPreferencesNotificationResponse {
  return true;
}

export function UserPreferencesNotificationResponseFromJSON(
  json: any,
): UserPreferencesNotificationResponse {
  return UserPreferencesNotificationResponseFromJSONTyped(json, false);
}

export function UserPreferencesNotificationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserPreferencesNotificationResponse {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'] == null ? undefined : json['userId'],
    type: json['type'] == null ? undefined : json['type'],
    push: json['push'] == null ? undefined : json['push'],
    email: json['email'] == null ? undefined : json['email'],
  };
}

export function UserPreferencesNotificationResponseToJSON(
  json: any,
): UserPreferencesNotificationResponse {
  return UserPreferencesNotificationResponseToJSONTyped(json, false);
}

export function UserPreferencesNotificationResponseToJSONTyped(
  value?: UserPreferencesNotificationResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userId: value['userId'],
    type: value['type'],
    push: value['push'],
    email: value['email'],
  };
}
