/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemSolar
 */
export interface EnergyItemSolar {
  /**
   *
   * @type {number}
   * @memberof EnergyItemSolar
   */
  production?: number;
}

/**
 * Check if a given object implements the EnergyItemSolar interface.
 */
export function instanceOfEnergyItemSolar(
  value: object,
): value is EnergyItemSolar {
  return true;
}

export function EnergyItemSolarFromJSON(json: any): EnergyItemSolar {
  return EnergyItemSolarFromJSONTyped(json, false);
}

export function EnergyItemSolarFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemSolar {
  if (json == null) {
    return json;
  }
  return {
    production: json['production'] == null ? undefined : json['production'],
  };
}

export function EnergyItemSolarToJSON(json: any): EnergyItemSolar {
  return EnergyItemSolarToJSONTyped(json, false);
}

export function EnergyItemSolarToJSONTyped(
  value?: EnergyItemSolar | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    production: value['production'],
  };
}
