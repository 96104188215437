/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface DataConsent
 */
export interface DataConsent {
  /**
   *
   * @type {string}
   * @memberof DataConsent
   */
  userId?: string;
  /**
   *
   * @type {string}
   * @memberof DataConsent
   */
  ipAddress?: string;
}

/**
 * Check if a given object implements the DataConsent interface.
 */
export function instanceOfDataConsent(value: object): value is DataConsent {
  return true;
}

export function DataConsentFromJSON(json: any): DataConsent {
  return DataConsentFromJSONTyped(json, false);
}

export function DataConsentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataConsent {
  if (json == null) {
    return json;
  }
  return {
    userId: json['userId'] == null ? undefined : json['userId'],
    ipAddress: json['ipAddress'] == null ? undefined : json['ipAddress'],
  };
}

export function DataConsentToJSON(json: any): DataConsent {
  return DataConsentToJSONTyped(json, false);
}

export function DataConsentToJSONTyped(
  value?: DataConsent | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userId: value['userId'],
    ipAddress: value['ipAddress'],
  };
}
