import { DateRangeNavigator } from '../DateRangeNavigator/DateRangeNavigator';
import { DateRangeType } from '@clevergy/shared/types';
import { Button } from '@clevergy/ui/components/Button';
import { DateRange, DayPicker } from '@clevergy/ui/components/DayPicker';
import { Modal } from '@clevergy/ui/components/Modal';
import { Tabs } from '@clevergy/ui/components/Tabs';
import { format, isSameDay, Locale } from 'date-fns';
import { FC, useState } from 'react';

export type DateRangeSelectorChangePayload = {
  customStartDate?: Date;
  customEndDate?: Date;
  dateRangeType: DateRangeType;
  dateRangeCurrentDate?: Date;
};

export type DateRangeSelectorOnChangeHandler = (
  payload: DateRangeSelectorChangePayload,
) => void;

export type DateRangeSelectorProps = {
  /**
   * The type of date range to display
   */
  dateRangeType: DateRangeType;
  /**
   * The default new current date for the date range when tabs are clicked
   */
  dateRangeDefaultCurrentDate?: Date;
  /**
   * The current date for the date range (except for custom date range)
   */
  dateRangeCurrentDate?: Date;
  /**
   * The start date for the custom date range
   */
  customStartDate?: Date;
  /**
   * The end date for the custom date range
   */
  customEndDate?: Date;
  /**
   * The minimum date that can be selected.
   */
  minStartDate: Date;
  /**
   * The maximum date that can be selected.
   */
  maxEndDate: Date;
  /**
   * Texts for the component
   */
  texts: {
    day: string;
    month: string;
    year: string;
    custom: string;
    cancelButton: string;
    okButton: string;
  };
  /**
   * The locale to use
   */
  locale: Locale;
  /**
   * Callback to handle the change of the date range
   */
  onChange: DateRangeSelectorOnChangeHandler;
};

export const DateRangeSelector: FC<DateRangeSelectorProps> = (props) => {
  const {
    dateRangeType,
    dateRangeDefaultCurrentDate = new Date(),
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
    minStartDate,
    maxEndDate,
    texts,
    locale,
    onChange,
  } = props;

  const [selectedDayPickerDateRange, setSelectedDayPickerDateRange] = useState<
    DateRange | undefined
  >({
    from: customStartDate,
    to: customEndDate,
  });

  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const handleSetDateRangeType = (type: DateRangeType) => {
    if (type !== DateRangeType.Custom) {
      setSelectedDayPickerDateRange(undefined);
      onChange({
        customStartDate: undefined,
        customEndDate: undefined,
        dateRangeType: type,
        dateRangeCurrentDate: dateRangeDefaultCurrentDate,
      });
    } else {
      onChange({
        customStartDate: selectedDayPickerDateRange?.from,
        customEndDate: selectedDayPickerDateRange?.to,
        dateRangeType: DateRangeType.Custom,
        dateRangeCurrentDate: undefined,
      });
    }
  };

  const handleSetCustomRange = () => {
    onChange({
      customStartDate: selectedDayPickerDateRange?.from,
      customEndDate: selectedDayPickerDateRange?.to,
      dateRangeType: DateRangeType.Custom,
      dateRangeCurrentDate: undefined,
    });
    setShowDateRangePicker(false);
  };

  const handleDateRangePickerCancel = () => {
    if (dateRangeType !== DateRangeType.Custom) {
      setSelectedDayPickerDateRange(undefined);
    } else {
      setSelectedDayPickerDateRange({
        from: customStartDate,
        to: customEndDate,
      });
    }
    setShowDateRangePicker(false);
  };

  return (
    <>
      <Tabs>
        <Tabs.Item
          onClick={() => handleSetDateRangeType(DateRangeType.Day)}
          isCurrent={dateRangeType === DateRangeType.Day}
        >
          {texts.day}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => handleSetDateRangeType(DateRangeType.Month)}
          isCurrent={dateRangeType === DateRangeType.Month}
        >
          {texts.month}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => handleSetDateRangeType(DateRangeType.Year)}
          isCurrent={dateRangeType === DateRangeType.Year}
        >
          {texts.year}
        </Tabs.Item>
        <Tabs.Item
          onClick={() => {
            setShowDateRangePicker(true);
          }}
          isCurrent={dateRangeType === DateRangeType.Custom}
        >
          {texts.custom}
        </Tabs.Item>
      </Tabs>

      {dateRangeType === DateRangeType.Custom && (
        <div className="flex justify-center p-2">
          {selectedDayPickerDateRange?.from &&
            format(selectedDayPickerDateRange.from, 'd MMM yyyy', { locale })}
          {' - '}
          {selectedDayPickerDateRange?.to &&
            format(selectedDayPickerDateRange.to, 'd MMM yyyy', { locale })}
        </div>
      )}

      {(dateRangeType === DateRangeType.Day ||
        dateRangeType === DateRangeType.Month ||
        dateRangeType === DateRangeType.Year) && (
        <DateRangeNavigator
          dateRangeType={dateRangeType}
          dateRangeCurrentDate={
            dateRangeCurrentDate || dateRangeDefaultCurrentDate
          }
          minStartDate={minStartDate}
          maxEndDate={maxEndDate}
          locale={locale}
          onChange={(newDate: Date) => {
            onChange({
              customStartDate: undefined,
              customEndDate: undefined,
              dateRangeType,
              dateRangeCurrentDate: newDate,
            });
          }}
        />
      )}

      <Modal
        isOpen={showDateRangePicker}
        fitContent
        onClose={handleDateRangePickerCancel}
      >
        <DayPicker
          required
          locale={locale}
          mode="range"
          startMonth={minStartDate}
          endMonth={maxEndDate}
          disabled={[{ before: minStartDate, after: maxEndDate }]}
          onSelect={(range: DateRange | undefined) => {
            setSelectedDayPickerDateRange(range);
          }}
          selected={selectedDayPickerDateRange}
          footer={
            <div className="flex gap-2 w-full mt-2">
              <div className="w-1/2">
                <Button
                  colorScheme="neutral"
                  fullWidth
                  onClick={handleDateRangePickerCancel}
                >
                  {texts.cancelButton}
                </Button>
              </div>
              <div className="w-1/2">
                <Button
                  onClick={handleSetCustomRange}
                  fullWidth
                  disabled={
                    !selectedDayPickerDateRange?.from ||
                    !selectedDayPickerDateRange?.to ||
                    isSameDay(
                      selectedDayPickerDateRange.from,
                      selectedDayPickerDateRange.to,
                    )
                  }
                >
                  {texts.okButton}
                </Button>
              </div>
            </div>
          }
        />
      </Modal>
    </>
  );
};
