/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface HouseSettings
 */
export interface HouseSettings {
  /**
   *
   * @type {string}
   * @memberof HouseSettings
   */
  houseId?: string;
  /**
   *
   * @type {boolean}
   * @memberof HouseSettings
   */
  completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof HouseSettings
   */
  houseType?: HouseSettingsHouseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof HouseSettings
   */
  meters?: number;
  /**
   *
   * @type {number}
   * @memberof HouseSettings
   */
  housePeople?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof HouseSettings
   */
  devices?: Array<HouseSettingsDevicesEnum>;
  /**
   *
   * @type {string}
   * @memberof HouseSettings
   */
  rateType?: HouseSettingsRateTypeEnum;
}

/**
 * @export
 */
export const HouseSettingsHouseTypeEnum = {
  Flat: 'FLAT',
  Home: 'HOME',
} as const;
export type HouseSettingsHouseTypeEnum =
  (typeof HouseSettingsHouseTypeEnum)[keyof typeof HouseSettingsHouseTypeEnum];

/**
 * @export
 */
export const HouseSettingsDevicesEnum = {
  GasCooker: 'GAS_COOKER',
  GasBoiler: 'GAS_BOILER',
  GasWaterHeater: 'GAS_WATER_HEATER',
  Heater: 'HEATER',
  WaterHeater: 'WATER_HEATER',
  PlugHeater: 'PLUG_HEATER',
  Kitchen: 'KITCHEN',
  Oven: 'OVEN',
  Ac: 'AC',
  PlugAc: 'PLUG_AC',
  Fridge: 'FRIDGE',
  Freezer: 'FREEZER',
  WashingMachine: 'WASHING_MACHINE',
  Dryer: 'DRYER',
  Dishwasher: 'DISHWASHER',
  Tv: 'TV',
  Workstation: 'WORKSTATION',
  PhotovoltaicPanel: 'PHOTOVOLTAIC_PANEL',
  SolarThermalPanel: 'SOLAR_THERMAL_PANEL',
  Battery: 'BATTERY',
  Aerothermal: 'AEROTHERMAL',
  Geothermal: 'GEOTHERMAL',
  Pool: 'POOL',
  GarageDoor: 'GARAGE_DOOR',
  ChargingPoint: 'CHARGING_POINT',
} as const;
export type HouseSettingsDevicesEnum =
  (typeof HouseSettingsDevicesEnum)[keyof typeof HouseSettingsDevicesEnum];

/**
 * @export
 */
export const HouseSettingsRateTypeEnum = {
  Regulated: 'REGULATED',
  Pymes: 'PYMES',
} as const;
export type HouseSettingsRateTypeEnum =
  (typeof HouseSettingsRateTypeEnum)[keyof typeof HouseSettingsRateTypeEnum];

/**
 * Check if a given object implements the HouseSettings interface.
 */
export function instanceOfHouseSettings(value: object): value is HouseSettings {
  return true;
}

export function HouseSettingsFromJSON(json: any): HouseSettings {
  return HouseSettingsFromJSONTyped(json, false);
}

export function HouseSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseSettings {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    completed: json['completed'] == null ? undefined : json['completed'],
    houseType: json['houseType'] == null ? undefined : json['houseType'],
    meters: json['meters'] == null ? undefined : json['meters'],
    housePeople: json['housePeople'] == null ? undefined : json['housePeople'],
    devices: json['devices'] == null ? undefined : json['devices'],
    rateType: json['rateType'] == null ? undefined : json['rateType'],
  };
}

export function HouseSettingsToJSON(json: any): HouseSettings {
  return HouseSettingsToJSONTyped(json, false);
}

export function HouseSettingsToJSONTyped(
  value?: HouseSettings | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    completed: value['completed'],
    houseType: value['houseType'],
    meters: value['meters'],
    housePeople: value['housePeople'],
    devices: value['devices'],
    rateType: value['rateType'],
  };
}
