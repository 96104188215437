/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { DataProviders } from './DataProviders';
import {
  DataProvidersFromJSON,
  DataProvidersFromJSONTyped,
  DataProvidersToJSON,
  DataProvidersToJSONTyped,
} from './DataProviders';

/**
 *
 * @export
 * @interface UserSetUpHouse
 */
export interface UserSetUpHouse {
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  houseId: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  cups: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  postalCode: string;
  /**
   *
   * @type {DataProviders}
   * @memberof UserSetUpHouse
   */
  dataProviders?: DataProviders;
  /**
   *
   * @type {string}
   * @memberof UserSetUpHouse
   */
  timeZone: string;
}

/**
 * Check if a given object implements the UserSetUpHouse interface.
 */
export function instanceOfUserSetUpHouse(
  value: object,
): value is UserSetUpHouse {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (!('cups' in value) || value['cups'] === undefined) return false;
  if (!('address' in value) || value['address'] === undefined) return false;
  if (!('postalCode' in value) || value['postalCode'] === undefined)
    return false;
  if (!('timeZone' in value) || value['timeZone'] === undefined) return false;
  return true;
}

export function UserSetUpHouseFromJSON(json: any): UserSetUpHouse {
  return UserSetUpHouseFromJSONTyped(json, false);
}

export function UserSetUpHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSetUpHouse {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    cups: json['cups'],
    address: json['address'],
    postalCode: json['postalCode'],
    dataProviders:
      json['dataProviders'] == null
        ? undefined
        : DataProvidersFromJSON(json['dataProviders']),
    timeZone: json['timeZone'],
  };
}

export function UserSetUpHouseToJSON(json: any): UserSetUpHouse {
  return UserSetUpHouseToJSONTyped(json, false);
}

export function UserSetUpHouseToJSONTyped(
  value?: UserSetUpHouse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    cups: value['cups'],
    address: value['address'],
    postalCode: value['postalCode'],
    dataProviders: DataProvidersToJSON(value['dataProviders']),
    timeZone: value['timeZone'],
  };
}
