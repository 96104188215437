import { FontIcon } from '../FontIcon';
import { DateRangeType } from '@clevergy/shared/types';
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarMonths,
  differenceInDays,
  differenceInYears,
  format,
  Locale,
} from 'date-fns';
import { FC, useMemo } from 'react';

export type DateRangeNavigatorProps = {
  dateRangeType: Exclude<DateRangeType, DateRangeType.Custom>;
  dateRangeCurrentDate: Date;
  minStartDate: Date;
  maxEndDate: Date;
  locale: Locale;
  onChange: (newDate: Date) => void;
};

export const DateRangeNavigator: FC<DateRangeNavigatorProps> = (props) => {
  const {
    dateRangeType,
    dateRangeCurrentDate,
    minStartDate,
    maxEndDate,
    locale,
    onChange,
  } = props;

  const { hasPrevious, hasNext } = useMemo(() => {
    switch (dateRangeType) {
      case DateRangeType.Day:
        return {
          hasPrevious: differenceInDays(dateRangeCurrentDate, minStartDate) > 0,
          hasNext: differenceInDays(maxEndDate, dateRangeCurrentDate) > 0,
        };
      case DateRangeType.Month:
        return {
          hasPrevious:
            differenceInCalendarMonths(dateRangeCurrentDate, minStartDate) > 0,
          hasNext:
            differenceInCalendarMonths(maxEndDate, dateRangeCurrentDate) > 0,
        };
      case DateRangeType.Year:
        return {
          hasPrevious:
            differenceInYears(dateRangeCurrentDate, minStartDate) > 0,
          hasNext: differenceInYears(maxEndDate, dateRangeCurrentDate) > 0,
        };
      default:
        return {
          hasPrevious: false,
        };
    }
  }, [dateRangeType, dateRangeCurrentDate, minStartDate, maxEndDate]);

  const handleNavigation = (increment: number) => {
    if (dateRangeType === DateRangeType.Day) {
      onChange(addDays(dateRangeCurrentDate, increment));
    }
    if (dateRangeType === DateRangeType.Month) {
      onChange(addMonths(dateRangeCurrentDate, increment));
    }
    if (dateRangeType === DateRangeType.Year) {
      onChange(addYears(dateRangeCurrentDate, increment));
    }
  };

  const currentDateFormatted = useMemo(() => {
    if (dateRangeCurrentDate && dateRangeType === DateRangeType.Day) {
      return format(dateRangeCurrentDate, 'd MMM yyyy', { locale });
    }
    if (dateRangeCurrentDate && dateRangeType === DateRangeType.Month) {
      return format(dateRangeCurrentDate, 'MMMM yyyy', { locale });
    }
    if (dateRangeCurrentDate && dateRangeType === DateRangeType.Year) {
      return format(dateRangeCurrentDate, 'yyyy', { locale });
    }
    return '';
  }, [dateRangeCurrentDate, dateRangeType, locale]);

  return (
    <div className="flex justify-center items-center p-2">
      <div className="flex gap-4">
        <div className="w-6 h-6">
          {hasPrevious && (
            <button onClick={() => handleNavigation(-1)} type="button">
              <FontIcon name="chevron_left" inline />
            </button>
          )}
        </div>
        <div className="capitalize">{currentDateFormatted}</div>
        <div className="w-6 h-6">
          {hasNext && (
            <button onClick={() => handleNavigation(1)} type="button">
              <FontIcon name="chevron_right" inline />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
