import { InvoiceItem } from './InvoiceItem';
import { HouseInvoiceSummaryResponse } from '@clevergy/api-client';
import { List } from '@clevergy/ui/components/List';
import { FC } from 'react';

type InvoicesListProps = {
  invoices: HouseInvoiceSummaryResponse[];
};

export const InvoicesList: FC<InvoicesListProps> = ({ invoices }) => {
  return (
    <List>
      <>
        {invoices.map((invoice: HouseInvoiceSummaryResponse) => (
          <InvoiceItem key={invoice.id} invoice={invoice} />
        ))}
      </>
    </List>
  );
};
