export const assetsBaseUrl = 'https://assets.clever.gy';

// script and css urls for modules in production
export const modulesScriptProductionUrl = `${assetsBaseUrl}/clevergy-modules.js`;
export const modulesStylesProductionUrl = `${assetsBaseUrl}/clevergy-modules.css`;

// script and css urls for modules in development
export const modulesDevelopmentPort = 3001;
export const modulesScriptDevelopmentPath = `/src/clevergy-modules.tsx`;
export const modulesStylesDevelopmentPath = `/src/styles/clevergy-modules.css`;

export const googleSymbolsFontUrl =
  'https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:wght,FILL,GRAD@400,0..1,0';

export const defaultAppDomain = 'app.clever.gy';
export const defaultMultiTenantDomain = 'ecogrid.clever.gy';

export const tenantsAssetsBaseUrl = `${assetsBaseUrl}/tenants`;
