import { Button } from '@clevergy/ui/components/Button';
import { Modal } from '@clevergy/ui/components/Modal';
import { FC } from 'react';

export type PushNotificationShowUpModalProps = {
  isOpen: boolean;
  title: string;
  body: string;
  onConfirm: () => void;
};

export const PushNotificationShowUpModal: FC<
  PushNotificationShowUpModalProps
> = (props) => {
  const { isOpen, title, body, onConfirm } = props;
  return (
    <Modal isOpen={isOpen} fitContent>
      <div className="flex flex-col gap-4 min-w-72">
        <div>
          <p>
            <strong>{title}</strong>
          </p>
          <p>{body}</p>
        </div>
        <Button fullWidth onClick={onConfirm}>
          Entendido
        </Button>
      </div>
    </Modal>
  );
};
