/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { DataProviderInfo } from './DataProviderInfo';
import {
  DataProviderInfoFromJSON,
  DataProviderInfoFromJSONTyped,
  DataProviderInfoToJSON,
  DataProviderInfoToJSONTyped,
} from './DataProviderInfo';

/**
 *
 * @export
 * @interface DataProviders
 */
export interface DataProviders {
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  smartMeter?: DataProviderInfo;
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  solarInverter?: DataProviderInfo;
  /**
   *
   * @type {DataProviderInfo}
   * @memberof DataProviders
   */
  energyCommunity?: DataProviderInfo;
}

/**
 * Check if a given object implements the DataProviders interface.
 */
export function instanceOfDataProviders(value: object): value is DataProviders {
  return true;
}

export function DataProvidersFromJSON(json: any): DataProviders {
  return DataProvidersFromJSONTyped(json, false);
}

export function DataProvidersFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DataProviders {
  if (json == null) {
    return json;
  }
  return {
    smartMeter:
      json['smartMeter'] == null
        ? undefined
        : DataProviderInfoFromJSON(json['smartMeter']),
    solarInverter:
      json['solarInverter'] == null
        ? undefined
        : DataProviderInfoFromJSON(json['solarInverter']),
    energyCommunity:
      json['energyCommunity'] == null
        ? undefined
        : DataProviderInfoFromJSON(json['energyCommunity']),
  };
}

export function DataProvidersToJSON(json: any): DataProviders {
  return DataProvidersToJSONTyped(json, false);
}

export function DataProvidersToJSONTyped(
  value?: DataProviders | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    smartMeter: DataProviderInfoToJSON(value['smartMeter']),
    solarInverter: DataProviderInfoToJSON(value['solarInverter']),
    energyCommunity: DataProviderInfoToJSON(value['energyCommunity']),
  };
}
