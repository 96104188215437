import { i18n } from 'i18n';
import { FC, ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';

export type TranslationsProviderProps = {
  children: ReactNode;
};

export const TranslationsProvider: FC<TranslationsProviderProps> = ({
  children,
}) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
