import { useAuthContext } from './AuthContext';
import {
  AlexaApi,
  Configuration as ClevergyApiConfiguration,
  EnergyApi,
  InvoicesApi,
  OptimizeApi,
  SettingsApi,
  SolarApi,
  SmartDevicesApi,
  UsersApi,
} from '@clevergy/api-client';
import {
  AppsApi,
  AuthApi,
  Configuration as CleveryPortalRoutesApiConfiguration,
  ExpectationsApi,
  NotificationsApi,
  TenantsApi,
  TicketsApi,
} from '@clevergy/api-client-portal-routes';
import { FC, ReactNode, createContext, useContext } from 'react';

export const ApiContext = createContext<{
  api: {
    alexa: AlexaApi;
    apps: AppsApi;
    auth: AuthApi;
    energy: EnergyApi;
    expectations: ExpectationsApi;
    invoices: InvoicesApi;
    notifications: NotificationsApi;
    optimize: OptimizeApi;
    settings: SettingsApi;
    smartDevices: SmartDevicesApi;
    solar: SolarApi;
    tenants: TenantsApi;
    tickets: TicketsApi;
    users: UsersApi;
  };
} | null>(null);

export const ApiProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { refreshToken } = useAuthContext();

  const config = {
    accessToken: async () => {
      const token = await refreshToken();
      return `Bearer ${token}`;
    },
  };

  const clevergyApiConfig = new ClevergyApiConfiguration(config);
  const clevergyPortalRoutesApiConfig = new CleveryPortalRoutesApiConfiguration(
    {
      ...config,
      // basePath:
      //   import.meta.env.MODE === 'development'
      //     ? 'http://localhost:3003'
      //     : 'https://portal.clever.gy',
    },
  );

  const api = {
    alexa: new AlexaApi(clevergyApiConfig),
    apps: new AppsApi(clevergyPortalRoutesApiConfig),
    auth: new AuthApi(clevergyPortalRoutesApiConfig),
    energy: new EnergyApi(clevergyApiConfig),
    expectations: new ExpectationsApi(clevergyPortalRoutesApiConfig),
    invoices: new InvoicesApi(clevergyApiConfig),
    notifications: new NotificationsApi(clevergyPortalRoutesApiConfig),
    optimize: new OptimizeApi(clevergyApiConfig),
    settings: new SettingsApi(clevergyApiConfig),
    smartDevices: new SmartDevicesApi(clevergyApiConfig),
    solar: new SolarApi(clevergyApiConfig),
    tickets: new TicketsApi(clevergyPortalRoutesApiConfig),
    tenants: new TenantsApi(clevergyPortalRoutesApiConfig),
    users: new UsersApi(clevergyApiConfig),
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};

export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw Error('useApiContext must be used inside of a ApiContext');
  }
  return context;
};
