export const injectCssVariables = (
  cssVariables: Record<string, string>,
  element = document?.documentElement,
) => {
  if (!element) {
    throw new Error('element is not defined');
  }
  // Inject CSS variables
  Object.keys(cssVariables).forEach((key) => {
    const value = cssVariables[key as keyof typeof cssVariables];
    if (value) {
      element.style.setProperty(key, value);
    }
  });
};
