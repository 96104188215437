/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * New ticket information
 * @export
 * @interface NewTicket
 */
export interface NewTicket {
  /**
   * Ticket subject
   * @type {string}
   * @memberof NewTicket
   */
  subject: string;
  /**
   * Ticket description
   * @type {string}
   * @memberof NewTicket
   */
  description: string;
  /**
   * House identifier
   * @type {string}
   * @memberof NewTicket
   */
  houseId?: string;
}

/**
 * Check if a given object implements the NewTicket interface.
 */
export function instanceOfNewTicket(value: object): value is NewTicket {
  if (!('subject' in value) || value['subject'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  return true;
}

export function NewTicketFromJSON(json: any): NewTicket {
  return NewTicketFromJSONTyped(json, false);
}

export function NewTicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NewTicket {
  if (json == null) {
    return json;
  }
  return {
    subject: json['subject'],
    description: json['description'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
  };
}

export function NewTicketToJSON(json: any): NewTicket {
  return NewTicketToJSONTyped(json, false);
}

export function NewTicketToJSONTyped(
  value?: NewTicket | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    subject: value['subject'],
    description: value['description'],
    houseId: value['houseId'],
  };
}
