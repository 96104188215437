import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type ButtonToggleHorizontalProps = {
  icon?: ReactNode;
  title?: string;
  subtitle?: string;
  showRadio?: boolean;
  isChecked?: boolean;
  onClick?(): void;
};

export const ButtonToggleHorizontal: FC<ButtonToggleHorizontalProps> = ({
  icon,
  title,
  subtitle,
  isChecked = false,
  showRadio = false,
  onClick,
}) => {
  return (
    <button
      type="button"
      aria-pressed={isChecked}
      className="w-full flex py-2 px-5 gap-5 min-h-[72px] items-center rounded-2xl border-2 text-clevergy-subtext border-clevergy-unselected aria-pressed:text-clevergy-primary aria-pressed:border-clevergy-primary"
      onClick={onClick}
    >
      {icon && <span className="shrink-0">{icon}</span>}
      <div className="flex flex-col text-left grow">
        {title && <span className="text-base font-semibold">{title}</span>}
        {subtitle && <span className="text-sm">{subtitle}</span>}
      </div>
      {showRadio && (
        <div
          className={clsx('rounded-full border w-6 h-6 relative shrink-0', {
            'border-clevergy-primary': isChecked,
            'border-clevergy-unselected': !isChecked,
          })}
        >
          <span
            className={clsx(
              'rounded-full w-4 h-4 block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
              {
                'bg-clevergy-primary': isChecked,
                'bg-transparante': !isChecked,
              },
            )}
          ></span>
        </div>
      )}
    </button>
  );
};
