/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { PowerAnalysis } from './PowerAnalysis';
import {
  PowerAnalysisFromJSON,
  PowerAnalysisFromJSONTyped,
  PowerAnalysisToJSON,
  PowerAnalysisToJSONTyped,
} from './PowerAnalysis';

/**
 * Detail of invoice
 * @export
 * @interface InteractiveHouseInvoice
 */
export interface InteractiveHouseInvoice {
  /**
   *
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  houseId: string;
  /**
   * Start date of the invoicing period
   * @type {Date}
   * @memberof InteractiveHouseInvoice
   */
  startDate: Date;
  /**
   * End date of the invoicing period
   * @type {Date}
   * @memberof InteractiveHouseInvoice
   */
  endDate: Date;
  /**
   * Energy consumption 1st period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP1?: number;
  /**
   * Energy consumption 2nd period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP2?: number;
  /**
   * Energy consumption 3rd period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP3?: number;
  /**
   * Total consumption of invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumption?: number;
  /**
   * Total cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalCost: number;
  /**
   * Energy cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyCost?: number;
  /**
   * Power cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  powerCost?: number;
  /**
   * Other costs of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  otherCost?: number;
  /**
   * Discounts applied to the invoice. Negative values means discounts.
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  discountCost?: number;
  /**
   * Tax cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxCost?: number;
  /**
   * Cost of the service
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  serviceCost?: number;
  /**
   * URL to download the invoice file
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  urlFile?: string;
  /**
   * Tax applied to invoice in percentage
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  tax?: number;
  /**
   * Export energy savings
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  exportSaving?: number;
  /**
   * Discounts applied to the invoice cominf from the client's wallet. Negative values means discounts.
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  virtualWallet?: number;
  /**
   * Difference in energy consumption between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumptionDiff?: number;
  /**
   * Difference in percentage in energy consumption between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumptionDiffPercentage?: number;
  /**
   * Difference in monetary cost between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalCostDiff?: number;
  /**
   * Id of the invoice previous to this interactive invoice
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  previousInvoiceId?: string;
  /**
   * Id of the invoice next to this interactive invoice
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  nextInvoiceId?: string;
  /**
   * Rounded sum of taxCost and otherCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxesAndOthersCost?: number;
  /**
   * Rounded sum of taxCost, otherCost and serviceCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxesAndOthersAndServicesCost?: number;
  /**
   * Rounded sum of taxCost, otherCost and serviceCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  exportOverProductionPercentage?: number;
  /**
   * Rounded sum of taxCost, otherCost and serviceCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  consumptionPercentageOverPreviousConsumption?: number;
  /**
   *
   * @type {PowerAnalysis}
   * @memberof InteractiveHouseInvoice
   */
  powerAnalysis?: PowerAnalysis;
}

/**
 * Check if a given object implements the InteractiveHouseInvoice interface.
 */
export function instanceOfInteractiveHouseInvoice(
  value: object,
): value is InteractiveHouseInvoice {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (!('startDate' in value) || value['startDate'] === undefined) return false;
  if (!('endDate' in value) || value['endDate'] === undefined) return false;
  if (!('totalCost' in value) || value['totalCost'] === undefined) return false;
  return true;
}

export function InteractiveHouseInvoiceFromJSON(
  json: any,
): InteractiveHouseInvoice {
  return InteractiveHouseInvoiceFromJSONTyped(json, false);
}

export function InteractiveHouseInvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractiveHouseInvoice {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    startDate: new Date(json['startDate']),
    endDate: new Date(json['endDate']),
    energyConsumptionP1:
      json['energyConsumptionP1'] == null
        ? undefined
        : json['energyConsumptionP1'],
    energyConsumptionP2:
      json['energyConsumptionP2'] == null
        ? undefined
        : json['energyConsumptionP2'],
    energyConsumptionP3:
      json['energyConsumptionP3'] == null
        ? undefined
        : json['energyConsumptionP3'],
    totalEnergyConsumption:
      json['totalEnergyConsumption'] == null
        ? undefined
        : json['totalEnergyConsumption'],
    totalCost: json['totalCost'],
    energyCost: json['energyCost'] == null ? undefined : json['energyCost'],
    powerCost: json['powerCost'] == null ? undefined : json['powerCost'],
    otherCost: json['otherCost'] == null ? undefined : json['otherCost'],
    discountCost:
      json['discountCost'] == null ? undefined : json['discountCost'],
    taxCost: json['taxCost'] == null ? undefined : json['taxCost'],
    serviceCost: json['serviceCost'] == null ? undefined : json['serviceCost'],
    urlFile: json['urlFile'] == null ? undefined : json['urlFile'],
    tax: json['tax'] == null ? undefined : json['tax'],
    exportSaving:
      json['exportSaving'] == null ? undefined : json['exportSaving'],
    virtualWallet:
      json['virtualWallet'] == null ? undefined : json['virtualWallet'],
    totalEnergyConsumptionDiff:
      json['totalEnergyConsumptionDiff'] == null
        ? undefined
        : json['totalEnergyConsumptionDiff'],
    totalEnergyConsumptionDiffPercentage:
      json['totalEnergyConsumptionDiffPercentage'] == null
        ? undefined
        : json['totalEnergyConsumptionDiffPercentage'],
    totalCostDiff:
      json['totalCostDiff'] == null ? undefined : json['totalCostDiff'],
    previousInvoiceId:
      json['previousInvoiceId'] == null ? undefined : json['previousInvoiceId'],
    nextInvoiceId:
      json['nextInvoiceId'] == null ? undefined : json['nextInvoiceId'],
    taxesAndOthersCost:
      json['taxesAndOthersCost'] == null
        ? undefined
        : json['taxesAndOthersCost'],
    taxesAndOthersAndServicesCost:
      json['taxesAndOthersAndServicesCost'] == null
        ? undefined
        : json['taxesAndOthersAndServicesCost'],
    exportOverProductionPercentage:
      json['exportOverProductionPercentage'] == null
        ? undefined
        : json['exportOverProductionPercentage'],
    consumptionPercentageOverPreviousConsumption:
      json['consumptionPercentageOverPreviousConsumption'] == null
        ? undefined
        : json['consumptionPercentageOverPreviousConsumption'],
    powerAnalysis:
      json['powerAnalysis'] == null
        ? undefined
        : PowerAnalysisFromJSON(json['powerAnalysis']),
  };
}

export function InteractiveHouseInvoiceToJSON(
  json: any,
): InteractiveHouseInvoice {
  return InteractiveHouseInvoiceToJSONTyped(json, false);
}

export function InteractiveHouseInvoiceToJSONTyped(
  value?: InteractiveHouseInvoice | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    startDate: value['startDate'].toISOString().substring(0, 10),
    endDate: value['endDate'].toISOString().substring(0, 10),
    energyConsumptionP1: value['energyConsumptionP1'],
    energyConsumptionP2: value['energyConsumptionP2'],
    energyConsumptionP3: value['energyConsumptionP3'],
    totalEnergyConsumption: value['totalEnergyConsumption'],
    totalCost: value['totalCost'],
    energyCost: value['energyCost'],
    powerCost: value['powerCost'],
    otherCost: value['otherCost'],
    discountCost: value['discountCost'],
    taxCost: value['taxCost'],
    serviceCost: value['serviceCost'],
    urlFile: value['urlFile'],
    tax: value['tax'],
    exportSaving: value['exportSaving'],
    virtualWallet: value['virtualWallet'],
    totalEnergyConsumptionDiff: value['totalEnergyConsumptionDiff'],
    totalEnergyConsumptionDiffPercentage:
      value['totalEnergyConsumptionDiffPercentage'],
    totalCostDiff: value['totalCostDiff'],
    previousInvoiceId: value['previousInvoiceId'],
    nextInvoiceId: value['nextInvoiceId'],
    taxesAndOthersCost: value['taxesAndOthersCost'],
    taxesAndOthersAndServicesCost: value['taxesAndOthersAndServicesCost'],
    exportOverProductionPercentage: value['exportOverProductionPercentage'],
    consumptionPercentageOverPreviousConsumption:
      value['consumptionPercentageOverPreviousConsumption'],
    powerAnalysis: PowerAnalysisToJSON(value['powerAnalysis']),
  };
}
