/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserRegistrationCompleted
 */
export interface UserRegistrationCompleted {
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof UserRegistrationCompleted
   */
  cups?: string;
}

/**
 * Check if a given object implements the UserRegistrationCompleted interface.
 */
export function instanceOfUserRegistrationCompleted(
  value: object,
): value is UserRegistrationCompleted {
  return true;
}

export function UserRegistrationCompletedFromJSON(
  json: any,
): UserRegistrationCompleted {
  return UserRegistrationCompletedFromJSONTyped(json, false);
}

export function UserRegistrationCompletedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserRegistrationCompleted {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'] == null ? undefined : json['name'],
    surname: json['surname'] == null ? undefined : json['surname'],
    dni: json['dni'] == null ? undefined : json['dni'],
    cups: json['cups'] == null ? undefined : json['cups'],
  };
}

export function UserRegistrationCompletedToJSON(
  json: any,
): UserRegistrationCompleted {
  return UserRegistrationCompletedToJSONTyped(json, false);
}

export function UserRegistrationCompletedToJSONTyped(
  value?: UserRegistrationCompleted | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value['name'],
    surname: value['surname'],
    dni: value['dni'],
    cups: value['cups'],
  };
}
