import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import { Button } from '@clevergy/ui/components/Button';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type TicketFixedProps = {
  ticketId: string;
};

export const TicketFixed: FC<TicketFixedProps> = (props) => {
  const { ticketId } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { track } = useAnalyticsContext();

  return (
    <div className="flex flex-col gap-2">
      <p className="text-center font-semibold">
        {t('support.fixedTicket.title')}
      </p>
      <div className="flex gap-2 justify-between items-center">
        <Button
          fullWidth
          variant="outlined"
          colorScheme="neutral"
          onClick={() => {
            track({
              event_type: 'support_ticket_feedback_received',
              event_properties: {
                feedback: 'not_resolved',
              },
            });

            navigate(`/support/tickets/${ticketId}/submit-respond`);
          }}
        >
          {t('support.fixedTicket.actions.no')}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          colorScheme="neutral"
          onClick={() => {
            track({
              event_type: 'support_ticket_feedback_received',
              event_properties: {
                feedback: 'resolved',
              },
            });

            navigate('/support');
          }}
        >
          {t('support.fixedTicket.actions.yes')}
        </Button>
      </div>
    </div>
  );
};
