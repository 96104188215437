/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConsumptionCategory
 */
export interface ConsumptionCategory {
  /**
   * Name of the category
   * @type {string}
   * @memberof ConsumptionCategory
   */
  label?: string;
  /**
   * Consumption in kWh
   * @type {number}
   * @memberof ConsumptionCategory
   */
  kwh?: number;
}

/**
 * Check if a given object implements the ConsumptionCategory interface.
 */
export function instanceOfConsumptionCategory(
  value: object,
): value is ConsumptionCategory {
  return true;
}

export function ConsumptionCategoryFromJSON(json: any): ConsumptionCategory {
  return ConsumptionCategoryFromJSONTyped(json, false);
}

export function ConsumptionCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConsumptionCategory {
  if (json == null) {
    return json;
  }
  return {
    label: json['label'] == null ? undefined : json['label'],
    kwh: json['kwh'] == null ? undefined : json['kwh'],
  };
}

export function ConsumptionCategoryToJSON(json: any): ConsumptionCategory {
  return ConsumptionCategoryToJSONTyped(json, false);
}

export function ConsumptionCategoryToJSONTyped(
  value?: ConsumptionCategory | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    label: value['label'],
    kwh: value['kwh'],
  };
}
