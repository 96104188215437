import { DisconnectedItem, IntegrationItem } from './types';

export const getDisconnectedList = (
  list: IntegrationItem[],
): DisconnectedItem[] =>
  list
    .filter((item) => item.isEnabled && !item.isConnected)
    .map((item) => ({
      id: item.id,
      title: item.title,
      pathForConnect: item.pathForConnect,
    }));
