import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { useAnalyticsContext } from '@clevergy/amplitude/context/AnalyticsContext';
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';

export type AppUpdaterContextValue = {
  appVersion?: string;
  bundleVersion?: string;
};

export const AppUpdaterContext = createContext<AppUpdaterContextValue | null>(
  null,
);

export const AppUpdaterProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();

  const [appVersion, setAppVersion] = useState<string | undefined>(undefined);
  const [bundleVersion, setBundleVersion] = useState<string | undefined>(
    undefined,
  );
  const { identify } = useAnalyticsContext();

  useMount(() => {
    const updateVersionInfo = async () => {
      const currentUpdaterVersion = await CapacitorUpdater.current();
      // avoid web version because is always 0.0.0
      if (
        currentUpdaterVersion.native !== '0.0.0' &&
        currentUpdaterVersion.native !== ''
      ) {
        setAppVersion(currentUpdaterVersion.native);
      }
      if (currentUpdaterVersion.bundle.version !== '') {
        setBundleVersion(currentUpdaterVersion.bundle.version);
      }
    };

    CapacitorUpdater.addListener('majorAvailable', () => {
      if (window.confirm(t('appUpdater.message') as string)) {
        AppUpdate.openAppStore();
      }
    });

    CapacitorUpdater.addListener('appReloaded', () => {
      updateVersionInfo();
    });

    updateVersionInfo();
    CapacitorUpdater.notifyAppReady();
  });

  // Force migration to Ecogrid for deprecated apps
  useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform === 'web') {
        return;
      }
      App.getInfo().then((info) => {
        if (
          info.id === 'enersolex.clever.gy' ||
          info.id === 'trifon.clever.gy'
        ) {
          window.alert(
            "Esta aplicación ha dejado de estar disponible, por favor descarga la aplicación 'Ecogrid' y sigue las instrucciones que recibiste por correo electrónico.",
          );
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = 'https://link.clever.gy/app-ecogrid';
          link.click();
          App.exitApp();
        }
      });
    });
  }, [t]);

  useEffect(() => {
    identify({
      appVersion: appVersion,
      appBundleVersion: bundleVersion,
    });
  }, [appVersion, bundleVersion, identify]);

  const value: AppUpdaterContextValue = {
    appVersion,
    bundleVersion,
  };

  return (
    <AppUpdaterContext.Provider value={value}>
      {children}
    </AppUpdaterContext.Provider>
  );
};

export const useAppUpdaterContext = () => {
  const context = useContext(AppUpdaterContext);
  if (context === null) {
    throw Error(
      'useAppUpdaterContext must be used inside of a AppUpdaterContext',
    );
  }
  return context as AppUpdaterContextValue;
};
