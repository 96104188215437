import clsx from 'clsx';
import { FC, ReactNode } from 'react';

export type BadgeProps = {
  colorScheme?: 'primary' | 'secondary' | 'neutral' | 'danger';
  inverted?: boolean;
  children: ReactNode;
};

export const Badge: FC<BadgeProps> = ({
  colorScheme = 'primary',
  inverted = false,
  children,
}) => {
  return (
    <div
      className={clsx('w-fit rounded px-1 text-xs leading-4 ', {
        'bg-clevergy-primary text-white':
          colorScheme === 'primary' && !inverted,
        'bg-clevergy-secondary text-white':
          colorScheme === 'secondary' && !inverted,
        'bg-clevergy-subtext text-white':
          colorScheme === 'neutral' && !inverted,
        'bg-red-100 text-red-600': colorScheme === 'danger' && !inverted,

        'bg-white text-clevergy-primary': colorScheme === 'primary' && inverted,
        'bg-white text-clevergy-secondary':
          colorScheme === 'secondary' && inverted,
        'bg-white text-clevergy-subtext': colorScheme === 'neutral' && inverted,
        'bg-white text-red-600': colorScheme === 'danger' && inverted,
      })}
    >
      {children}
    </div>
  );
};
