import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { useNavbarMenu } from 'hooks/useNavbarMenu';
import { FC, ReactNode } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

export const MobileNavbar = () => {
  const navbarMenuItems = useNavbarMenu();

  return (
    <div className="block sm:hidden">
      <div className="flex bg-white font-sans flex-nowrap border-t border-[#eeeef1] pb-safe">
        {navbarMenuItems.map((item) => {
          if (!item.showAsTabInMobile || !item.to) {
            return null;
          }
          const childMatchingRoutes = item.childrenItems?.flatMap(
            (i) => i.matchingRoutes || [],
          );
          const matchingRoutes = [
            ...(item.matchingRoutes || []),
            ...(childMatchingRoutes || []),
          ];

          return (
            <NavbarItem
              key={item.label}
              url={item.to}
              icon={item.icon}
              label={item.label}
              badge={item.badge}
              machingRoutes={matchingRoutes}
            />
          );
        })}
      </div>
    </div>
  );
};

type NavbarItemProps = {
  url: string;
  icon: ReactNode;
  label: string;
  badge?: number;
  machingRoutes: string[];
};

const NavbarItem: FC<NavbarItemProps> = ({
  url,
  icon,
  label,
  badge = 0,
  machingRoutes,
}) => {
  const location = useLocation();
  const isActive = matchRoutes(
    machingRoutes.map((route) => ({ path: route })),
    location,
  );

  return (
    <AnimatePresence key={url} mode="popLayout">
      <Link
        to={url}
        className={clsx(
          'flex flex-col items-center justify-center gap-0.5 text-xs w-full px-2 py-3 cursor-pointer transition-colors duration-200',
          {
            'text-clevergy-primary': isActive,
            'text-clevergy-unselected': !isActive,
          },
        )}
        aria-current={isActive ? 'page' : 'false'}
      >
        <div className="relative">
          {icon}
          {badge > 0 && (
            <motion.span
              className="absolute top-0 -right-[14px] flex items-center justify-center bg-clevergy-secondary text-white font-semibold rounded-full text-[10px] w-4 h-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ scale: 0 }}
            >
              {badge}
            </motion.span>
          )}
        </div>
        {label}
      </Link>
    </AnimatePresence>
  );
};
