import { ClevergyEventTypes, useClevergyEvent } from '@clevergy/event-bus';
import { usePullToRefresh } from 'use-pull-to-refresh';

const MAXIMUM_PULL_LENGTH = 240;
const REFRESH_THRESHOLD = 180;

export const PullToRefresh = () => {
  const dispatchInvalidateQueries = useClevergyEvent(
    ClevergyEventTypes.INVALIDATE_ALL_QUERIES,
  );

  const onRefresh = async () => {
    setTimeout(async () => {
      dispatchInvalidateQueries();
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }, 100);
  };

  const { isRefreshing, pullPosition } = usePullToRefresh({
    onRefresh,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD,
  });

  return (
    <div
      style={{
        top: (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 2,
        opacity: isRefreshing ? 1 : pullPosition / MAXIMUM_PULL_LENGTH,
      }}
      className="fixed left-1/2 z-[200] -translate-x-1/2 pointer-events-none"
    >
      <div
        className={`${isRefreshing ? 'animate-spin' : ''}`}
        style={!isRefreshing ? { transform: `rotate(${pullPosition}deg)` } : {}}
      >
        <div className="h-8 w-8 shadow rounded-full bg-white">
          <svg width={32} height={32} viewBox="0 0 32 32">
            <circle
              cx="16"
              cy="16"
              r="8"
              fill="none"
              strokeWidth="2"
              className="stroke-clevergy-primary"
              strokeDasharray="35 1400"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
