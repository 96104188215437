import { formatNumber } from '@clevergy/utils/format/formatNumber';
import { FC } from 'react';

export type NumberWithDecimalsProps = {
  language: string;
  value: number;
  unit?: string;
  precision?: number;
  unitClassName?: string;
  valueClassName?: string;
  decimalClassName?: string;
};

export const NumberWithDecimals: FC<NumberWithDecimalsProps> = ({
  language,
  value,
  unit,
  precision = 1,
  unitClassName,
  valueClassName,
  decimalClassName,
}) => {
  const { integerValue, decimalValue, decimalSeparator } = formatNumber({
    value,
    precision,
    language,
  });

  return (
    <span>
      <span className={valueClassName}>{integerValue}</span>
      {decimalValue && (
        <span className={decimalClassName}>
          {decimalSeparator}
          {decimalValue}
        </span>
      )}
      {unit && <span className={unitClassName}> {unit}</span>}
    </span>
  );
};
