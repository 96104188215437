import { NewTicket } from '@clevergy/api-client-portal-routes';
import { MutationKeys } from '@clevergy/shared/constants/mutationKeys';
import { Button } from '@clevergy/ui/components/Button';
import { InputSelect } from '@clevergy/ui/components/InputSelect';
import { InputText } from '@clevergy/ui/components/InputText';
import { InputTextArea } from '@clevergy/ui/components/InputTextArea';
import { useIsMutating, useMutation } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

type SupportTicketCreateFormSchema = {
  houseId?: string;
  subject: string;
  description: string;
};

export const SupportTicketCreatePage: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { api } = useApiContext();
  const { houses } = useUserSetupContext();

  const [searchParams] = useSearchParams();
  const subject = searchParams.get('subject') || 'Otras consultas';

  const housesList = useMemo(() => {
    if (!houses) {
      return null;
    }
    return houses.map((house) => ({
      id: house.houseId,
      text: house.address,
    }));
  }, [houses]);

  const createIssueMutation = useMutation({
    mutationKey: [MutationKeys.CREATE_TICKET],
    mutationFn: (params: SupportTicketCreateFormSchema) => {
      const { houseId, subject, description } = params;
      const ticket: NewTicket = {
        subject,
        description,
      };

      if (houseId) {
        ticket.houseId = houseId;
      }

      return api.tickets.createTicket({ ticket });
    },
    onSuccess: () => {
      onFinish();
    },
  });

  const isMutationLoading = useIsMutating({
    mutationKey: [MutationKeys.CREATE_TICKET],
  });

  const { register, handleSubmit, formState, clearErrors, setValue, watch } =
    useForm<SupportTicketCreateFormSchema>({
      defaultValues: {
        subject,
      },
    });

  const selectedHouseId = watch('houseId');

  const handleSubmitCreateTicketForm = (data: SupportTicketCreateFormSchema) =>
    createIssueMutation.mutate(data);

  const onFinish = () => {
    navigate('/support/tickets/success');
  };

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath="/support"
        title={t('support.createTicket.title') as string}
      />

      <Wrapper>
        <form
          onSubmit={handleSubmit(handleSubmitCreateTicketForm)}
          className="flex w-full flex-col gap-4 justify-center"
        >
          {housesList && housesList.length > 0 && (
            <InputSelect
              id="houseId"
              values={housesList}
              selectedValueId={selectedHouseId}
              label={t('support.createTicket.form.house.label')}
              placeholder={t('support.createTicket.form.house.placeholder')}
              fullWidth
              {...register('houseId')}
              onChange={(e) => {
                setValue('houseId', e.target.value);
                clearErrors();
              }}
            />
          )}

          <InputText
            id="subject"
            type="text"
            label={t('support.createTicket.form.subject.label') as string}
            placeholder={t('support.createTicket.form.subject.placeholder')}
            error={formState.errors.subject?.message}
            {...register('subject', {
              required: t(
                'support.createTicket.form.subject.required',
              ) as string,
            })}
          />

          <InputTextArea
            id="description"
            label="Descripción"
            rows={6}
            autoComplete="off"
            placeholder={t('support.createTicket.form.description.placeholder')}
            error={formState.errors.description?.message}
            {...register('description', {
              required: t(
                'support.createTicket.form.description.required',
              ) as string,
            })}
          />

          <Button
            fullWidth
            type="submit"
            variant="filled"
            disabled={
              formState.isSubmitting ||
              !formState.isValid ||
              Boolean(isMutationLoading)
            }
            busy={formState.isSubmitting || Boolean(isMutationLoading)}
            onClick={() => clearErrors()}
          >
            {t('support.createTicket.form.actions.submit')}
          </Button>
        </form>
      </Wrapper>
    </Page>
  );
};
