/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface HouseInvoiceSummaryResponse
 */
export interface HouseInvoiceSummaryResponse {
  /**
   * Invoice id
   * @type {string}
   * @memberof HouseInvoiceSummaryResponse
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof HouseInvoiceSummaryResponse
   */
  startDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof HouseInvoiceSummaryResponse
   */
  endDate?: Date;
  /**
   *
   * @type {number}
   * @memberof HouseInvoiceSummaryResponse
   */
  totalCost?: number;
  /**
   *
   * @type {string}
   * @memberof HouseInvoiceSummaryResponse
   */
  urlFile?: string;
}

/**
 * Check if a given object implements the HouseInvoiceSummaryResponse interface.
 */
export function instanceOfHouseInvoiceSummaryResponse(
  value: object,
): value is HouseInvoiceSummaryResponse {
  if (!('id' in value) || value['id'] === undefined) return false;
  return true;
}

export function HouseInvoiceSummaryResponseFromJSON(
  json: any,
): HouseInvoiceSummaryResponse {
  return HouseInvoiceSummaryResponseFromJSONTyped(json, false);
}

export function HouseInvoiceSummaryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseInvoiceSummaryResponse {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    startDate:
      json['startDate'] == null ? undefined : new Date(json['startDate']),
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    totalCost: json['totalCost'] == null ? undefined : json['totalCost'],
    urlFile: json['urlFile'] == null ? undefined : json['urlFile'],
  };
}

export function HouseInvoiceSummaryResponseToJSON(
  json: any,
): HouseInvoiceSummaryResponse {
  return HouseInvoiceSummaryResponseToJSONTyped(json, false);
}

export function HouseInvoiceSummaryResponseToJSONTyped(
  value?: HouseInvoiceSummaryResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    startDate:
      value['startDate'] == null ? undefined : value['startDate'].toISOString(),
    endDate:
      value['endDate'] == null ? undefined : value['endDate'].toISOString(),
    totalCost: value['totalCost'],
    urlFile: value['urlFile'],
  };
}
