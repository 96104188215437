/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface NotificationTrack
 */
export interface NotificationTrack {
  /**
   * Communication identifier
   * @type {string}
   * @memberof NotificationTrack
   */
  communicationId: string;
  /**
   * Device token
   * @type {string}
   * @memberof NotificationTrack
   */
  deviceToken: string;
}

/**
 * Check if a given object implements the NotificationTrack interface.
 */
export function instanceOfNotificationTrack(
  value: object,
): value is NotificationTrack {
  if (!('communicationId' in value) || value['communicationId'] === undefined)
    return false;
  if (!('deviceToken' in value) || value['deviceToken'] === undefined)
    return false;
  return true;
}

export function NotificationTrackFromJSON(json: any): NotificationTrack {
  return NotificationTrackFromJSONTyped(json, false);
}

export function NotificationTrackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NotificationTrack {
  if (json == null) {
    return json;
  }
  return {
    communicationId: json['communicationId'],
    deviceToken: json['deviceToken'],
  };
}

export function NotificationTrackToJSON(json: any): NotificationTrack {
  return NotificationTrackToJSONTyped(json, false);
}

export function NotificationTrackToJSONTyped(
  value?: NotificationTrack | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    communicationId: value['communicationId'],
    deviceToken: value['deviceToken'],
  };
}
