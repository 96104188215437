import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { ModuleError } from '@clevergy/ui/components/ModuleError';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ModuleSuccess } from 'components/ModuleSuccess';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

const FIVE_SECONDS_IN_MS = 5000;

export const AlexaCallbackPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { selectedHouse } = useSelectedHouse();
  const queryClient = useQueryClient();
  const { authedUser } = useAuthContext();
  const { api } = useApiContext();

  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);

  const connectWithAlexaMutation = useMutation({
    mutationFn: ({ cups, authCode }: { cups: string; authCode: string }) =>
      api.alexa.connectWithAlexa({
        userId: authedUser?.uid || 'me',
        connectWithAlexaCredentials: {
          cups,
          authCode,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.GET_USER_SETUP, { userId: authedUser?.uid }],
      });
    },
  });

  const handleRegisterAlexa = useCallback(async () => {
    try {
      const code = searchParams.get('code');

      if (!code) {
        setError(true);
        return;
      }

      await connectWithAlexaMutation.mutateAsync({
        cups: selectedHouse?.cups || '',
        authCode: code,
      });

      if (connectWithAlexaMutation.isError) {
        setError(true);
      } else {
        setSuccess(true);
        setTimeout(() => {
          navigate('/integrations', { replace: true });
        }, FIVE_SECONDS_IN_MS);
      }
    } catch (err) {
      setError(true);
    }
  }, [connectWithAlexaMutation, navigate, searchParams, selectedHouse?.cups]);

  // Register Alexa on mount
  useMount(() => {
    handleRegisterAlexa();
  });

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath={'/integrations'}
        title={t('alexa.title') as string}
      />
      <Wrapper>
        {connectWithAlexaMutation.isPending && <LoadingIndicator centered />}
        {isError && (
          <ModuleError
            message={
              <>
                {t('alexa.error.message1')}
                {t('alexa.error.message2')}{' '}
                <Link
                  className="text-clevergy-primary"
                  to={`/support/tickets/create?subject=Problema con Alexa`}
                >
                  {t('alexa.error.message3')}
                </Link>
              </>
            }
            buttonText={t('alexa.error.buttonText') as string}
            onClick={handleRegisterAlexa}
          />
        )}
        {isSuccess && (
          <ModuleSuccess
            title={t('alexa.success.title') as string}
            message={t('alexa.success.message') as string}
          />
        )}
      </Wrapper>
    </Page>
  );
};
