/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserExpectations
 */
export interface UserExpectations {
  /**
   *
   * @type {Array<string>}
   * @memberof UserExpectations
   */
  expectations?: Array<string>;
}

/**
 * Check if a given object implements the UserExpectations interface.
 */
export function instanceOfUserExpectations(
  value: object,
): value is UserExpectations {
  return true;
}

export function UserExpectationsFromJSON(json: any): UserExpectations {
  return UserExpectationsFromJSONTyped(json, false);
}

export function UserExpectationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserExpectations {
  if (json == null) {
    return json;
  }
  return {
    expectations:
      json['expectations'] == null ? undefined : json['expectations'],
  };
}

export function UserExpectationsToJSON(json: any): UserExpectations {
  return UserExpectationsToJSONTyped(json, false);
}

export function UserExpectationsToJSONTyped(
  value?: UserExpectations | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    expectations: value['expectations'],
  };
}
