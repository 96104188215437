import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { ConditionalRedirect } from 'components/ConditionalRedirect';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { useUserSetupContext } from 'context/UserSetupContext';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { useUserCompletionsWhileOnboarding } from 'hooks/useUserCompletionsWhileOnboarding';
import { FC, useMemo } from 'react';
import { Outlet } from 'react-router';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const UserSetupRedirectRoute: FC = () => {
  const { userStatus, alexaAuthorization } = useUserSetupContext();

  const {
    isResolving: isUserCompletionsResolving,
    getNextPendingCompletion,
    hasPendingUserCompletions,
    getPendingUserCompletionsPaths,
  } = useUserCompletionsWhileOnboarding();

  const { hasAnsweredNotifications } = useAppConfigurationStore();

  const { shouldRedirectToSmartMeter } = useSmartMeterIntegration();

  const nextCompletionRedirect = useMemo(() => {
    const nextPendingCompletion = getNextPendingCompletion();
    if (!nextPendingCompletion) {
      return null;
    }

    return (
      <ConditionalRedirect
        targetPath={nextPendingCompletion.path}
        redirectCondition={hasPendingUserCompletions()}
      />
    );
  }, [getNextPendingCompletion, hasPendingUserCompletions]);

  if (!userStatus || isUserCompletionsResolving) {
    return <TenantLoadingIndicator />;
  }

  return (
    <>
      {/* If user has completed setup, but came from Alexa auth, redirect to Alexa settings */}
      <ConditionalRedirect
        targetPath="/alexa/settings"
        redirectCondition={
          userStatus === UserSetUpUserStatusEnum.Completed &&
          Boolean(alexaAuthorization)
        }
      />

      {/* If user needs to complete some step in onboarding process */}
      {nextCompletionRedirect}

      {/* If user is in initial state, redirect to register page and is not completing a pending completion */}
      <ConditionalRedirect
        targetPath="/smart-meter"
        excludedPaths={getPendingUserCompletionsPaths()}
        redirectCondition={shouldRedirectToSmartMeter}
      />

      {/* If user is in initial state, redirect to register page and is not completing a pending completion */}
      <ConditionalRedirect
        targetPath="/notifications"
        excludedPaths={[...getPendingUserCompletionsPaths(), '/smart-meter']}
        redirectCondition={!hasAnsweredNotifications}
      />

      {/* If current page is register but user is not in initial state or has skipped register */}
      <ConditionalRedirect
        targetPath="/"
        includedPaths={['/smart-meter', '/smart-meter/force']}
        redirectCondition={!shouldRedirectToSmartMeter}
      />

      {/* If current page is one of the completion steps and there are no pending completions */}
      <ConditionalRedirect
        targetPath="/"
        includedPaths={getPendingUserCompletionsPaths()}
        redirectCondition={!hasPendingUserCompletions()}
      />

      {/* If none of the above conditions are met, render the child routes */}
      <Outlet />
    </>
  );
};
