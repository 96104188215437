/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConsumerAppSettings
 */
export interface ConsumerAppSettings {
  /**
   * App name
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  appName: string;
  /**
   * App deep link base url
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  deepLinkBaseUrl?: string;
  /**
   * App iOS store url
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  iosStoreUrl?: string;
  /**
   * App Android store url
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  androidStoreUrl?: string;
  /**
   * App domain
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  domain?: string;
  /**
   * Google Tag Manager ID
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  googleTagManagerId?: string;
  /**
   * Tenant associated with this app
   * @type {string}
   * @memberof ConsumerAppSettings
   */
  defaultTenantGcpId?: string;
}

/**
 * Check if a given object implements the ConsumerAppSettings interface.
 */
export function instanceOfConsumerAppSettings(
  value: object,
): value is ConsumerAppSettings {
  if (!('appName' in value) || value['appName'] === undefined) return false;
  return true;
}

export function ConsumerAppSettingsFromJSON(json: any): ConsumerAppSettings {
  return ConsumerAppSettingsFromJSONTyped(json, false);
}

export function ConsumerAppSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConsumerAppSettings {
  if (json == null) {
    return json;
  }
  return {
    appName: json['appName'],
    deepLinkBaseUrl:
      json['deepLinkBaseUrl'] == null ? undefined : json['deepLinkBaseUrl'],
    iosStoreUrl: json['iosStoreUrl'] == null ? undefined : json['iosStoreUrl'],
    androidStoreUrl:
      json['androidStoreUrl'] == null ? undefined : json['androidStoreUrl'],
    domain: json['domain'] == null ? undefined : json['domain'],
    googleTagManagerId:
      json['googleTagManagerId'] == null
        ? undefined
        : json['googleTagManagerId'],
    defaultTenantGcpId:
      json['defaultTenantGcpId'] == null
        ? undefined
        : json['defaultTenantGcpId'],
  };
}

export function ConsumerAppSettingsToJSON(json: any): ConsumerAppSettings {
  return ConsumerAppSettingsToJSONTyped(json, false);
}

export function ConsumerAppSettingsToJSONTyped(
  value?: ConsumerAppSettings | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    appName: value['appName'],
    deepLinkBaseUrl: value['deepLinkBaseUrl'],
    iosStoreUrl: value['iosStoreUrl'],
    androidStoreUrl: value['androidStoreUrl'],
    domain: value['domain'],
    googleTagManagerId: value['googleTagManagerId'],
    defaultTenantGcpId: value['defaultTenantGcpId'],
  };
}
