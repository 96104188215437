import { Comment, CommentAuthorEnum } from '@clevergy/api-client-portal-routes';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { TenantLogo } from 'components/TenantLogo';
import { format } from 'date-fns';
import { dateFnsLocales } from 'i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type TicketCommentProp = {
  comment: Comment;
};

export const TicketComment: FC<TicketCommentProp> = (props) => {
  const { comment } = props;

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div
      key={comment.id}
      className="flex flex-col gap-2 p-4 bg-gray-100 rounded-lg"
    >
      <div className="flex gap-2 items-center text-sm">
        <p>
          {comment.author === CommentAuthorEnum.Support ? (
            <TenantLogo
              type="small"
              width={24}
              height={24}
              className="border rounded-full border-gray-200 bg-white"
            />
          ) : (
            <FontIcon name="account_circle" />
          )}
        </p>
        <span className='text-sm"'>
          {format(new Date(comment.createdAt), 'd MMMM H:mm', {
            locale: dateFnsLocales[language],
          })}
        </span>
      </div>

      <p>{comment.body}</p>
    </div>
  );
};
