import clsx from 'clsx';
import { FC } from 'react';

export type StepsMiniProps = {
  currentStep: number;
  numberOfSteps?: number;
  fullWidth?: boolean;
};

export const StepsMini: FC<StepsMiniProps> = ({
  numberOfSteps = 4,
  currentStep,
  fullWidth = false,
}) => {
  const steps = Array.from({ length: numberOfSteps }, (_, i) => i + 1);

  return (
    <div
      className={clsx('flex gap-3', {
        'w-full': fullWidth,
      })}
      data-full-width={fullWidth}
    >
      {steps.map((step) => (
        <div
          key={step}
          className={clsx(
            'bg-clevergy-unselected h-2 w-2 rounded-full transition-all duration-300 aria-selected:bg-clevergy-primary ',
            {
              'aria-selected:w-auto aria-selected:flex-grow': fullWidth,
            },
          )}
          aria-selected={currentStep === step}
        ></div>
      ))}
    </div>
  );
};
