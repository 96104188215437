import { Button } from '@clevergy/ui/components/Button';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC, ReactNode } from 'react';

export type ModuleErrorProps = {
  title?: string;
  message?: string | ReactNode;
  buttonText?: string;
  onClick?(): void;
};

export const ModuleError: FC<ModuleErrorProps> = (props) => {
  const { title, message, buttonText, onClick } = props;

  return (
    <div
      className="flex w-full flex-col items-center justify-center gap-2 text-center"
      data-testid="module-error"
    >
      <div>
        {title && (
          <Heading as="h4" centered colorScheme="neutral">
            {title}
          </Heading>
        )}
        {message && <p className="text-clevergy-subtext mb-2">{message}</p>}
      </div>
      {onClick && buttonText && (
        <Button fullWidth onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
