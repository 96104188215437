/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface InvoiceAnalysis
 */
export interface InvoiceAnalysis {
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  dni?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  cups?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  provider?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceAnalysis
   */
  distributor?: string;
  /**
   *
   * @type {Date}
   * @memberof InvoiceAnalysis
   */
  startDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof InvoiceAnalysis
   */
  endDate?: Date;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  totalCost?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof InvoiceAnalysis
   */
  contractedPower?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof InvoiceAnalysis
   */
  totalEnergyConsumption?: number;
}

/**
 * Check if a given object implements the InvoiceAnalysis interface.
 */
export function instanceOfInvoiceAnalysis(
  value: object,
): value is InvoiceAnalysis {
  return true;
}

export function InvoiceAnalysisFromJSON(json: any): InvoiceAnalysis {
  return InvoiceAnalysisFromJSONTyped(json, false);
}

export function InvoiceAnalysisFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InvoiceAnalysis {
  if (json == null) {
    return json;
  }
  return {
    dni: json['dni'] == null ? undefined : json['dni'],
    name: json['name'] == null ? undefined : json['name'],
    surname: json['surname'] == null ? undefined : json['surname'],
    address: json['address'] == null ? undefined : json['address'],
    cups: json['cups'] == null ? undefined : json['cups'],
    provider: json['provider'] == null ? undefined : json['provider'],
    distributor: json['distributor'] == null ? undefined : json['distributor'],
    startDate:
      json['startDate'] == null ? undefined : new Date(json['startDate']),
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    totalCost: json['totalCost'] == null ? undefined : json['totalCost'],
    contractedPower:
      json['contractedPower'] == null ? undefined : json['contractedPower'],
    totalEnergyConsumption:
      json['totalEnergyConsumption'] == null
        ? undefined
        : json['totalEnergyConsumption'],
  };
}

export function InvoiceAnalysisToJSON(json: any): InvoiceAnalysis {
  return InvoiceAnalysisToJSONTyped(json, false);
}

export function InvoiceAnalysisToJSONTyped(
  value?: InvoiceAnalysis | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    dni: value['dni'],
    name: value['name'],
    surname: value['surname'],
    address: value['address'],
    cups: value['cups'],
    provider: value['provider'],
    distributor: value['distributor'],
    startDate:
      value['startDate'] == null
        ? undefined
        : value['startDate'].toISOString().substring(0, 10),
    endDate:
      value['endDate'] == null
        ? undefined
        : value['endDate'].toISOString().substring(0, 10),
    totalCost: value['totalCost'],
    contractedPower: value['contractedPower'],
    totalEnergyConsumption: value['totalEnergyConsumption'],
  };
}
