import { formatNumberWithLocaleES } from './formatNumberWithLocaleES';

type FormatNumberPayload = {
  formattedValue: string;
  integerValue: string;
  decimalValue: string;
  decimalSeparator: string;
};

type FormatNumberProps = {
  value: number;
  language: string;
  precision?: number;
  options?: Intl.NumberFormatOptions;
};

function _splitNumber({
  value,
  decimalSeparator,
}: {
  value: string;
  decimalSeparator: string;
}) {
  const [integerValue, decimalValue] = value.split(decimalSeparator);
  return { integerValue, decimalValue, decimalSeparator };
}

/**
 * Split a number by its integer value, decimal value and decimal separator
 */
function _getStringNumberComponents({
  value,
  language,
}: {
  value: string;
  language: string;
}) {
  if (language === 'es-ES') {
    return _splitNumber({ value, decimalSeparator: ',' });
  }

  return _splitNumber({ value, decimalSeparator: '.' });
}

/**
 * Format a number with the given language
 */
export function formatNumber({
  value,
  language,
  precision = 1,
  options = {},
}: FormatNumberProps): FormatNumberPayload {
  if (precision < 0) {
    throw new Error('Precision must be greater than or equal to zero');
  }
  const formatter = new Intl.NumberFormat(language, {
    ...options,
    maximumFractionDigits: options.maximumFractionDigits || precision,
    minimumFractionDigits: options.minimumFractionDigits || precision,
  });

  const valueWithPrecision = Number(value.toFixed(precision));
  const shouldBeFormatted =
    value >= 1000 && value < 10000 && language.includes('-ES');

  const formattedValue = shouldBeFormatted
    ? formatNumberWithLocaleES(valueWithPrecision)
    : formatter.format(valueWithPrecision);

  const { integerValue, decimalValue, decimalSeparator } =
    _getStringNumberComponents({
      value: formattedValue,
      language,
    });

  return {
    formattedValue,
    integerValue,
    decimalValue,
    decimalSeparator,
  };
}
