import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const TicketNotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col justify-between pt-20">
      <div className="flex flex-col gap-6 p-5  text-center inset-0 z-50 m-auto items-center justify-center">
        <FontIcon name="help" size={64} />

        <Heading as="h3" centered colorScheme="neutral">
          {t('support.notFound.title')}
        </Heading>

        <p>{t('support.notFound.text')}</p>

        <Button
          fullWidth
          colorScheme="primary"
          onClick={() => navigate('/support/tickets')}
        >
          {t('support.notFound.actions.back')}
        </Button>
      </div>
    </div>
  );
};
