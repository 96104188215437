import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';

export const AppUrlListener: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    async function subscribeToEvents() {
      await App.addListener(
        'appUrlOpen',
        async (event: URLOpenListenerEvent) => {
          // Close the in app browser just in case
          await Browser.close();
          // Too tight coupling with the domain
          const slug = event.url.split('clever.gy').pop();
          if (slug) {
            navigate(slug);
          }
        },
      );
    }
    subscribeToEvents();
    return () => {
      App.removeAllListeners();
    };
  }, [navigate]);

  return null;
};
