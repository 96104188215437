import { Tabs } from '@clevergy/ui/components/Tabs';
import { FC } from 'react';

type InvoicesFilterProps = {
  availableYears: number[];
  currentYear: number | undefined;
  setCurrentYear: (year: number) => void;
};

export const InvoicesFilter: FC<InvoicesFilterProps> = (props) => {
  const { availableYears, currentYear, setCurrentYear } = props;

  return (
    <>
      <Tabs centered={availableYears.length <= 2}>
        {availableYears.map((year) => (
          <Tabs.Item
            key={year}
            isCurrent={year === currentYear}
            onClick={() => year !== currentYear && setCurrentYear(year)}
          >
            {year}
          </Tabs.Item>
        ))}
      </Tabs>
    </>
  );
};
