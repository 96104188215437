/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemGrid
 */
export interface EnergyItemGrid {
  /**
   *
   * @type {number}
   * @memberof EnergyItemGrid
   */
  _import?: number;
  /**
   *
   * @type {number}
   * @memberof EnergyItemGrid
   */
  _export?: number;
}

/**
 * Check if a given object implements the EnergyItemGrid interface.
 */
export function instanceOfEnergyItemGrid(
  value: object,
): value is EnergyItemGrid {
  return true;
}

export function EnergyItemGridFromJSON(json: any): EnergyItemGrid {
  return EnergyItemGridFromJSONTyped(json, false);
}

export function EnergyItemGridFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemGrid {
  if (json == null) {
    return json;
  }
  return {
    _import: json['import'] == null ? undefined : json['import'],
    _export: json['export'] == null ? undefined : json['export'],
  };
}

export function EnergyItemGridToJSON(json: any): EnergyItemGrid {
  return EnergyItemGridToJSONTyped(json, false);
}

export function EnergyItemGridToJSONTyped(
  value?: EnergyItemGrid | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    import: value['_import'],
    export: value['_export'],
  };
}
