import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TicketsNoData: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-full flex-col justify-between pt-20">
      <div className="flex flex-col gap-6 p-5  text-center inset-0 z-50 m-auto items-center justify-center">
        <FontIcon name="help" size={64} />
        <Heading as="h3" centered colorScheme="neutral">
          {t('support.noData.title')}
        </Heading>
        <p>{t('support.noData.text')}</p>
      </div>
    </div>
  );
};
