import { TicketResume } from './TicketResume';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const THIRTY_SECONDS = 30 * 1000;

export const LastTicket: FC = () => {
  const { t } = useTranslation();

  const { api } = useApiContext();

  const ticketsQuery = useQuery({
    queryKey: [QueryKeys.GET_TICKETS],
    queryFn: () => api.tickets.getTicketsList(),
    refetchInterval: THIRTY_SECONDS,
    staleTime: 0,
    refetchOnMount: true,
  });

  const displayLastTicket =
    !ticketsQuery.isPending &&
    ticketsQuery.data &&
    ticketsQuery.data.elements.length > 0;

  if (ticketsQuery.isPending) {
    return (
      <div className="flex justify-between mb-2">
        <LoadingIndicator centered colorScheme="primary" variant="spinner" />
      </div>
    );
  }

  if (!displayLastTicket) {
    return null;
  }

  const [firstTicket] = ticketsQuery.data.elements;

  return (
    <>
      <div className="flex justify-between mb-2">
        <p className="font-semibold">{t('support.lastTicket.title')}</p>

        <Link
          to="/support/tickets"
          className="text-clevergy-primary underline font-semibold"
        >
          {t('support.lastTicket.actions.all')}
        </Link>
      </div>

      <TicketResume ticket={firstTicket} />

      <p className="pt-4 pb-2 font-semibold">{t('support.startNewTicket')}</p>
    </>
  );
};
