/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarInfo
 */
export interface SolarInfo {
  /**
   * Total consumption of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  totalConsumptionKwh?: number;
  /**
   * Total production of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  selfProductionKwh?: number;
  /**
   * Total self consumption of the house on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionKwh?: number;
  /**
   * Percentage of self consumption over production
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionOverProductionPercentage?: number;
  /**
   * Percentage of self consumption over consumption
   * @type {number}
   * @memberof SolarInfo
   */
  selfConsumptionOverConsumptionPercentage?: number;
  /**
   * Consumed energy from the grid on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  importKwh?: number;
  /**
   * Percentage of energy consumed from the grid over consumption
   * @type {number}
   * @memberof SolarInfo
   */
  importOverConsumptionPercentage?: number;
  /**
   * Injected energy from the house into the grid on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  exportKwh?: number;
  /**
   * Percentage of energy injected into the grid over production
   * @type {number}
   * @memberof SolarInfo
   */
  exportOverProductionPercentage?: number;
  /**
   * Savings of the current period on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  currentPeriodSavingsKwh?: number;
  /**
   * Savings of the historical period on kWh
   * @type {number}
   * @memberof SolarInfo
   */
  historicalSavingsKwh?: number;
  /**
   * How much CO2 was reduced during the historical period?
   * @type {number}
   * @memberof SolarInfo
   */
  historicalC02Reduced?: number;
  /**
   * How much CO2 was reduced by car usage during the historical period?
   * @type {number}
   * @memberof SolarInfo
   */
  historicalCarUsageReduced?: number;
}

/**
 * Check if a given object implements the SolarInfo interface.
 */
export function instanceOfSolarInfo(value: object): value is SolarInfo {
  return true;
}

export function SolarInfoFromJSON(json: any): SolarInfo {
  return SolarInfoFromJSONTyped(json, false);
}

export function SolarInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarInfo {
  if (json == null) {
    return json;
  }
  return {
    totalConsumptionKwh:
      json['totalConsumptionKwh'] == null
        ? undefined
        : json['totalConsumptionKwh'],
    selfProductionKwh:
      json['selfProductionKwh'] == null ? undefined : json['selfProductionKwh'],
    selfConsumptionKwh:
      json['selfConsumptionKwh'] == null
        ? undefined
        : json['selfConsumptionKwh'],
    selfConsumptionOverProductionPercentage:
      json['selfConsumptionOverProductionPercentage'] == null
        ? undefined
        : json['selfConsumptionOverProductionPercentage'],
    selfConsumptionOverConsumptionPercentage:
      json['selfConsumptionOverConsumptionPercentage'] == null
        ? undefined
        : json['selfConsumptionOverConsumptionPercentage'],
    importKwh: json['importKwh'] == null ? undefined : json['importKwh'],
    importOverConsumptionPercentage:
      json['importOverConsumptionPercentage'] == null
        ? undefined
        : json['importOverConsumptionPercentage'],
    exportKwh: json['exportKwh'] == null ? undefined : json['exportKwh'],
    exportOverProductionPercentage:
      json['exportOverProductionPercentage'] == null
        ? undefined
        : json['exportOverProductionPercentage'],
    currentPeriodSavingsKwh:
      json['currentPeriodSavingsKwh'] == null
        ? undefined
        : json['currentPeriodSavingsKwh'],
    historicalSavingsKwh:
      json['historicalSavingsKwh'] == null
        ? undefined
        : json['historicalSavingsKwh'],
    historicalC02Reduced:
      json['historicalC02Reduced'] == null
        ? undefined
        : json['historicalC02Reduced'],
    historicalCarUsageReduced:
      json['historicalCarUsageReduced'] == null
        ? undefined
        : json['historicalCarUsageReduced'],
  };
}

export function SolarInfoToJSON(json: any): SolarInfo {
  return SolarInfoToJSONTyped(json, false);
}

export function SolarInfoToJSONTyped(
  value?: SolarInfo | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    totalConsumptionKwh: value['totalConsumptionKwh'],
    selfProductionKwh: value['selfProductionKwh'],
    selfConsumptionKwh: value['selfConsumptionKwh'],
    selfConsumptionOverProductionPercentage:
      value['selfConsumptionOverProductionPercentage'],
    selfConsumptionOverConsumptionPercentage:
      value['selfConsumptionOverConsumptionPercentage'],
    importKwh: value['importKwh'],
    importOverConsumptionPercentage: value['importOverConsumptionPercentage'],
    exportKwh: value['exportKwh'],
    exportOverProductionPercentage: value['exportOverProductionPercentage'],
    currentPeriodSavingsKwh: value['currentPeriodSavingsKwh'],
    historicalSavingsKwh: value['historicalSavingsKwh'],
    historicalC02Reduced: value['historicalC02Reduced'],
    historicalCarUsageReduced: value['historicalCarUsageReduced'],
  };
}
