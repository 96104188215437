/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
  CommentToJSONTyped,
} from './Comment';

/**
 * Ticket information
 * @export
 * @interface Ticket
 */
export interface Ticket {
  /**
   * Ticket identifier
   * @type {string}
   * @memberof Ticket
   */
  id: string;
  /**
   * Ticket creation date
   * @type {string}
   * @memberof Ticket
   */
  createdAt: string;
  /**
   * Ticket last update date
   * @type {string}
   * @memberof Ticket
   */
  updatedAt: string;
  /**
   * Ticket type
   * @type {string}
   * @memberof Ticket
   */
  type: string;
  /**
   * Ticket subject
   * @type {string}
   * @memberof Ticket
   */
  subject: string;
  /**
   * Ticket description
   * @type {string}
   * @memberof Ticket
   */
  description: string;
  /**
   * Ticket status
   * @type {string}
   * @memberof Ticket
   */
  status: TicketStatusEnum;
  /**
   * Tenant GCP identifier
   * @type {string}
   * @memberof Ticket
   */
  tenantGcpId: string;
  /**
   * User identifier
   * @type {string}
   * @memberof Ticket
   */
  userId: string;
  /**
   * House identifier
   * @type {string}
   * @memberof Ticket
   */
  houseId: string;
  /**
   * User email
   * @type {string}
   * @memberof Ticket
   */
  email: string;
  /**
   * User full name
   * @type {string}
   * @memberof Ticket
   */
  fullName: string;
  /**
   * List of comments
   * @type {Array<Comment>}
   * @memberof Ticket
   */
  comments?: Array<Comment>;
}

/**
 * @export
 */
export const TicketStatusEnum = {
  New: 'new',
  Pending: 'pending',
  Open: 'open',
  Solved: 'solved',
} as const;
export type TicketStatusEnum =
  (typeof TicketStatusEnum)[keyof typeof TicketStatusEnum];

/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value: object): value is Ticket {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('subject' in value) || value['subject'] === undefined) return false;
  if (!('description' in value) || value['description'] === undefined)
    return false;
  if (!('status' in value) || value['status'] === undefined) return false;
  if (!('tenantGcpId' in value) || value['tenantGcpId'] === undefined)
    return false;
  if (!('userId' in value) || value['userId'] === undefined) return false;
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('fullName' in value) || value['fullName'] === undefined) return false;
  return true;
}

export function TicketFromJSON(json: any): Ticket {
  return TicketFromJSONTyped(json, false);
}

export function TicketFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Ticket {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: json['createdAt'],
    updatedAt: json['updatedAt'],
    type: json['type'],
    subject: json['subject'],
    description: json['description'],
    status: json['status'],
    tenantGcpId: json['tenantGcpId'],
    userId: json['userId'],
    houseId: json['houseId'],
    email: json['email'],
    fullName: json['fullName'],
    comments:
      json['comments'] == null
        ? undefined
        : (json['comments'] as Array<any>).map(CommentFromJSON),
  };
}

export function TicketToJSON(json: any): Ticket {
  return TicketToJSONTyped(json, false);
}

export function TicketToJSONTyped(
  value?: Ticket | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value['id'],
    createdAt: value['createdAt'],
    updatedAt: value['updatedAt'],
    type: value['type'],
    subject: value['subject'],
    description: value['description'],
    status: value['status'],
    tenantGcpId: value['tenantGcpId'],
    userId: value['userId'],
    houseId: value['houseId'],
    email: value['email'],
    fullName: value['fullName'],
    comments:
      value['comments'] == null
        ? undefined
        : (value['comments'] as Array<any>).map(CommentToJSON),
  };
}
