import translation from './translation.json';

export type Translations = {
  translation: typeof translation;
};

const translations: Translations = {
  translation,
};

export default translations;
