/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Ticket } from './Ticket';
import {
  TicketFromJSON,
  TicketFromJSONTyped,
  TicketToJSON,
  TicketToJSONTyped,
} from './Ticket';

/**
 * List of tickets grouped by pages
 * @export
 * @interface TicketsList
 */
export interface TicketsList {
  /**
   * Number of the page requested
   * @type {number}
   * @memberof TicketsList
   */
  page: number;
  /**
   * Number of total pages
   * @type {number}
   * @memberof TicketsList
   */
  totalPages: number;
  /**
   * Number of total elements in the list
   * @type {number}
   * @memberof TicketsList
   */
  totalElements: number;
  /**
   * List of tickets
   * @type {Array<Ticket>}
   * @memberof TicketsList
   */
  elements: Array<Ticket>;
}

/**
 * Check if a given object implements the TicketsList interface.
 */
export function instanceOfTicketsList(value: object): value is TicketsList {
  if (!('page' in value) || value['page'] === undefined) return false;
  if (!('totalPages' in value) || value['totalPages'] === undefined)
    return false;
  if (!('totalElements' in value) || value['totalElements'] === undefined)
    return false;
  if (!('elements' in value) || value['elements'] === undefined) return false;
  return true;
}

export function TicketsListFromJSON(json: any): TicketsList {
  return TicketsListFromJSONTyped(json, false);
}

export function TicketsListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): TicketsList {
  if (json == null) {
    return json;
  }
  return {
    page: json['page'],
    totalPages: json['totalPages'],
    totalElements: json['totalElements'],
    elements: (json['elements'] as Array<any>).map(TicketFromJSON),
  };
}

export function TicketsListToJSON(json: any): TicketsList {
  return TicketsListToJSONTyped(json, false);
}

export function TicketsListToJSONTyped(
  value?: TicketsList | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    page: value['page'],
    totalPages: value['totalPages'],
    totalElements: value['totalElements'],
    elements: (value['elements'] as Array<any>).map(TicketToJSON),
  };
}
