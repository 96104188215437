/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemBattery
 */
export interface EnergyItemBattery {
  /**
   *
   * @type {number}
   * @memberof EnergyItemBattery
   */
  charge?: number;
  /**
   *
   * @type {number}
   * @memberof EnergyItemBattery
   */
  discharge?: number;
}

/**
 * Check if a given object implements the EnergyItemBattery interface.
 */
export function instanceOfEnergyItemBattery(
  value: object,
): value is EnergyItemBattery {
  return true;
}

export function EnergyItemBatteryFromJSON(json: any): EnergyItemBattery {
  return EnergyItemBatteryFromJSONTyped(json, false);
}

export function EnergyItemBatteryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemBattery {
  if (json == null) {
    return json;
  }
  return {
    charge: json['charge'] == null ? undefined : json['charge'],
    discharge: json['discharge'] == null ? undefined : json['discharge'],
  };
}

export function EnergyItemBatteryToJSON(json: any): EnergyItemBattery {
  return EnergyItemBatteryToJSONTyped(json, false);
}

export function EnergyItemBatteryToJSONTyped(
  value?: EnergyItemBattery | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    charge: value['charge'],
    discharge: value['discharge'],
  };
}
