import { MainMeasuringPointEnum } from '../hooks/useSmartDeviceFormLists';
import { UpdateSmartDeviceRequestSubtypeEnum } from '@clevergy/api-client';

/**
 * Returns the main measuring point based on the subtype.
 * @param subtype The subtype of the smart device.
 * @returns The main measuring point.
 */
export const getMainMeasuringPointValue = (
  subtype?: UpdateSmartDeviceRequestSubtypeEnum,
) => {
  if (!subtype || subtype === UpdateSmartDeviceRequestSubtypeEnum.Unknown) {
    return undefined;
  }

  if (
    subtype === UpdateSmartDeviceRequestSubtypeEnum.Aerothermal ||
    subtype === UpdateSmartDeviceRequestSubtypeEnum.Battery ||
    subtype === UpdateSmartDeviceRequestSubtypeEnum.Export ||
    subtype === UpdateSmartDeviceRequestSubtypeEnum.Grid ||
    subtype === UpdateSmartDeviceRequestSubtypeEnum.SelfProduction
  ) {
    return subtype;
  }

  return MainMeasuringPointEnum.HouseholdAppliance;
};
