import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { FC } from 'react';

export type CarouselControlsProps = {
  totalItems: number;
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
};

export const CarouselControls: FC<CarouselControlsProps> = (props) => {
  const { totalItems, currentIndex, setCurrentIndex } = props;
  return (
    <div className="flex justify-between items-center mt-4">
      <button
        type="button"
        disabled={currentIndex === 0}
        className={clsx('rounded-md border p-1', {
          'cursor-not-allowed border-gray-100 text-neutral-300':
            currentIndex === 0,
          'hover:bg-gray-100 border-gray-300 ': currentIndex !== 0,
        })}
        onClick={() => {
          setCurrentIndex(currentIndex - 1);
        }}
      >
        <FontIcon name="chevron_left" />
      </button>

      <div className="flex gap-2">
        {Array(totalItems)
          .fill(null)
          .map((_, index) => (
            <button
              key={index}
              type="button"
              onClick={() => {
                setCurrentIndex(index);
              }}
              className={`w-2 h-2 rounded-full ${
                index === currentIndex ? 'bg-clevergy-primary' : 'bg-gray-300'
              }`}
            />
          ))}
      </div>

      <button
        type="button"
        disabled={currentIndex === totalItems - 1}
        className={clsx('rounded-md border p-1', {
          'cursor-not-allowed border-gray-100 text-neutral-300':
            currentIndex === totalItems - 1,
          'hover:bg-gray-100 border-gray-300 ': currentIndex !== totalItems - 1,
        })}
        onClick={() => {
          setCurrentIndex(currentIndex + 1);
        }}
      >
        <FontIcon name="chevron_right" />
      </button>
    </div>
  );
};
