import { DateRangeType } from '@clevergy/shared/types';
import { format } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

const initialState = {
  dateRangeType: DateRangeType.Month,
  dateRangeCurrentDate: undefined,
  customStartDate: undefined,
  customEndDate: undefined,
};

/**
 * Manage the filters for the Analysis page from query params.
 */
export const useAnalysisPageFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateRangeTypeFromParams = searchParams.get('dateRangeType');
  const dateRangeCurrentDateFromParams = searchParams.get(
    'dateRangeCurrentDate',
  );
  const customStartDateFromParams = searchParams.get('customStartDate');
  const customEndDateFromParams = searchParams.get('customEndDate');

  const dateRangeType =
    (dateRangeTypeFromParams as DateRangeType) || initialState.dateRangeType;

  const dateRangeCurrentDate = dateRangeCurrentDateFromParams
    ? new Date(dateRangeCurrentDateFromParams)
    : initialState.dateRangeCurrentDate;

  const customStartDate = customStartDateFromParams
    ? new Date(customStartDateFromParams)
    : initialState.customStartDate;

  const customEndDate = customEndDateFromParams
    ? new Date(customEndDateFromParams)
    : initialState.customEndDate;

  const setFilters = (filters: {
    dateRangeType: DateRangeType;
    dateRangeCurrentDate?: Date;
    customStartDate?: Date;
    customEndDate?: Date;
  }) => {
    const newUrlSearchParams = new URLSearchParams();
    newUrlSearchParams.set('dateRangeType', filters.dateRangeType);
    if (filters.dateRangeCurrentDate) {
      newUrlSearchParams.set(
        'dateRangeCurrentDate',
        format(filters.dateRangeCurrentDate, 'yyyy-MM-dd'),
      );
    }
    if (filters.customStartDate) {
      newUrlSearchParams.set(
        'customStartDate',
        format(filters.customStartDate, 'yyyy-MM-dd'),
      );
    }
    if (filters.customEndDate) {
      newUrlSearchParams.set(
        'customEndDate',
        format(filters.customEndDate, 'yyyy-MM-dd'),
      );
    }
    setSearchParams(newUrlSearchParams, { replace: true });
  };

  const resetFilter = () => {
    const newUrlSearchParams = new URLSearchParams();
    setSearchParams(newUrlSearchParams, { replace: true });
  };

  return {
    dateRangeType,
    dateRangeCurrentDate,
    customStartDate,
    customEndDate,
    setFilters,
    resetFilter,
  };
};
