/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { IntegrationsStatus } from './IntegrationsStatus';
import {
  IntegrationsStatusFromJSON,
  IntegrationsStatusFromJSONTyped,
  IntegrationsStatusToJSON,
  IntegrationsStatusToJSONTyped,
} from './IntegrationsStatus';
import type { UserSetUpHouse } from './UserSetUpHouse';
import {
  UserSetUpHouseFromJSON,
  UserSetUpHouseFromJSONTyped,
  UserSetUpHouseToJSON,
  UserSetUpHouseToJSONTyped,
} from './UserSetUpHouse';

/**
 *
 * @export
 * @interface UserSetUp
 */
export interface UserSetUp {
  /**
   *
   * @type {string}
   * @memberof UserSetUp
   */
  userStatus?: UserSetUpUserStatusEnum;
  /**
   *
   * @type {Array<UserSetUpHouse>}
   * @memberof UserSetUp
   */
  houses?: Array<UserSetUpHouse>;
  /**
   *
   * @type {IntegrationsStatus}
   * @memberof UserSetUp
   */
  integrationsStatus?: IntegrationsStatus;
  /**
   * Identity document is validated
   * @type {boolean}
   * @memberof UserSetUp
   */
  hasIdentityDocumentValidated?: boolean;
  /**
   * User accept conditions date
   * @type {Date}
   * @memberof UserSetUp
   */
  acceptedConditionsDate?: Date;
}

/**
 * @export
 */
export const UserSetUpUserStatusEnum = {
  Initial: 'INITIAL',
  PendingRenewal: 'PENDING_RENEWAL',
  AcceptedConditions: 'ACCEPTED_CONDITIONS',
  Completed: 'COMPLETED',
} as const;
export type UserSetUpUserStatusEnum =
  (typeof UserSetUpUserStatusEnum)[keyof typeof UserSetUpUserStatusEnum];

/**
 * Check if a given object implements the UserSetUp interface.
 */
export function instanceOfUserSetUp(value: object): value is UserSetUp {
  return true;
}

export function UserSetUpFromJSON(json: any): UserSetUp {
  return UserSetUpFromJSONTyped(json, false);
}

export function UserSetUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserSetUp {
  if (json == null) {
    return json;
  }
  return {
    userStatus: json['userStatus'] == null ? undefined : json['userStatus'],
    houses:
      json['houses'] == null
        ? undefined
        : (json['houses'] as Array<any>).map(UserSetUpHouseFromJSON),
    integrationsStatus:
      json['integrationsStatus'] == null
        ? undefined
        : IntegrationsStatusFromJSON(json['integrationsStatus']),
    hasIdentityDocumentValidated:
      json['hasIdentityDocumentValidated'] == null
        ? undefined
        : json['hasIdentityDocumentValidated'],
    acceptedConditionsDate:
      json['acceptedConditionsDate'] == null
        ? undefined
        : new Date(json['acceptedConditionsDate']),
  };
}

export function UserSetUpToJSON(json: any): UserSetUp {
  return UserSetUpToJSONTyped(json, false);
}

export function UserSetUpToJSONTyped(
  value?: UserSetUp | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    userStatus: value['userStatus'],
    houses:
      value['houses'] == null
        ? undefined
        : (value['houses'] as Array<any>).map(UserSetUpHouseToJSON),
    integrationsStatus: IntegrationsStatusToJSON(value['integrationsStatus']),
    hasIdentityDocumentValidated: value['hasIdentityDocumentValidated'],
    acceptedConditionsDate:
      value['acceptedConditionsDate'] == null
        ? undefined
        : value['acceptedConditionsDate'].toISOString(),
  };
}
