/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UserPreferencesNotificationRequest
 */
export interface UserPreferencesNotificationRequest {
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationRequest
   */
  push?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserPreferencesNotificationRequest
   */
  email?: boolean;
}

/**
 * Check if a given object implements the UserPreferencesNotificationRequest interface.
 */
export function instanceOfUserPreferencesNotificationRequest(
  value: object,
): value is UserPreferencesNotificationRequest {
  return true;
}

export function UserPreferencesNotificationRequestFromJSON(
  json: any,
): UserPreferencesNotificationRequest {
  return UserPreferencesNotificationRequestFromJSONTyped(json, false);
}

export function UserPreferencesNotificationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserPreferencesNotificationRequest {
  if (json == null) {
    return json;
  }
  return {
    push: json['push'] == null ? undefined : json['push'],
    email: json['email'] == null ? undefined : json['email'],
  };
}

export function UserPreferencesNotificationRequestToJSON(
  json: any,
): UserPreferencesNotificationRequest {
  return UserPreferencesNotificationRequestToJSONTyped(json, false);
}

export function UserPreferencesNotificationRequestToJSONTyped(
  value?: UserPreferencesNotificationRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    push: value['push'],
    email: value['email'],
  };
}
