import { FC, ReactNode } from 'react';

export type ModuleCardContentProps = {
  children: ReactNode;
};

export const ModuleCardContent: FC<ModuleCardContentProps> = ({ children }) => {
  return (
    <div className="h-full p-moduleContainerPadding group-has-[header]:pt-0">
      {children}
    </div>
  );
};
