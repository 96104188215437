/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface ConnectWithAlexaRequest
 */
export interface ConnectWithAlexaRequest {
  /**
   *
   * @type {string}
   * @memberof ConnectWithAlexaRequest
   */
  authCode: string;
  /**
   *
   * @type {string}
   * @memberof ConnectWithAlexaRequest
   */
  cups: string;
}

/**
 * Check if a given object implements the ConnectWithAlexaRequest interface.
 */
export function instanceOfConnectWithAlexaRequest(
  value: object,
): value is ConnectWithAlexaRequest {
  if (!('authCode' in value) || value['authCode'] === undefined) return false;
  if (!('cups' in value) || value['cups'] === undefined) return false;
  return true;
}

export function ConnectWithAlexaRequestFromJSON(
  json: any,
): ConnectWithAlexaRequest {
  return ConnectWithAlexaRequestFromJSONTyped(json, false);
}

export function ConnectWithAlexaRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConnectWithAlexaRequest {
  if (json == null) {
    return json;
  }
  return {
    authCode: json['authCode'],
    cups: json['cups'],
  };
}

export function ConnectWithAlexaRequestToJSON(
  json: any,
): ConnectWithAlexaRequest {
  return ConnectWithAlexaRequestToJSONTyped(json, false);
}

export function ConnectWithAlexaRequestToJSONTyped(
  value?: ConnectWithAlexaRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    authCode: value['authCode'],
    cups: value['cups'],
  };
}
