export const demoAccountSettings = {
  emailRegex: /info(.*)@clever\.gy/,
  addressesForCups: {
    ES0031103454661001DQ0F: 'Casa con fotovoltaica 1', // Loma
    ES0031101496756008VS0F: 'Casa con fotovoltaica 2', // Torreblanca
    ES0031104288163024ZW0F: 'Casa con fotovoltaica 3 - sin datos de solar', // Escritora María
    ES0021000005089645FH: 'Sin solar, con Shellys', // Pardiñas
    ES0021000015582304MP: 'Consumo coche eléctrico', // Riola
    ES0021000012989428VZ: 'Sin solar, recomendación cambio de potencia', // Riu Turia
    ES0021000004880256BK: 'Casa sin fotovoltaica', // C/ Peña Citores,
    ES0022000006007959NR1P: 'Casa sin datos de consumo', // Goya
    ES0031105117880001QY0F: 'Casa con bateria', // Pueblo Saharaui
    ES0021000008835539DV: 'Piso en ciudad', // Poeta Micer Masco
  },
} as const;
