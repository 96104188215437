/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface Expectations
 */
export interface Expectations {
  /**
   * User has answered expectations survey
   * @type {boolean}
   * @memberof Expectations
   */
  hasUserAnsweredExpectations: boolean;
}

/**
 * Check if a given object implements the Expectations interface.
 */
export function instanceOfExpectations(value: object): value is Expectations {
  if (
    !('hasUserAnsweredExpectations' in value) ||
    value['hasUserAnsweredExpectations'] === undefined
  )
    return false;
  return true;
}

export function ExpectationsFromJSON(json: any): Expectations {
  return ExpectationsFromJSONTyped(json, false);
}

export function ExpectationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Expectations {
  if (json == null) {
    return json;
  }
  return {
    hasUserAnsweredExpectations: json['hasUserAnsweredExpectations'],
  };
}

export function ExpectationsToJSON(json: any): Expectations {
  return ExpectationsToJSONTyped(json, false);
}

export function ExpectationsToJSONTyped(
  value?: Expectations | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    hasUserAnsweredExpectations: value['hasUserAnsweredExpectations'],
  };
}
