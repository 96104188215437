/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UpdateContractLead
 */
export interface UpdateContractLead {
  /**
   *
   * @type {string}
   * @memberof UpdateContractLead
   */
  tariffId: string;
  /**
   *
   * @type {string}
   * @memberof UpdateContractLead
   */
  houseId?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateContractLead
   */
  contractedPower?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UpdateContractLead
   */
  holderName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateContractLead
   */
  iban?: string;
}

/**
 * Check if a given object implements the UpdateContractLead interface.
 */
export function instanceOfUpdateContractLead(
  value: object,
): value is UpdateContractLead {
  if (!('tariffId' in value) || value['tariffId'] === undefined) return false;
  return true;
}

export function UpdateContractLeadFromJSON(json: any): UpdateContractLead {
  return UpdateContractLeadFromJSONTyped(json, false);
}

export function UpdateContractLeadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateContractLead {
  if (json == null) {
    return json;
  }
  return {
    tariffId: json['tariffId'],
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    contractedPower:
      json['contractedPower'] == null ? undefined : json['contractedPower'],
    holderName: json['holderName'] == null ? undefined : json['holderName'],
    iban: json['iban'] == null ? undefined : json['iban'],
  };
}

export function UpdateContractLeadToJSON(json: any): UpdateContractLead {
  return UpdateContractLeadToJSONTyped(json, false);
}

export function UpdateContractLeadToJSONTyped(
  value?: UpdateContractLead | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    tariffId: value['tariffId'],
    houseId: value['houseId'],
    contractedPower: value['contractedPower'],
    holderName: value['holderName'],
    iban: value['iban'],
  };
}
