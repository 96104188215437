/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Change password link
 * @export
 * @interface SendChangePasswordLink
 */
export interface SendChangePasswordLink {
  /**
   * User email
   * @type {string}
   * @memberof SendChangePasswordLink
   */
  email: string;
  /**
   * Tenant GCP identifier
   * @type {string}
   * @memberof SendChangePasswordLink
   */
  tenantGcpId: string;
}

/**
 * Check if a given object implements the SendChangePasswordLink interface.
 */
export function instanceOfSendChangePasswordLink(
  value: object,
): value is SendChangePasswordLink {
  if (!('email' in value) || value['email'] === undefined) return false;
  if (!('tenantGcpId' in value) || value['tenantGcpId'] === undefined)
    return false;
  return true;
}

export function SendChangePasswordLinkFromJSON(
  json: any,
): SendChangePasswordLink {
  return SendChangePasswordLinkFromJSONTyped(json, false);
}

export function SendChangePasswordLinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SendChangePasswordLink {
  if (json == null) {
    return json;
  }
  return {
    email: json['email'],
    tenantGcpId: json['tenantGcpId'],
  };
}

export function SendChangePasswordLinkToJSON(
  json: any,
): SendChangePasswordLink {
  return SendChangePasswordLinkToJSONTyped(json, false);
}

export function SendChangePasswordLinkToJSONTyped(
  value?: SendChangePasswordLink | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    email: value['email'],
    tenantGcpId: value['tenantGcpId'],
  };
}
