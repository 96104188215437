/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UploadDniUrl
 */
export interface UploadDniUrl {
  /**
   *
   * @type {string}
   * @memberof UploadDniUrl
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof UploadDniUrl
   */
  urlBack?: string;
}

/**
 * Check if a given object implements the UploadDniUrl interface.
 */
export function instanceOfUploadDniUrl(value: object): value is UploadDniUrl {
  return true;
}

export function UploadDniUrlFromJSON(json: any): UploadDniUrl {
  return UploadDniUrlFromJSONTyped(json, false);
}

export function UploadDniUrlFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UploadDniUrl {
  if (json == null) {
    return json;
  }
  return {
    url: json['url'] == null ? undefined : json['url'],
    urlBack: json['urlBack'] == null ? undefined : json['urlBack'],
  };
}

export function UploadDniUrlToJSON(json: any): UploadDniUrl {
  return UploadDniUrlToJSONTyped(json, false);
}

export function UploadDniUrlToJSONTyped(
  value?: UploadDniUrl | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    url: value['url'],
    urlBack: value['urlBack'],
  };
}
