/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 * Detail of invoice
 * @export
 * @interface HouseInvoiceDetail
 */
export interface HouseInvoiceDetail {
  /**
   * Start date of the invoicing period
   * @type {Date}
   * @memberof HouseInvoiceDetail
   */
  startDate?: Date;
  /**
   * End date of the invoicing period
   * @type {Date}
   * @memberof HouseInvoiceDetail
   */
  endDate?: Date;
  /**
   * Total cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  totalCost: number;
  /**
   * Energy cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  energyCost?: number;
  /**
   * Power cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  powerCost?: number;
  /**
   * Other costs of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  otherCosts?: number;
  /**
   * Discounts applied to the invoice. Negative values means discounts.
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  discounts?: number;
  /**
   * Tax cost of the invoice
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  taxCost?: number;
  /**
   * Surplus applied to the invoice. Negative values means surplus applied.
   * @type {number}
   * @memberof HouseInvoiceDetail
   */
  exportSaving?: number;
  /**
   * URL to download the invoice file
   * @type {string}
   * @memberof HouseInvoiceDetail
   */
  urlFile?: string;
}

/**
 * Check if a given object implements the HouseInvoiceDetail interface.
 */
export function instanceOfHouseInvoiceDetail(
  value: object,
): value is HouseInvoiceDetail {
  if (!('totalCost' in value) || value['totalCost'] === undefined) return false;
  return true;
}

export function HouseInvoiceDetailFromJSON(json: any): HouseInvoiceDetail {
  return HouseInvoiceDetailFromJSONTyped(json, false);
}

export function HouseInvoiceDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HouseInvoiceDetail {
  if (json == null) {
    return json;
  }
  return {
    startDate:
      json['startDate'] == null ? undefined : new Date(json['startDate']),
    endDate: json['endDate'] == null ? undefined : new Date(json['endDate']),
    totalCost: json['totalCost'],
    energyCost: json['energyCost'] == null ? undefined : json['energyCost'],
    powerCost: json['powerCost'] == null ? undefined : json['powerCost'],
    otherCosts: json['otherCosts'] == null ? undefined : json['otherCosts'],
    discounts: json['discounts'] == null ? undefined : json['discounts'],
    taxCost: json['taxCost'] == null ? undefined : json['taxCost'],
    exportSaving:
      json['exportSaving'] == null ? undefined : json['exportSaving'],
    urlFile: json['urlFile'] == null ? undefined : json['urlFile'],
  };
}

export function HouseInvoiceDetailToJSON(json: any): HouseInvoiceDetail {
  return HouseInvoiceDetailToJSONTyped(json, false);
}

export function HouseInvoiceDetailToJSONTyped(
  value?: HouseInvoiceDetail | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    startDate:
      value['startDate'] == null
        ? undefined
        : value['startDate'].toISOString().substring(0, 10),
    endDate:
      value['endDate'] == null
        ? undefined
        : value['endDate'].toISOString().substring(0, 10),
    totalCost: value['totalCost'],
    energyCost: value['energyCost'],
    powerCost: value['powerCost'],
    otherCosts: value['otherCosts'],
    discounts: value['discounts'],
    taxCost: value['taxCost'],
    exportSaving: value['exportSaving'],
    urlFile: value['urlFile'],
  };
}
