import { App } from './App';
import './setupFirebase';
import React from 'react';
import { createRoot } from 'react-dom/client';

export const setupApplication = () => {
  const container = document.getElementById('root');
  if (!container) {
    throw new Error('Could not find root element');
  }
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};
