import { EventBus } from '../classes/EventBus.class';
import { FC, ReactNode, createContext, useContext } from 'react';

type EventBusContextValue = {
  eventBus: EventBus;
};

export const EventBusContext = createContext<EventBusContextValue | null>(null);

export const EventBusContextProvider: FC<{
  children: ReactNode;
  eventBus: EventBus;
}> = ({ children, eventBus }) => {
  return (
    <EventBusContext.Provider value={{ eventBus }}>
      {children}
    </EventBusContext.Provider>
  );
};

export const useEventBusContext = () => {
  const context = useContext(EventBusContext);
  if (context === null) {
    throw Error(
      'useEventBusContext must be used inside of a EventBusContextProvider',
    );
  }
  return context as EventBusContextValue;
};
