/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface UpdateSmartDeviceRequest
 */
export interface UpdateSmartDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateSmartDeviceRequest
   */
  subtype: UpdateSmartDeviceRequestSubtypeEnum;
}

/**
 * @export
 */
export const UpdateSmartDeviceRequestSubtypeEnum = {
  Grid: 'GRID',
  SelfProduction: 'SELF_PRODUCTION',
  Battery: 'BATTERY',
  Export: 'EXPORT',
  Dishwasher: 'DISHWASHER',
  Kitchen: 'KITCHEN',
  Fridge: 'FRIDGE',
  Freezer: 'FREEZER',
  Microwave: 'MICROWAVE',
  Oven: 'OVEN',
  Ac: 'AC',
  PlugAc: 'PLUG_AC',
  WashingMachine: 'WASHING_MACHINE',
  WaterHeater: 'WATER_HEATER',
  Workstation: 'WORKSTATION',
  Vacuum: 'VACUUM',
  Heater: 'HEATER',
  PlugHeater: 'PLUG_HEATER',
  Tv: 'TV',
  Dryer: 'DRYER',
  Light: 'LIGHT',
  Pool: 'POOL',
  Aerothermal: 'AEROTHERMAL',
  Unknown: 'UNKNOWN',
  Other: 'OTHER',
} as const;
export type UpdateSmartDeviceRequestSubtypeEnum =
  (typeof UpdateSmartDeviceRequestSubtypeEnum)[keyof typeof UpdateSmartDeviceRequestSubtypeEnum];

/**
 * Check if a given object implements the UpdateSmartDeviceRequest interface.
 */
export function instanceOfUpdateSmartDeviceRequest(
  value: object,
): value is UpdateSmartDeviceRequest {
  if (!('subtype' in value) || value['subtype'] === undefined) return false;
  return true;
}

export function UpdateSmartDeviceRequestFromJSON(
  json: any,
): UpdateSmartDeviceRequest {
  return UpdateSmartDeviceRequestFromJSONTyped(json, false);
}

export function UpdateSmartDeviceRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateSmartDeviceRequest {
  if (json == null) {
    return json;
  }
  return {
    subtype: json['subtype'],
  };
}

export function UpdateSmartDeviceRequestToJSON(
  json: any,
): UpdateSmartDeviceRequest {
  return UpdateSmartDeviceRequestToJSONTyped(json, false);
}

export function UpdateSmartDeviceRequestToJSONTyped(
  value?: UpdateSmartDeviceRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    subtype: value['subtype'],
  };
}
