import { Prose } from '@clevergy/ui/components/Prose';
import { FC } from 'react';

export type PrivacyPolicyProps = {
  legalName?: string | null;
  taxId?: string | null;
  address?: string | null;
  email?: string | null;
};

export const PrivacyPolicy: FC<PrivacyPolicyProps> = (
  props: PrivacyPolicyProps,
) => {
  const { address, legalName, taxId, email } = props;

  return (
    <Prose>
      <h1>POLÍTICA DE PRIVACIDAD PARA LOS INTERESADOS</h1>
      <p>
        {legalName} es el responsable del tratamiento de tus datos de carácter
        personal a los efectos de prestarte los servicios identificados en la
        presente política de privacidad. {legalName} es una sociedad domiciliada
        en {address}, y su CIF/NIF es {taxId} (en adelante, &quot;
        <strong>Cliente</strong>
        &quot;).
      </p>
      <h2>¿Qué información tratamos?</h2>
      <p>
        Para el uso de esta aplicación y los servicios que ofrece, solicitaremos
        datos como tu nombre y apellidos, datos de contacto, DNI, código postal
        o tu número de suministro energético, necesarios para poder atender tu
        solicitud y/o prestarte nuestros servicios. Todos los campos que
        aparezcan señalados con un asterisco (\*) en los formularios que se te
        faciliten en los canales serán de obligada cumplimentación, de forma que
        la omisión de alguno de ellos podría comportar la imposibilidad de que
        se te puedan facilitar los servicios solicitados. Para que la
        información facilitada esté siempre actualizada y no contenga errores,
        deberás comunicar a <strong>Cliente</strong> a la mayor brevedad
        posible, las modificaciones de tus datos de carácter personal que se
        produzcan. Asimismo, al hacer &quot;click&quot; en el botón
        &quot;Acepto&quot; (o equivalente) incorporado en los citados
        formularios, declaras que la información y los datos que en ellos has
        facilitado son exactos y veraces.
      </p>
      <h2>¿Cuál es el origen de los datos?</h2>
      <p>
        Consideramos que todos los datos tratados por <strong>Cliente</strong>{' '}
        nos los has aportado libremente. En caso de que los datos personales
        aportados sean de un tercero, garantizas que le has informado de esta
        Política de Privacidad y has obtenido su autorización para facilitar los
        datos a <strong>Cliente</strong> con las finalidades señaladas
        anteriormente. Igualmente, garantizas que los datos aportados son
        exactos y actualizados, y te haces responsable de cualquier daño o
        perjuicio, directo o indirecto, que pudiera ocasionarse como
        consecuencia del incumplimiento de tal obligación.
      </p>
      <h2>
        ¿Para qué finalidad y con qué base legitimadora tratamos los datos?
      </h2>
      <p>
        <strong>Cliente</strong> actúa como responsable del tratamiento de datos
        personales, en base a la gestión de la relación contractual, para
        prestarte los servicios que nos requieres, permitir el acceso a tu
        sección de cliente en la app y atender a tus peticiones de información,
        así como a cualquier tipo de incidencia o petición derivada de la
        relación contractual. En base a tu consentimiento, solicitaremos a
        terceros el acceso a la información de tu consumo con la finalidad de
        poder ofrecerte información sobre el mismo y recomendaciones de cómo
        reducirlo.
      </p>
      <h2>¿A quién se comunican los datos?</h2>
      <p>
        Podemos ceder algunos de tus datos a empresas colaboradoras para que
        estas puedan identificarte cuando aceptes que las mismas nos
        proporcionen datos de tu consumo. Además de lo anterior, no cedemos tus
        datos a terceros más allá de las comunicaciones necesarias a las
        administraciones públicas o de justicia para el cumplimiento de las
        obligaciones legales que nos son de aplicación. No obstante, si fuera
        necesario llevar a cabo alguna cesión, te informaremos previamente de
        ello y se llevará a cabo siempre en virtud de una base jurídica que
        legitime el tratamiento. Del mismo modo, <strong>Cliente</strong>{' '}
        garantiza que en caso de existir proveedores que se encuentren en países
        fuera de la Unión Europea u otros no considerados adecuados de
        conformidad con la normativa de protección de datos, se toman las
        precauciones necesarias que garantizan que los datos pueden ser
        transferidos de manera segura, ya sea porque el proveedor ofrece
        garantías adecuadas, o porque se ha adoptado alguna de las garantías
        establecidas en la normativa de protección de datos para asegurar que
        las transferencias internacionales se ajustan a la legalidad.
      </p>

      <h2>Ejercicio de tus derechos.</h2>
      <p>Te informamos de que podrás ejercer los siguientes derechos:</p>
      <ol>
        <li>
          Derecho de acceso a sus datos personales para saber cuáles están
          siendo objeto de tratamiento y las operaciones de tratamiento llevadas
          a cabo con ellos.
        </li>
        <li>Derecho de rectificación de cualquier dato personal inexacto.</li>
        <li>
          Derecho de supresión de sus datos personales, cuando esto sea posible.
        </li>
        <li>
          Derecho a solicitar la limitación del tratamiento de sus datos
          personales cuando la exactitud, la legalidad o la necesidad del
          tratamiento de los datos resulte dudosa, en cuyo caso, podremos
          conservar los datos para el ejercicio o la defensa de reclamaciones.
        </li>
        <li>
          Derecho a oponerse a la toma de decisiones automatizadas, incluida la
          realización de perfiles.
        </li>
        <li>
          Derecho de oposición al tratamiento de tus datos personales cuando la
          base legal que nos habilite para su tratamiento, conforme al apartado
          4 anterior, sea el interés legítimo.
        </li>
        <li>
          Derecho a la portabilidad de tus datos, cuando la base legal que nos
          habilite para su tratamiento de las indicadas en el apartado 4
          anterior sea la existencia de una relación contractual o tu
          consentimiento.
        </li>
        <li>Derecho a revocar el consentimiento otorgado.</li>
      </ol>

      <h2>
        Podrás ejercitar tus derechos en cualquier momento y de forma gratuita
        de las siguientes formas:
      </h2>
      <p>
        Para ejercer tus derechos, podrás enviarnos la petición a la siguiente
        dirección de correo electrónico:{' '}
        <a href={`mailto:${email || 'soporte@clever.gy'}`}>
          {email || 'soporte@clever.gy'}
        </a>
        .
      </p>

      <p>
        Asimismo, te informamos de que tienes derecho a presentar una
        reclamación ante la Agencia Española de Protección de Datos si
        consideras que hemos cometido una infracción de la legislación en
        materia de protección de datos respecto al tratamiento de tus datos
        personales.
      </p>

      <p>
        Además, te informamos de que puedes apuntarte en la Lista Robinson en{' '}
        <a href="https://www.listarobinson.es/">www.listarobinson.es</a>: el
        sistema de exclusión publicitaria gestionado la Asociación Española de
        Economía Digital (ADIGITAL), en el que puedes inscribirte con el objeto
        de mostrar tu oposición a que se utilicen tus datos con el fin de
        remitirte comunicaciones comerciales.
      </p>

      <h2>Plazo de conservación de tus datos.</h2>

      <p>
        Sólo mantendremos tus datos durante el tiempo que sea necesario para
        ofrecerte estos servicios. Cualquiera de los datos que nos facilites a
        través de los canales serán bloqueados siempre y cuando dejen de ser
        necesarios para gestionar el servicio y sólo estarán disponibles en caso
        de que exista una obligación legal (derivada de una petición de las
        Fuerzas y Cuerpos de Seguridad del Estado o de Juzgados y Tribunales)
        así como cuando ejercites tus derechos.
      </p>

      <h2>Seguridad y confidencialidad.</h2>

      <p>
        Con el objeto de prevenir accesos no autorizados o la revelación no
        autorizada de datos personales, hemos tomado las medidas adecuadas tanto
        técnicas como físicas, así como los procesos de gestión para
        salvaguardar y asegurar la información que recabamos de ti.
      </p>

      <h2>Actualización de la política de privacidad.</h2>

      <p>
        Hacemos nuestros mejores esfuerzos para mantener nuestra política de
        privacidad totalmente actualizada. Si hacemos cambios, estos serán
        claramente identificables de forma clara y específica, conforme sea
        posible en la relación que hemos establecido contigo (por ejemplo: te
        podemos comunicar los cambios por email). Esta política de privacidad ha
        sido revisada y publicada a fecha 18 de Octubre de 2023.
      </p>
    </Prose>
  );
};
